import { takeEvery, put } from "redux-saga/effects";
import { GRAPHQL_TYPE_PERSON, GRAPHQL_TYPE_ENTITY, Person, Entity, PreviewKind } from "@radivision/graphql";
import { EDIT_PROFILE } from "../constants";
import { EditProfileAction, SetProfileStatePayload } from "../types";
import { getProfilePhoto } from "../../../../../utilities/get-profile-photo";
import { setProfileState } from "../actions";
import { getSocialMediaProfilesMap } from "../selectors";
import { getNumberFromRange } from "../../../../../utilities/get-number-from-range";
import { getLocation } from "../../../../../utilities/get-location";
import isEmpty from "lodash.isempty";
import { MONTHS_NAMES } from "../../../../../constants/months-names";

function* editProfileWorker(action: EditProfileAction) {
  try {
    const { subject } = action.payload;
    const isEntity = subject.__typename === GRAPHQL_TYPE_ENTITY;
    const isPerson = subject.__typename === GRAPHQL_TYPE_PERSON;
    const person = isPerson ? (subject as Person) : null;
    const entity = isEntity ? (subject as Entity) : null;
    const profileInput: SetProfileStatePayload = {};

    profileInput.type = subject.__typename;
    profileInput.id = subject.id;
    profileInput.revision = subject.revision;
    profileInput.name = isEntity ? entity.name : null;
    profileInput.fullName = isPerson ? person.fullName : null;
    profileInput.tagLine = isEntity ? entity.tagLine : null;
    profileInput.description = subject.description;
    profileInput.responsibility = isPerson ? person.responsibility : null;
    // const foundedDate = isEntity && entity.founded ? new Date(entity.founded) : null;
    const year = new Date(entity?.founded).getFullYear();
    const month = new Date(entity?.founded).getUTCMonth();
    const foundedDate = `${!isNaN(month) ? MONTHS_NAMES[month] : ""} ${year}`;

    // const foundedDateSplitted = isEntity && entity.founded ? entity?.founded.slice(0, 9).split("-") : null;
    // const updatedFoundedDate = new Date(foundedDateSplitted[0], foundedDateSplitted[1] - 1, foundedDateSplitted[2] + 2);

    profileInput.founded = isEntity ? (foundedDate ? foundedDate : "") : null;
    // updatedFoundedDate
    //   ? `${updatedFoundedDate.getFullYear()}-${String(updatedFoundedDate.getMonth() + 1).padStart(2, "0")}`

    profileInput.websiteUrl = subject.websiteUrl;
    profileInput.location = getLocation(subject);
    profileInput.noOfEmployees = isEntity && entity?.noOfEmployees ? getNumberFromRange(entity?.noOfEmployees) : null;
    profileInput.industry = isEntity ? entity.industry : null;
    profileInput.activities =
      isEntity && Array.isArray(entity.activities) ? entity?.activities?.map((a: any) => a.kind) : [];
    profileInput.profileImage = null;
    profileInput.socialMediaProfiles = getSocialMediaProfilesMap(subject);

    const opportunities =
      isEntity && entity.opportunities
        ? Object.keys(entity.opportunities).reduce((acc, k) => {
            let opportunity = entity.opportunities[k];
            const overrideLinkWithEmail = !isEmpty(opportunity?.email) ? opportunity?.email : null;
            console.log("debug: ", { opportunity, overrideLinkWithEmail });
            if (opportunity && overrideLinkWithEmail) {
              opportunity = { ...opportunity, link: overrideLinkWithEmail };
            }
            acc[k] = opportunity;
            return acc;
          }, {})
        : {};
    profileInput.opportunities = opportunities;
    profileInput.profileImageUrl = getProfilePhoto(subject);
    profileInput.profileBackgroundUrl = getProfilePhoto(subject, PreviewKind.BACKGROUND);
    console.log("debug: editProfile ", { profileInput });
    yield put(setProfileState(profileInput));
  } catch (errors) {
    console.log("Error: editProfileWorker", { errors });
  }
}

export function* watchEditProfile() {
  yield takeEvery(EDIT_PROFILE, editProfileWorker);
}
