import * as React from "react";
import isEmpty from "lodash.isempty";
import { Entity, OpportunitiesItem } from "@radivision/graphql";
import { ConnectInvestorButton } from "./connect-investor-button";
import { EditProfileButton } from "../../profile-header/edit-profile-button";
import { SVG } from "../../../../component-configuration/svgs";
import "./opportunities.scss";

interface ComponentProps {
  entity: Entity & { opportunities: any };
  owner: boolean | null;
}

type Props = ComponentProps;

export class Opportunities extends React.Component<Props> {
  render() {
    const owner = this.props.owner;

    const opportunities = this.props.entity?.opportunities;
    const fields = {
      // productAndServices: "Products And Services",
      careers: "Career",
      collaboration: "Other",
    };

    return (
      <Container>
        {Object.keys(fields).map((field) => {
          const opportunity = opportunities && opportunities[field];
          const title = fields[field];
          return (
            <Opportunity
              key={`opportunity-component-${field}`}
              entity={this.props.entity}
              opportunity={opportunity}
              title={title}
              owner={owner}
            />
          );
        })}
      </Container>
    );
  }
}

const Opportunity = ({
  opportunity,
  title,
  entity,
  owner,
}: {
  opportunity: OpportunitiesItem;
  title: string;
  entity: Entity;
  owner: boolean;
}) => {
  const { description, link, email } = opportunity || {};
  if (isEmpty(description) && isEmpty(link) && isEmpty(email) && !owner) {
    return null;
  }

  return (
    <>
      <div className="opportunity">
        <div className="opportunity-body">
          <div className="intro-title">
            <span>{title}</span>
          </div>
          {/* <div className="description">
          {title === "Career"
            ? "Recruit people passionate about your company"
            : title === "Other"
            ? "Connect for collaboration or other opportunities"
            : null}
        </div> */}
          {!isEmpty(description) &&
            description?.split(/\n/i).map((line: string, index: number) => {
              return <p key={`opportunity-${title}-${index}`}>{line}</p>;
            })}
        </div>
        {!isEmpty(link) || !isEmpty(email) ? (
          <div
            className="opportunity-link"
            style={{ visibility: isEmpty(link) && isEmpty(email) ? "hidden" : "visible" }}
          >
            <ConnectInvestorButton entity={entity} link={email || link} />
          </div>
        ) : owner ? (
          <div className="opportunity-link"></div>
        ) : null}
      </div>
      {owner && (
        <EditProfileButton subject={entity}>
          <span>{SVG.plusAlt}</span>
          {title === "Career" ? (
            <div className="button-text">Career Page Link</div>
          ) : title === "Other" ? (
            <div className="button-text">Contact Link</div>
          ) : null}
        </EditProfileButton>
      )}
    </>
  );
};

export const Container = ({ children }) => {
  return <div className="opportunities-container">{children}</div>;
};
