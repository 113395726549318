export function formatTime(given_seconds: number) {
  if (isNaN(given_seconds) || given_seconds === 0 || given_seconds === null || given_seconds === undefined) {
    return null;
  }
  try {
    const durationInSeconds = given_seconds / 1000 > 50 ? given_seconds : given_seconds * 1000;
    const dateObj = new Date(durationInSeconds);
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();

    const hoursStr = hours > 0 ? hours.toString() + "h " : "";
    const minutesStr = minutes > 0 ? minutes.toString() + "min" : "1min";
    const timeString = hoursStr + minutesStr;
    return timeString;
  } catch (error) {
    console.log("error: ", { error });
    return null;
  }
}
