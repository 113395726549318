import Loadable from "react-loadable";
import { Entity } from "@radivision/graphql";

import OptionsWithoutRender = LoadableExport.OptionsWithoutRender;

interface ComponentProps {
  entity: Entity;
  link: string;
  buttonText?: string;
}

type Props = ComponentProps;

const loadableOptions: OptionsWithoutRender<Props> = {
  loader: () => import("../../modules/manage-pitch").then((module) => module.ConnectInvestorButton) as Promise<any>,
  loading: () => null,
};

export const InvestNowButton = Loadable(loadableOptions);
