import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { FormFieldInput, FormFieldContainer, CharsCount } from "../../../react-components/modals/modal-form-elements";
import { LabelToolTip } from "../../../react-components/page-framework/label-tool-tip";
import { SetProfileStatePayload, SetProfileStateAction, ProfileModuleState } from "../redux/profile/types";
import {setProfileState} from "../redux/profile/actions";
import {GRAPHQL_TYPE_ENTITY} from "@radivision/graphql";

interface MapStateProps {
  tagLine: string | null;
  isCompany: boolean;
}
interface MapDispatchProps {
  setProfileState: (payload: SetProfileStatePayload) => SetProfileStateAction;
}

export type Props = MapStateProps & MapDispatchProps;

export const Component = (props: Props) => {
  const { setProfileState, tagLine, isCompany } = props;

  const [charsCount, setCharsCount] = React.useState<number>(0);
  const textareaId = "create-company-tagline";

  React.useEffect(() => {
    setCharsCount(tagLine ? tagLine.length : 0);
  }, [tagLine]);

  React.useEffect(
    function resizeTextarea() {
      const textarea = document.getElementById(textareaId);
      if (!textarea) {
        return;
      }
      try {
        textarea.setAttribute(
          "style",
          `min-height:100px;max-height:200px;height:${textarea.scrollHeight}px;overflow-y:auto;`,
        );
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      } catch (error) {
        console.log({ error });
      }
    },
    [textareaId],
  );

  if (!isCompany) return null;
  const tooltipText = "Brief description about your company";
  return (
    <FormFieldContainer>
      <label htmlFor="tagline">
        <span>Tagline:</span>
        <LabelToolTip text={tooltipText} />
      </label>
      <p className="field-help-text">{tooltipText}</p>
      <FormFieldInput>
        <textarea
          rows={4}
          name="tagLine"
          id={textareaId}
          maxLength={120}
          value={tagLine}
          onChange={(e) => setProfileState({ tagLine: e.target.value })}
        />
        <CharsCount count={charsCount} limit={120} />
      </FormFieldInput>
    </FormFieldContainer>
  );
};

export const Container = ({ children }) => {
  return <div className="profile-tagline-input">{children}</div>;
};

const mapState = (state: AppState & ProfileModuleState) => ({
  tagLine: state.profile.tagLine,
  isCompany: state.profile.type === GRAPHQL_TYPE_ENTITY,
});

const mapDispatch = {
  setProfileState: setProfileState,
};

export const TagLine = connect(mapState, mapDispatch)(Component);
