import { takeEvery, put, select, call } from "redux-saga/effects";
import { CREATE_INVEST_AMOUNT_REQUEST } from "../constants";
import { storageFactory } from "../../../../../utilities/local-storage-factory";
import { setToastMessage } from "../../../../../redux/actions";
import { TOAST_SUCCESS, TOAST_FAIL } from "../../../../../redux/toast/constants";
import { CreateInvestAmountRequestAction } from "../types";
import { createInvestAmountRequest } from "../api";

export function* createInvestAmountRequestWorker(action: CreateInvestAmountRequestAction) {
  const localStore = storageFactory(() => localStorage);
  const { entity } = action.payload;
  const message = `Your investment request has been sent successfully to "${entity?.name}"`;
  try {
    yield call(createInvestAmountRequest, action.payload);
    localStore.setItem(`invest-amount-${entity?.id}`, "filled");
    yield put(setToastMessage({ msg: message, type: TOAST_SUCCESS }));
  } catch (error) {
    yield put(setToastMessage({ msg: "Fail to send investment request!", type: TOAST_FAIL }));
    console.log("delete pitch: Error ", { error });
  }
}

export function* watchCreateInvestmentAmount() {
  yield takeEvery(CREATE_INVEST_AMOUNT_REQUEST, createInvestAmountRequestWorker);
}
