import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";
import { AppState } from "../../redux";
import { getCarouselItem } from "../../utilities/general";
import { PreviewKind, GRAPHQL_TYPE_POSTER, GRAPHQL_TYPE_PITCH_VIDEO_STORY } from "@radivision/graphql";
import { GridView } from "../lists/grid-view";
import { OpenDetailsPanelPayload, OpenDetailsPanelAction } from "../../redux/details-panel/types";
import { FetchListAction, FetchListPayload } from "../../redux/cache/types";
import isEmpty from "lodash.isempty";
import { defaultMoreLikeThis, originalContentMoreLikeThisIDs, pitchPlanetListId } from "../../constants/content-ids";
import { SVG } from "../../component-configuration/svgs";

interface ComponentProps { }

interface MapDispatchProps {
  openDetailsPanel: (payload: OpenDetailsPanelPayload) => OpenDetailsPanelAction;
  fetchList: (payload: FetchListPayload) => FetchListAction;
}
interface MapStateProps {
  list: any;
  item: any;
  moreLikeThis: string | null;
  moreLikeThisList: any;
  isFetching: boolean;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

const PAGE_SIZE = 8;

export const Component = (props: Props) => {
  const { item, list, moreLikeThisList, moreLikeThis, openDetailsPanel, fetchList, isFetching } = props;
  const isPortrait = item?.__typename === GRAPHQL_TYPE_POSTER;
  const [page, setPage] = React.useState(1);
  const content =
    item &&
    moreLikeThisList?.items?.edges
      ?.filter((edge) => edge.listItem?.item?.id !== item?.id)
      .slice(0, page * PAGE_SIZE)
      .map((edge: any) => {
        return getCarouselItem(
          edge,
          moreLikeThisList,
          0,
          { containerWidthRatio: 1.5, numberOfItems: 3 },
          isPortrait ? PreviewKind.PORTRAIT : PreviewKind.CAROUSEL,
          true,
        );
      });

  React.useEffect(() => {
    if (moreLikeThis && !moreLikeThisList && !isFetching) {
      fetchList({ variables: { listId: moreLikeThis } });
    }
  }, [fetchList, isFetching, moreLikeThis, moreLikeThisList]);

  const MoreBreakLine = React.useCallback(() => {
    const total = moreLikeThisList?.items?.edges?.length;
    const thereIsMore = total > page * PAGE_SIZE;
    if (!thereIsMore) return null;
    return (
      <div className="more-line-break" onClick={() => setPage(page + 1)}>
        <div className="br" />
        <span>
          <em>{SVG.chevronDown}</em>
        </span>
        <div className="br" />
      </div>
    );
  }, [page, setPage, moreLikeThisList]);

  if (!content?.length) return null;

  return (
    <div className="details-panel-section more-like-section">
      <h3 className="section-title">More like this</h3>
      {content?.length ? (
        <div className="more-like-this">
          <GridView
            gridContent={content}
            itemsToShow={3}
            itemPreviewKind={isPortrait ? PreviewKind.PORTRAIT : PreviewKind.CAROUSEL}
            hideItemTypeIcon={true}
            displayItemDescription={false}
            displayItemDescriptionOneLine={true}
            handleItemClick={(index: number) => {
              const itemId = content[index].id;
              const edge = moreLikeThisList?.items?.edges.find((edge) => edge.listItem.item.id === itemId);
              const item = edge?.listItem.item;
              openDetailsPanel({
                item,
                list: moreLikeThisList,
                moreLikeThis: getItemMoreLikeThis(item, moreLikeThis, list?.id),
              });
            }}
          />
          <MoreBreakLine />
        </div>
      ) : null}
    </div>
  );
};

const getItemMoreLikeThis = (item, _moreLikeThis: string | null, listId: string) => {
  const itemIsOriginalShow = listId === item?.id;
  if (itemIsOriginalShow) return originalContentMoreLikeThisIDs[listId];

  const isPitchItem = item?.__typename === GRAPHQL_TYPE_PITCH_VIDEO_STORY;
  if (isPitchItem) return pitchPlanetListId;

  const itemUsesSourceListForEpisodes = !isEmpty(item?.sourceList);
  const moreLikeThis = itemUsesSourceListForEpisodes
    ? originalContentMoreLikeThisIDs[item?.sourceList] || _moreLikeThis || defaultMoreLikeThis
    : listId || item?.moreLikeThis || _moreLikeThis || defaultMoreLikeThis;

  console.log({ moreLikeThis });
  return moreLikeThis;
};

const mapState = (state: AppState) => {
  const { item, moreLikeThis: _moreLikeThis } = state.detailsPanel;
  const { currentHash } = state.app;
  const [_title, _itemId, listId] = currentHash ? String(currentHash).split("/") : [];
  const list = listId ? state.cache.store[listId] : false;
  const moreLikeThis = getItemMoreLikeThis(item, _moreLikeThis || item?.moreLikeThis, listId);
  const moreLikeThisList = moreLikeThis ? state.cache.store[moreLikeThis] : false;
  const isFetching = moreLikeThis ? state.cache.inProgress[moreLikeThis] : false;
  return {
    item,
    moreLikeThisList: moreLikeThisList?.list?.list,
    moreLikeThis,
    isFetching,
    list: list?.list?.list,
  };
};

const mapDispatch = {
  openDetailsPanel: Actions.openDetailsPanel,
  fetchList: Actions.fetchList,
};

export const MoreLikeThis = connect(mapState, mapDispatch)(Component);
