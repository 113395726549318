import $ from "jquery";
import React from "react";
import { detectMobile } from "../../utilities/general";
import { closeIcon } from "./icons";

const LOGO = require("../../../static/rv_logo_01.png");

/**
 * The props of the Modal class.
 *
 * @interface
 */
interface ModalProps {
  /**
   * Optional properties of a child control used in composition.
   *
   * @type {Object<string, any>}
   */
  children?: { [index: string]: any };

  /**
   * The name of the css class for the modal - overrides the default.
   *
   * @type {string}
   */
  className?: string;

  /**
   * if modal has to be confirmed dismissing
   *
   * @type {string}
   */
  confirmModal?: string;

  /**
   * the state which defines display the confirm dismiss modal or not
   *
   * @type {boolean}
   */
  showConfirmDismiss?: boolean;

  /**
   * The footer for the modal.
   *
   * @type {React.ReactNode}
   */
  footer?: React.ReactNode;

  /**
   * The ID of the parent div element.
   *
   * @type {string}
   */
  id: string;

  /**
   * A flag which is true if the Modal Header has a Radivision logo.
   *
   * @type {boolean}
   */
  logo?: boolean;

  /**
   * on dismiss modal handler
   *
   * @type {() => void}
   * @memberof ModalProps
   */
  onDismissModal?: () => void;

  /**
   * Modal title (in case there is no logo)
   *
   * @type {string}
   */
  title?: any;
  modalContentStyle?: any;
  buttonIcon?: React.ReactNode;
  buttonClick?: () => void;
}

interface ModalState {
  cleared: boolean;
}
/**
 * A React component that renders modals. The class is a base class which aggregates specific modals.
 *
 * @extends {React.Component<ModalProps>}
 */
export class Modal extends React.Component<ModalProps, ModalState> {
  /**
   * A reference to the modal element.
   *
   * @type {React.RefObject<HTMLButtonElement>}
   */
  modalRef: React.RefObject<HTMLButtonElement>;

  /**
   * save the value of window scrollTop
   *
   * @type {number}
   */
  windowScrollValue: number;

  /**
   * Constructor.
   *
   * @param {ModalProps} props The props of the component.
   */
  constructor(props: ModalProps) {
    super(props);
    this.windowScrollValue = 0;

    this.modalRef = React.createRef();
    this.close = this.close.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      cleared: false,
    };
  }

  componentDidMount() {
    $(`#${this.props.id}`).on("shown.bs.modal", (e) => {
      this.windowScrollValue = document.documentElement.scrollTop;
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = "fixed";
    });

    $(`#${this.props.id}`).on("hide.bs.modal", (e) => {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
      // document.body.style.marginTop = "0";
      // document.body.classList.add("allow-scroll");
      // const scrollY = document.body.style.top;
      // document.body.style.position = "";
      // document.body.style.top = "";
      // window.scrollTo(0, parseInt(scrollY || "0") * -1);
    });
  }

  /**
   * Closes the modal
   */
  close() {
    this.modalRef.current.click();
  }
  /**
   *
   * @memberof Modal
   */
  closeModal(e) {
    const form: any = document.querySelector(".modal-body form");
    if (form !== null && form !== undefined) form.reset();
    $(`#${this.props.id}`).modal("hide");
    this.setState({
      cleared: !this.state.cleared,
    });
    if (this.props.onDismissModal) {
      this.props.onDismissModal();
    }
  }
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    let modalDialogClassName = "modal-dialog modal-dialog-centered modal-lg";

    if (this.props.className) {
      modalDialogClassName = `${modalDialogClassName} ${this.props.className}`;
    }
    let modalProps: any = {
      className: `modal`,
      id: this.props.id,
      role: "dialog",
      "aria-hidden": "true",
    };

    if (detectMobile() || this.props.onDismissModal) {
      modalProps = {
        ...modalProps,
        "data-backdrop": "false",
      };
    }
    return (
      <div {...modalProps} data-backdrop="false">
        <div className={modalDialogClassName} role="document">
          <div className="modal-content" style={this.props.modalContentStyle}>
            <div className="modal-header">
              {this.props.logo ? (
                <img loading="lazy" src={LOGO} alt="logo" className="radivisionLogo d-inline-block" />
              ) : (
                <p>{this.props.title}</p>
              )}
              <button
                type="button"
                className={`close close-lg ${this.props.buttonIcon ? "custom-icon" : ""}`}
                aria-label="Close"
                ref={this.modalRef}
                onClick={
                  this.props.showConfirmDismiss
                    ? null
                    : this.props.buttonClick
                    ? this.props.buttonClick
                    : this.closeModal
                }
                data-toggle={this.props.showConfirmDismiss ? "modal" : ""}
                data-target={this.props.showConfirmDismiss ? this.props.confirmModal : undefined}
              >
                <span aria-hidden="true">
                  <i
                    style={{
                      margin: "auto",
                    }}
                  >
                    {this.props.buttonIcon ? this.props.buttonIcon : closeIcon}
                  </i>
                </span>
              </button>
            </div>
            <div className="modal-body">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}
