import { put, call, select } from "redux-saga/effects";
import {
  GRAPHQL_TYPE_ENTITY,
  GRAPHQL_TYPE_PERSON,
  DeleteEntityAttributeKind,
  DeleteEntityAttributeInput,
  DeletePersonAttributeInput,
} from "@radivision/graphql";
import { getClientMutationId } from "../../../../../utilities/general";
import { deleteFromEntity, deleteFromPerson } from "../api";
import isEmpty from "lodash.isempty";
import { AppState } from "../../../../../redux";
import { cacheData } from "../../../../../redux/actions";
import {ProfileModuleState} from "../types";

export function* deleteFromProfileWorker() {
  const state = yield select((state) => state.profile);
  if (isEmpty(state.id)) {
    return;
  }
  const subjectId = state.id;
  const subject = yield select((state: AppState) => state.cache.store[subjectId]);
  try {
    if (subject?.__typename === GRAPHQL_TYPE_PERSON) {
      return yield call(deleteFromPersonWorker);
    }
    if (subject?.__typename === GRAPHQL_TYPE_ENTITY) {
      return yield call(deleteFromEntityWorker);
    }
  } catch (error) {
    console.log("Error: deleteFromProfile ", { error });
  }
}

function* deleteFromEntityWorker() {
  const state = yield select((state) => state.profile);
  if (isEmpty(state.id)) {
    return;
  }
  const subjectId = state.id;
  const subject = yield select((state: AppState) => state.cache.store[subjectId]);

  const ATTRIBUTES_TO_DELETE = [];

  const deleteLocation = isEmpty(state.location) && !isEmpty(subject.locations);
  if (deleteLocation) {
    ATTRIBUTES_TO_DELETE.push(DeleteEntityAttributeKind.LOCATIONS);
  }

  const deleteDescription = isEmpty(state.description) && !isEmpty(subject.description);
  if (deleteDescription) {
    ATTRIBUTES_TO_DELETE.push(DeleteEntityAttributeKind.DESCRIPTION);
  }

  const deleteTagLine = isEmpty(state.tagLine) && !isEmpty(subject.tagLine);
  if (deleteTagLine) {
    ATTRIBUTES_TO_DELETE.push("TAGLINE");
  }

  const deleteWebsiteUrl = isEmpty(state.websiteUrl) && !isEmpty(subject.websiteUrl);
  if (deleteWebsiteUrl) {
    ATTRIBUTES_TO_DELETE.push(DeleteEntityAttributeKind.WEBSITE);
  }

  const deleteFounded = isEmpty(state.founded) && !isEmpty(subject.founded);
  if (deleteFounded) {
    ATTRIBUTES_TO_DELETE.push(DeleteEntityAttributeKind.FOUNDED);
  }

  const deleteNoOfEmployees = isEmpty(state.noOfEmployees) && !isEmpty(subject.noOfEmployees);
  if (deleteNoOfEmployees) {
    ATTRIBUTES_TO_DELETE.push(DeleteEntityAttributeKind.NUMBER_OF_EMPLOYEES);
  }

  if (ATTRIBUTES_TO_DELETE.length === 0) return false;

  const input: DeleteEntityAttributeInput = {
    id: subject.id,
    revision: subject.revision,
    clientMutationId: getClientMutationId(),
    attributes: ATTRIBUTES_TO_DELETE,
  };

  const data = yield call(deleteFromEntity, input);
  yield put(cacheData({ key: subject.id, data }));
  return true;
}

function* deleteFromPersonWorker() {
  const state = yield select((state: ProfileModuleState) => state.profile);
  if (isEmpty(state.id)) {
    return;
  }
  const subjectId = state.id;
  const subject = yield select((state: AppState) => state.cache.store[subjectId]);

  const ATTRIBUTES_TO_DELETE = [];

  const deleteLocation = isEmpty(state.location) && !isEmpty(subject.locations);
  if (deleteLocation) {
    ATTRIBUTES_TO_DELETE.push(DeleteEntityAttributeKind.LOCATIONS);
  }

  const deleteDescription = isEmpty(state.description) && !isEmpty(subject.description);
  if (deleteDescription) {
    ATTRIBUTES_TO_DELETE.push(DeleteEntityAttributeKind.DESCRIPTION);
  }

  const deleteWebsiteUrl = isEmpty(state.websiteUrl) && !isEmpty(subject.websiteUrl);
  if (deleteWebsiteUrl) {
    ATTRIBUTES_TO_DELETE.push(DeleteEntityAttributeKind.WEBSITE);
  }

  const deleteResponsibility = isEmpty(state.responsibility) && !isEmpty(subject.responsibility);
  if (deleteResponsibility) {
    ATTRIBUTES_TO_DELETE.push("RESPONSIBILITY");
  }

  if (ATTRIBUTES_TO_DELETE.length === 0) return false;

  const input: DeletePersonAttributeInput = {
    id: subject.id,
    revision: subject.revision,
    clientMutationId: getClientMutationId(),
    attributes: ATTRIBUTES_TO_DELETE,
  };

  const data = yield call(deleteFromPerson, input);
  yield put(cacheData({ key: subject.id, data }));
  return true;
}
