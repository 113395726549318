/**
 * @author William A. Livesley
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-11-12
 * @description Implementation of MediaAssetFile class.
 * @filename media-asset-file.ts
 */
import { MediaAssetFile as GraphQlMediaAssetFile } from "@radivision/graphql/lib/ts/graphql/media-asset-file";
import { PagePixelUtility } from "./page-pixel-utility";
const fileType = require("file-type");

/**
 * Standard page widths
 *@radivision/graphq
 * @const
 * @type {Array<number>}
 */
// const WIDTHS: number[] = [1200, 992, 768, 576];

const WIDTHS: number[] = [1920, 1280, 768, 640, 320];

/**
 * A class containing utilities for media asset file manipulation
 */
export class MediaAssetFile {
  /**
   * Returns the Media Asset File which best matches the current screen size from the given collection of Media Asset Files.
   *
   * @param {Array<GraphQlMediaAssetFile>} mediaAssetFiles The collection of Media Asset Files
   *
   * @returns {MediaAssetFile} The Media Asset File which best matches the current screen size.
   */
  static getMediaAssetFileFromMediaAssetFiles(
    mediaAssetFiles: GraphQlMediaAssetFile[] = null
  ): MediaAssetFile {
    let desiredWidthInPixels: number;
    let mediaAssetFile: MediaAssetFile;
    let widthInPixel: number;
    let widthsInPixels: number[];

    if (mediaAssetFiles !== null && mediaAssetFiles.length > 0) {
      // Type Error: 0 is read-only bug from Firefox and Safari - not in Chrome
      desiredWidthInPixels = PagePixelUtility.getWidthOfPageInPixels();
      widthsInPixels = mediaAssetFiles
        .map((maf: GraphQlMediaAssetFile): number => {
          return maf.widthInPixels;
        })
        .sort((a: number, b: number): number => {
          return a - b;
        });
      widthInPixel = widthsInPixels.find(
        (value: number, index: number): boolean => {
          return (
            value > desiredWidthInPixels || index >= mediaAssetFiles.length - 1
          );
        }
      );
      mediaAssetFile = mediaAssetFiles.find(
        (maf: GraphQlMediaAssetFile): boolean => {
          return maf.widthInPixels === widthInPixel;
        }
      );
    }
    return mediaAssetFile === undefined ? null : mediaAssetFile;
  }

  /**
   * Returns the URL of the given media asset file.
   *
   * @param {string} baseUrl The base URL of the location of the media asset file.
   *
   * @param {GraphQlMediaAssetFile} mediaAssetFile The media asset file.
   *
   * @returns {string} The URL of the given file.
   */
  static getMediaAssetFileUrl(
    baseUrl: string,
    mediaAssetFile: GraphQlMediaAssetFile
  ): string {
    return `${baseUrl}/${mediaAssetFile.path}/${mediaAssetFile.name}`;
  }

  /**
   * Returns the URL of an image associated with a given media asset file.
   *
   * @param {GraphQlMediaAssetFile} mediaAssetFile The media asset file.
   *
   * @param {number} widthInPixels The desired width in pixels. If this is not supplied then the image is supplied at screen width.
   *
   * @param {boolean} setSize A flag which, if true, appends a size query parameter.
   *
   * @returns {string} The URL of an image associated with the given media asset file.
   */
  static getImageUrlFromMediaAssetFile(
    mediaAssetFile: GraphQlMediaAssetFile,
    widthInPixels: number = null,
    setSize: boolean = true
  ): string {
    let src: string = MediaAssetFile.getMediaAssetFileUrl(
      `${process.env.URL_MEDIA_IMAGE}`,
      mediaAssetFile
    );
    let wip: number;

    if (setSize) {
      wip =
        widthInPixels === null
          ? MediaAssetFile.getQuantizedWidthOfPageInPixels()
          : widthInPixels;
      src = `${src}?wip=${wip}`;
    }
    return src;
  }

  /**
   * Returns the quantized width of page - constrained to the closest size in the collection of sizes.
   *
   * @returns {number} The quantized width of the page.
   */
  static getQuantizedWidthOfPageInPixels(): number {
    let width: number = PagePixelUtility.getWidthOfPageInPixels();

    width = WIDTHS.find((width: number, index: number): boolean => {
      return (
        (index === 0 && width > width) ||
        index === WIDTHS.length - 1 ||
        (width > WIDTHS[index + 1] && width <= width)
      );
    });
    return width;
  }

  /**
   * Returns the relevant video URL from a given set of media asset files.
   *
   * @param {Array<GraphQlMediaAssetFile>} mediaAssetFiles The media asset files.
   *
   * @returns {string} The URL of the video link to a media asset file.
   */
  static getVideoUrlFromMediaAssetFiles(
    mediaAssetFiles: GraphQlMediaAssetFile[] = null
  ): string {
    // let mediaAssetFile: GraphQlMediaAssetFile = MediaAssetFile.getMediaAssetFileFromMediaAssetFiles(
    //   mediaAssetFiles
    // );
    let mediaAssetFile: GraphQlMediaAssetFile;
    if (mediaAssetFiles !== null && mediaAssetFiles.length > 0) {
      mediaAssetFile = mediaAssetFiles.find(
        (maf: GraphQlMediaAssetFile): boolean => {
          return maf.name.indexOf("m3u8") > -1;
        }
      );
    }

    if (mediaAssetFile === undefined || mediaAssetFile === null) {
      mediaAssetFile = MediaAssetFile.getMediaAssetFileFromMediaAssetFiles(
        mediaAssetFiles
      );
    }

    return mediaAssetFile === undefined || mediaAssetFile === null
      ? null
      : mediaAssetFile.name.includes("m3u8")
      ? MediaAssetFile.getMediaAssetFileUrl(
          `${process.env.URL_MEDIA_STREAMING}`,
          mediaAssetFile
        )
      : MediaAssetFile.getMediaAssetFileUrl(
          `${process.env.URL_MEDIA_VIDEO}`,
          mediaAssetFile
        );
  }
  /**
   * Returns corresponding file mim-type to provided file magic header
   * @param {string} fileMagicHeader 4 or 8 bytes containing file magic header
   * @returns {string} file mime-type
   */
  static extractFileMimeType(fileMagicHeader: string): string {
    let fileMimeType: string;
    // convert extracted hex value of file magic header to uppercase
    const HEADER = fileMagicHeader.toUpperCase();

    // // console.log("[extractFileMimeType] loaded fileMagicHeader : ", fileMagicHeader);
    // validate extracted magic header to our supported types
    // {png, svg, jpg, jpeg}
    switch (HEADER) {
      case "89504E47":
        fileMimeType = "image/png";
        break;
      case "3C3F786D":
        fileMimeType = "image/svg";
        break;
      case "FFD8FFE1":
        fileMimeType = "image/jpg";
        break;
      case "FFD8FFE0":
      case "FFD8FFE2":
      case "FFD8FFE3":
        fileMimeType = "image/jpeg";
        break;
      default:
        fileMimeType = "unknown";
    }
    // // console.log(
    //   "[extractFileMimeType] extracted file mime-Type : ",
    //   fileMimeType
    // );
    return fileMimeType;
  }

  /**
   *
   *
   * @static
   * @param {Blob} file
   * @memberof MediaAssetFile
   */
  static extractFileData(
    file: Uint8Array | ArrayBuffer
  ): { ext: string; mime: string } {
    return fileType(file);
  }
}
