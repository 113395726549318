import { fetchQuery } from "react-relay";
import { commitMutation } from "../../../../utilities/relay";
import { ENVIRONMENT } from "../../../../relay/relay-environment";
import { EVENT_QUERY } from "../../../../relay/queries/event-query";
import { CREATE_RSVP_REQUEST_MUTATION } from "../../../../relay/mutations/create-rsvp-request";
import {CreateInvestAmountRequestPayload} from "./types";
import {getClientMutationId, gaEvent} from "../../../../utilities/general";

export const fetchEvent = async (input) => {
  return fetchQuery(
    ENVIRONMENT,
    EVENT_QUERY,
    {
      id: input.id,
    },
    {
      force: true,
    },
  ).then((result) =>
    result.event.hasErrors === false ? Promise.resolve(result.event.event) : Promise.reject(result.event.errors),
  );
};

export const createRSVPRequest = async (input) => {
  let promise: Promise<any>;
  const config = {
    mutation: CREATE_RSVP_REQUEST_MUTATION,
    variables: { input },
  };
  console.log("DEBUG: createRSVPRequest ", { config });
  //
  promise = commitMutation(ENVIRONMENT, config);

  promise = promise.then((mutationResults: any) => {
    return mutationResults.createRSVPRequest.hasErrors === false
      ? Promise.resolve(mutationResults.createRSVPRequest.userRequest)
      : Promise.reject(mutationResults.createRSVPRequest.errors);
  });

  return promise;
};

export const createInvestAmountRequest = async (payload: CreateInvestAmountRequestPayload) => {
  const input = {
    id: payload.entity?.id,
    clientMutationId: getClientMutationId(),
    name: payload.name,
    email: payload.email,
    entity: payload.entity?.id,
    amount: payload.amount,
  };

  gaEvent("PP-More-info", "click", payload.entity.name, false);

  const PITCH_MUTATIONS = await import("../../../../relay/mutations-pitch");
  return commitMutation(ENVIRONMENT, {
    mutation: PITCH_MUTATIONS.CREATE_USER_INVESTMENT_REQUEST_MUTATION,
    variables: { input },
  }).then((response) => {
    return response.createUserInvestmentRequest.hasErrors === true
      ? Promise.reject(response.createUserInvestmentRequest.errors)
      : Promise.resolve(response.createUserInvestmentRequest);
  });
};
