/**
 * @author Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-07-25
 * @description Implementation of action modal context to store action modal data in the react Context.
 * @filename action-modal-context.ts
 */
import React from "react";
import { ActionModalProps } from "../react-components/modals/action-modal";

/**
 * the context used to trigger the action modal with specified props.
 */
export const ACTION_MODAL_CONTEXT: React.Context<{
  showModal: (props: ActionModalProps) => any;
}> = React.createContext({
  showModal: (props: ActionModalProps) => {
    // console.log(props);
  }
});
