
$dark-mode-bg: #161616;
$dark-mode-lighter: #1b1b1b;
$dark-mode-much-lighter: #383838;
$dark-mode-black: #202020;
$dark-empty-placeholder: rgba(255,255,255,0.05);
$dark-input: #313131;
$dark-input-placeholder: rgba(255, 255, 255, 0.22);
$danger: #f64a6b;
$snow-white: #fafafa;
$radivision-blue: #4cb4e7;
$radivision-dk-gray: #32323b;
$radivision-image-border: #f0f0f0;
$radivision-lt-gray: #f2f4f4;
$skyblue: #4cb4e7;
$dark-gray: #32323b;
$light-gray: #f2f4f4;
$gray: #4a4a4f;
$darker-gray: #25252a;
$logo-headshot-border: rgba(white, 0.3);
$moveLeft: -(200 * (1.2 - 1) / 2);
$moveRight: 200 * (1.2 - 1);
$highlight: #fffa57;

$font-size: 26px;
$line-height: 1.2;
$lines-to-show: 3;
