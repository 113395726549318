import { Person, Entity, GRAPHQL_TYPE_PERSON, PreviewKind } from "@radivision/graphql";
import $ from "jquery";
import { isValidUrl } from "../../../../utilities/general";
import { SocialMediaProfiles } from "./types";
import isEmpty from "lodash.isempty";
import { socialPlatforms } from "../../../../constants/social-platforms";

export function profileHasLogo(subject: Person | Entity | null | undefined) {
  if (!subject) {
    return false;
  }

  const hasLogo = subject.previews?.edges?.find((media: any) => {
    const logoKind = subject.__typename === GRAPHQL_TYPE_PERSON ? PreviewKind.HEADSHOT : PreviewKind.LOGO;
    return media.preview.kind === logoKind;
  });

  return hasLogo;
}

export function getSocialMediaProfilesToBeAdded(
  socialMediaProfiles: SocialMediaProfiles,
  originalData: SocialMediaProfiles,
) {
  const socialProfilesToBeAdded =
    socialMediaProfiles &&
    Object.keys(socialMediaProfiles).reduce((toBeAdded, key) => {
      const url = socialMediaProfiles[key];
      const originalUrl = originalData && originalData[key];
      if (isValidUrl(url) && url !== originalUrl) {
        toBeAdded.push({
          platform: key,
          url,
        });
      }
      return toBeAdded;
    }, []);
  return socialProfilesToBeAdded;
}

export function toggleNavMenu() {
  $(".navbar-collapse").toggleClass("expanded");
  $(".navbar-toggler").toggleClass("is-active");
  $(".navbar-toggler").focus();
}
export function getSocialMediaProfilesToBeRemoved(
  socialMediaProfiles: SocialMediaProfiles,
  currentData: SocialMediaProfiles,
) {
  const socialLinksToBeRemoved =
    socialMediaProfiles &&
    Object.keys(socialPlatforms).reduce((toBeRemoved, key) => {
      const item = socialMediaProfiles[key];
      const currentValue = currentData && currentData[key];
      if (isEmpty(item) && !isEmpty(currentValue)) {
        toBeRemoved.push(key);
      }
      return toBeRemoved;
    }, []);
  console.log("debug: ", { socialMediaProfiles, currentData, socialLinksToBeRemoved});
  return socialLinksToBeRemoved;
}

export function isValidSocialMediaProfiles(socialMediaProfiles: SocialMediaProfiles | null) {
  const invalidSocialLink =
    socialMediaProfiles &&
    Object.keys(socialMediaProfiles).find((k) => {
      const platform = socialPlatforms[k];
      const isValid = socialMediaProfiles[k] ? platform.regexp[0].test(socialMediaProfiles[k]) : true;
      if (!isValid) {
        return true;
      }
      return false;
    });
  return invalidSocialLink;
}
