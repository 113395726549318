import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";
import { AppState } from "../../redux";
import { PlayFullScreenVideoPayload, PlayFullScreenVideoAction } from "../../redux/full-screen-video/types";
import { extractYoutubeId } from "../../utilities/extract-youtube-id";
import { CarouselItem } from "../../component-configuration/carousel-item";
import { SVG } from "../../component-configuration/svgs";
import isEmpty from "lodash.isempty";

interface ComponentProps {
  item: CarouselItem;
  index: number;
}

interface MapDispatchProps {
  playFullScreenVideo: (p: PlayFullScreenVideoPayload) => PlayFullScreenVideoAction;
}
interface MapStateProps {}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { item, playFullScreenVideo } = props;
  const videoId = item.youTubeVideo || extractYoutubeId(item.link);

 
  const onClick = React.useCallback(() => {
    playFullScreenVideo({ videoId });
  }, [videoId, playFullScreenVideo]);

  if (!videoId) {
    return null;
  }

  return (
    <span className="play-btn" onClick={onClick}>
      {SVG.play}
    </span>
  );
};

const mapState = (state: AppState) => ({
  user: state.account.user,
});

const mapDispatch = {
  playFullScreenVideo: Actions.playFullScreenVideo,
};

export default connect(mapState, mapDispatch)(Component);
