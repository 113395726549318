import Loadable from "react-loadable";
import OptionsWithoutRender = LoadableExport.OptionsWithoutRender;
import { Person, Entity } from "@radivision/graphql";

interface ComponentProps {
  subject: Person | Entity;
  screen?: string;
  title?: string;
}

type Props = ComponentProps;

const loadableOptions: OptionsWithoutRender<Props> = {
  loader: () => import("../../../modules/manage-profile").then((module) => module.EditProfileButton) as Promise<any>,
  loading: () => null,
};

export const EditProfileButton = Loadable(loadableOptions);
