/**
 * @author Ahmed Serag
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-03-31
 * @description Implementation of the Footer React component.
 * @filename footer.tsx
 */
import React from "react";
import LazyLoad from "react-lazyload";
import { Router } from "../../utilities/router";
import { SVG } from "../../component-configuration/svgs";
import { Disclaimer } from "./disclaimer";
const LOGO = require("../../../static/rv-logo-on-black.png");

/**
 *  A React component that renders the footer.
 *
 * @export
 * @class Navbar
 * @extends {React.Component<FooterProps, FooterState>}
 */
export class Footer extends React.Component<{}, {}> {
  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render(): React.ReactNode {
    return (
      <div id="Footer" className="footer container-fluid">
        <div className="row newDesign">
          <div className="col-md-12 col-lg-4">
            <LazyLoad once={true}>
              <img src={LOGO} alt="Radivision logo" className="logo d-inline-block" />
            </LazyLoad>
            <ul className="social-icons">
              <li>
                <a href="https://www.facebook.com/radivision/" target="_blank">
                  <button id="facebookIcon" className="btn-circle btn-lg">
                    {SVG.facebook}
                  </button>
                  <span>Facebook</span>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/radivision/" target="_blank">
                  <button id="instagramIcom" className="btn-circle btn-lg">
                    {SVG.instagram}
                  </button>
                  <span>Instagram</span>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/radivisiontv" target="_blank">
                  <button id="twitterIcon" className="btn-circle btn-lg">
                    {SVG.twitter}
                  </button>
                  <span>Twitter</span>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/radivision/" target="_blank">
                  <button id="linkedinIcon" className="btn-circle btn-lg">
                    {SVG.linkedin}
                  </button>
                  <span>Linkedin</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-12 col-lg-8">
            <div className="row md4Responsive">
              <div className="col-4">
                <h5 className="mt-3 mb-4 col-title">company</h5>

                <ul className="list-unstyled">
                  <li>
                    <a onClick={() => Router.navigateTo("about")}>About</a>
                  </li>
                  {/* <li>
                    <a onClick={() => Router.navigateTo("about", undefined, "team")}>Team</a>
                  </li> */}
                  <li>
                    <a onClick={() => Router.navigateTo("partners")}>Partners</a>
                  </li>
                  <li>
                    <a
                      href="https://blog.radivision.com/"
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.preventDefault();
                        const blogURL = "https://blog.radivision.com/";
                        window.open(blogURL, "_blank");
                      }}
                    >
                      News
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://blog.radivision.com/"
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.preventDefault();
                        const blogURL = "https://blog.radivision.com/";
                        window.open(blogURL, "_blank");
                      }}
                    >
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-4">
                <h5 className="mt-3 mb-4 col-title">Support</h5>

                <ul className="list-unstyled">
                  <li>
                    <a onClick={() => Router.navigateTo("get-started")}>Get Started</a>
                  </li>
                  <li>
                    <a onClick={() => Router.navigateTo("support", undefined, "contact-us")}>Contact us</a>
                  </li>
                  <li>
                    <a onClick={() => Router.navigateTo("support", undefined, "technical-issues")}>Technical Issues</a>
                  </li>
                  {/* <li>
                    <a onClick={() => Router.navigateTo("get-started", undefined, "faq")}>FAQs</a>
                  </li> */}
                  <li>
                    <a onClick={() => Router.navigateTo("support", undefined, "report-an-issue")}>Report an Issue</a>
                  </li>
                </ul>
              </div>
              <div className="col-4">
                <h5 className="mt-3 mb-4 col-title">Legal</h5>

                <ul className="list-unstyled">
                  <li>
                    <a onClick={() => Router.navigateTo("legal", undefined, "privacy-policy")}>Privacy Policy</a>
                  </li>
                  <li>
                    <a onClick={() => Router.navigateTo("legal", undefined, "terms-conditions")}>Terms & Conditions </a>
                  </li>
                  <li>
                    <a onClick={() => Router.navigateTo("legal", ["section=cookies-section"], "privacy-policy")}>
                      Cookies
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Disclaimer />
      </div>
    );
  }
}
