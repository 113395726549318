@import "../../../../scss/imports.scss";
.getting-started {
  display: flex;
  margin-top: 120px;
  flex-direction: column;
  justify-content: flex-start;
  // align-items: center;
  width: 100%;
  padding: 0px 0px;

  .carousel-dashes {
    display: flex;
    justify-content: center;
    margin: -25px 0px 70px 0px;

    .dash {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #4a4a4f;
      margin: 0 3px;
      // cursor: pointer;

      &.active {
        background-color: $radivision-blue; /* Change the color for the active dash */
      }
    }
  }

  .question-and-answer {
    // width: 80%;
    div {
      margin-left: 15px;
      position: relative;
      padding: 0px 0px;

      h3 {
        text-align: left;
        font-size: 25px;
        font-family: "MuliBold";
        color: #4cb4e7;
        margin: 1.5rem 30px 3rem 30px;
        & > b {
          display: none;
        }

        @include media-breakpoint-down(md) {
          font-size: 28px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 22px;
        }

        &:hover {
          cursor: pointer;
        }

        @media (min-width: 2500px) {
          font-size: 48px;
        }
      }

      @keyframes topFadeIn {
        0% {
          opacity: 0;
        }

        50% {
          opacity: 0.5;
        }

        100% {
          opacity: 1;
        }
      }

      svg {
        width: 17px;
        fill: white;
        stroke: white;
        position: absolute;
        top: 9px;
        left: 0;
        cursor: pointer;

        // @include media-breakpoint-down(lg) {
        //   left: 23rem;
        // }

        @include media-breakpoint-down(md) {
          top: 10px;
        }

        @include media-breakpoint-down(sm) {
          width: 10px;
          // left: 16rem;
        }
      }

      .hide {
        display: none;
      }

      .show {
        margin-top: 0px;
        animation-name: topFadeIn;
        animation-duration: 1.5s;
        & > span {
          display: none;
        }

        // @include media-breakpoint-down(md)  {
        //   margin-top: -15px;
        // }

        // @include media-breakpoint-down(sm) {
        //   margin-top: -20px;
        // }
      }

      .answer {
        position: relative;
        bottom: 35px;
        margin-left: 15px;
        font-size: 18px;

        @include media-breakpoint-down(md) {
          font-size: 12pt;
          bottom: 24px;
          margin-left: 20px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 12pt;
        }

        a {
          color: #4cb4e7;
          font-size: 18px;

          @include media-breakpoint-down(md) {
            font-size: 12pt;
          }

          @include media-breakpoint-down(sm) {
            font-size: 12pt;
          }
          // @media (min-width: 2500px) {
          //   font-size: 18pt;
          // }

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
        .graph-img {
          display: block;
          margin: 1.5rem auto;
          width: 40%;
        }
      }
      span {
        font-weight: bold;
        color: #4cb4e7;
        font-size: 24px;
        @include media-breakpoint-down(md) {
          font-size: 12pt;
        }
      }
    }
  }

  .getting-started-container {
    .carousel-title {
      color: white;
      text-align: center;
      width: 40%;
      font-size: 24px;
      margin: 0px auto -35px auto;
      // margin: 10px auto;
    }

    .header-sub {
      color: $radivision-blue;
      width: 65%;
      font-size: 29px;
      font-family: "MuliSemiBold";
      text-align: center;
      @media (max-width: 800px) {
        font-size: 18px;
      }

      &.original {
        font-size: 25px;
        margin-top: 3rem;
        margin-bottom: 3rem;
      }
    }
    .getting-started-header {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        text-align: center;
        font-size: 36px;
        font-weight: 600;
        font-family: "MuliBold";
      }

      .header-sub {
        color: $radivision-blue;
        width: 65%;
        font-size: 25px;
        font-family: "MuliSemiBold";
        text-align: center;
      }

      h5 {
        text-align: center;
        color: $radivision-blue;
        font-size: 18px;
        font-family: "MuliSemiBold";
        margin-bottom: 5rem;
        cursor: pointer;
      }

      // .banner-text {
      //   width: 50%;
      //   font-size: 22px;
      //   margin-bottom: 4px;
      //   color: $radivision-blue;
      //   font-family: "MuliBold";

      //   @media (min-width: 600px) {
      //     font-size: 22px;
      //   }
      //   @media (min-width: 1200px) {
      //     font-size: 24px;
      //   }
      //   @media (min-width: 1600px) {
      //     font-size: 32px;
      //   }
      // }

      .gs-btn {
        margin: 60px 0px 60px 0px;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        button {
          height: 44px;
          cursor: pointer;
          border-radius: 30px;
          background-color: $radivision-blue;
          color: white;
          font-weight: 500;
          font-size: 22px;
          font-family: "MuliBold";
          padding: 0px 20px;
          border: 0px solid transparent;
        }
      }
      // .banner-content {
      //   position: relative;
      //   display: flex;
      //   flex-wrap: wrap;
      //   flex-direction: column;
      //   justify-content: center;
      //   align-items: center;
      //   padding: 0px 100px 0px 20px;
      //   width: 50%;
      //   // margin-right: auto;
      //   // top: 50%;
      //   // transform: translateY(30%);
      //   @media (max-width: 1200px) {
      //     justify-content: center;
      //     align-items: center;
      //     padding: 0px;
      //     width: 100%;
      //   }

      //   .banner-logo {
      //     height: auto;
      //     width: 60%;
      //     // margin: 0 auto;
      //     // margin-left: 3.5rem;

      //     @media (max-width: 600px) {
      //       width: 80%;
      //       // margin-left: 1.75rem;
      //     }
      //     @media (max-width: 1200px) {
      //       width: 40%;
      //     }
      //   }
      // }
    }
    .members-banner {
      padding: 10px 0px 20px 0px;
      position: relative;
      // padding-bottom: 20%;
      background-size: 100% 110%;
      background-position: top center;
      background-repeat: no-repeat;
      @media (min-width: 1200px) {
        background-size: 100% auto;
      }
      .members-banner-shade {
        display: block;
        opacity: 1;
        position: absolute;
        bottom: 0px;
        top: 0px;
        left: 0px;
        right: 0px;
        background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 80%);
      }
    }

    #faq {
      margin: 0 auto;
      width: 80%;
    }

    .original-series {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        height: auto;
        width: 13%;
        border-radius: 10px;
        border: 2px solid rgb(46, 46, 46);
      }

      .header-sub {
        color: $radivision-blue;
        width: 75%;
        font-size: 25px;
        text-align: center;
      }
    }

    .mission-vision {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 0px 20px 0px;
      position: relative;
      // padding-bottom: 20%;
      background-size: 100% 95%;
      background-position: top center;
      background-repeat: no-repeat;
      margin-top: -25px;

      .gs-btn {
        margin: 60px 0px 60px 0px;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        button {
          height: 44px;
          cursor: pointer;
          border-radius: 30px;
          background-color: $radivision-blue;
          color: white;
          font-weight: 500;
          font-size: 22px;
          font-family: "MuliBold";
          padding: 0px 20px;
          border: 0px solid transparent;
        }
      }

      .mission-vision-text {
        display: flex;
        justify-content: space-around;
        margin: 4.5rem auto;

        div {
          width: 35%;

          h4 {
            text-align: center;
          }

          p {
            color: $radivision-blue;
            font-size: 22px;
            // width: 100%;
            text-align: center;
          }
        }
      }

      .mission-img {
        margin-top: 2rem;
        height: auto;
        width: 8%;
      }
    }

    h3 {
      text-align: center;
      font-family: "MuliBold";
      font-size: 29px;
      font-weight: 800;
      color: white;
      margin: 3rem 0px 1rem 0px;
      z-index: 1;
      &.member {
        margin: 4rem 0px 6rem 0px;
      }
      &.original {
        margin: 2rem 0px 2rem 0px;
      }
      &.faq {
        margin: 3rem 0px 3rem 0px;
        color: white;
      }
      @media (min-width: 1200px) {
        font-size: 26px;
      }
      @media (min-width: 1600px) {
        font-size: 30px;
      }
    }
    .faqs {
      margin-bottom: 2rem;
    }

    .success-images {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      gap: 1.5rem;
      img {
        border: 1px solid rgb(92, 92, 92);
        border-radius: 12px;
        height: auto;
        width: 16%;
      }
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: center;
        img {
          width: 80%;
        }
      }
    }
    .faq {
      color: $radivision-blue;
      font-size: 26px;
      margin-bottom: 0.5rem;
      margin-left: 1rem;
      svg {
        fill: white;
        stroke: white;

        width: 15px;
        height: 15px;
        cursor: pointer;
        vertical-align: middle;
        margin-right: 0.75rem;
      }
    }

    .contact-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      button {
        cursor: pointer;
        font-family: MuliBold;
        text-align: center;
        border-radius: 30px;
        border: 0px;
        background-color: $radivision-blue;
        color: white;
        font-weight: 500;
        font-size: 22px;
        // width: 220px;
        padding: 8px 10px;
      }
    }
    .contact {
      color: $radivision-blue;
      cursor: pointer;
    }
    .assistance {
      margin: 4.5rem auto;
    }
    .signup {
      display: flex;
      flex-direction: column;
      align-items: center;
      label {
        position: relative;
      }
      #gettingStartedPanel {
        text-align: center;
        padding: 10px 10px;
        margin-bottom: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: rgba(22, 22, 22, 1);

        @include media-breakpoint-down(sm) {
          padding: 35px !important;
          height: 350px;
        }

        h3 {
          font-family: "MuliBold";
          font-size: 22px;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
          text-align: center;

          @media (min-width: 2000px) {
            font-size: 48px;
          }
        }

        p {
          font-family: "Muli";
          font-size: 16px;
          color: rgba(255, 255, 255, 1);
          text-align: center;
          margin-bottom: 40px;

          @media (min-width: 2000px) {
            font-size: 22px;
          }
        }

        form {
          position: relative;

          button {
            position: absolute;
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            right: 0;
            margin: 0;
            border-radius: 29px;
            font-family: MuliBold;
            font-size: 22px;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            letter-spacing: 0.03em;
            border: 0;
            // height: 57px;
            width: 180px;
            text-transform: capitalize;

            @media (min-width: 2000px) {
              height: 60px;
            }

            @include media-breakpoint-down(sm) {
              width: 100%;
              position: relative;
              padding: 8px 10px;
            }
          }

          input {
            border: 1px solid #c2c2c2;
            border-radius: 6px;
            padding-left: 6px;
            padding-bottom: 10px;
            padding-top: 10px;
            padding-left: 30px;
            width: 488px;
            background: transparent;
            border-radius: 29px;
            color: white;
            font-family: "Muli";
            font-size: 16px;
            color: rgba(255, 255, 255, 1);
            letter-spacing: 0.03em;

            // color: #c2c2c2;
            @media (min-width: 2000px) {
              width: 600px;
              height: 60px;
              font-size: 18px;
            }

            @include media-breakpoint-down(sm) {
              width: 100%;
              margin-bottom: 10px;
            }
          }

          .msg-alert {
            position: absolute;
            // color: #721c24;
            // background-color: #f8d7da;
            // border-color: #f5c6cb;
            width: 100%;
            color: black;
            text-align: center;
            top: 75px;
            padding: 15px;
            border-radius: 29px;

            @include media-breakpoint-down(sm) {
              top: 130px;
            }

            p {
              margin: 0;
            }
          }

          @include media-breakpoint-down(sm) {
            width: 100%;
            flex-direction: column !important;
          }
        }
      }
      input {
        background-color: transparent;
        border: 2px solid rgb(92, 92, 92);
        color: white;
        border-radius: 50px;
        padding: 1rem;
      }
      button {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        border: 0px;
        cursor: pointer;
        padding: 10px 10px;
        text-align: center;
        border-radius: 50px;
        background-color: $radivision-blue;
        color: white;
        font-weight: 500;
        font-size: 20px;
      }
    }
  }
}

.getting-started {
  .members {
    display: flex;
    flex-basis: 50%;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 80%;
    margin: auto;
    .circle {
      position: relative;
      width: 160px;
      height: 160px;
      border-radius: 50%;
      font-size: 50px;
      color: black;
      text-align: center;
      background: white;
      margin-bottom: 20px;
      // &:first-child {
      //   background: $radivision-blue;
      //   color: white;
      // }
      p {
        height: 100%;
        width: 100%;
        margin: 0px;
        font-family: "MuliSemiBold";
        font-size: 18px;
        font-weight: 400;
        align-items: center;
        justify-content: center;
        display: flex;
        img {
          width: 45%;
        }
      }
    }
    .experts {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-left: 10%;
      }
    }
  }
  @media (min-width: 1200px) {
    .members {
      .circle {
        width: 180px;
        height: 180px;
        p {
          width: auto;
          font-size: 20px;
          padding: 10px;
          margin: 0px;
          line-height: 24px;
          white-space: normal;
          img {
            width: 65%;
          }
        }
      }
      .experts {
        img {
          width: 90%;
        }
      }
    }
  }
  @media (min-width: 1600px) {
    .members {
      .circle {
        width: 210px;
        height: 210px;
        p {
          font-size: 20px;
          line-height: 30px;
          img {
            width: 65%;
          }
        }
      }
    }
  }
}
.getting-started {
  .videos-grid {
    display: grid;
    margin-top: 4rem;
    grid-template-columns: repeat(1, 1fr);
    gap: 80px 50px;
    .video-item {
      cursor: pointer;
      border: 1px solid transparent;
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      height: 0px;
      width: 100%;
      padding-bottom: 52%;
      position: relative;
      .video-panel {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 1;
        flex: 1;
      }
      .youtube-on-hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        & > div,
        iframe {
          width: 100%;
          height: 100%;
        }
      }
      .video-preview {
        flex: 1;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 0px;
        padding-bottom: 52%;
        // filter: blur(3px);
        position: absolute;
        top: -6px;
        left: -6px;
        right: -6px;
        bottom: -6px;
        z-index: 0;
      }
      .title {
        background-color: rgba(0, 0, 0, 0.35);
        text-shadow: 1px 1px 10px rgba(0, 0, 0, 1);
        text-align: center;
        position: absolute;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 1;
        h3 {
          padding: 0px;
          margin: 30px 0px 0px 0px;
          color: #4cb4e7;
          font-family: MuliSemiBold;
          font-size: 24px;
        }
        h4 {
          padding: 0px;
          margin: 5px 0px;
          font-size: 22px;
        }
        .play-icon {
          pointer-events: none;
          margin-top: 20px;
          background-color: $radivision-blue;
          padding: 8px 40px;
          border-radius: 40px;
          svg {
            width: 25px;
            height: 25px;
            fill: white;
          }
        }
      }
      &.is-active {
        filter: blur(0);
      }
    }
    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  @media (min-width: 600px) {
    .getting-started-container {
      .videos-grid {
        grid-template-columns: repeat(4, 1fr);
        display: grid;
        gap: 20px 20px;
        .video-item {
          .title {
            padding: 0px 0px;
            h3 {
              margin: 30px 0px 0px 0px;
              font-size: 20px;
              text-transform: uppercase;
            }
            h4 {
              font-size: 16px;
            }
            .play-icon {
              margin-top: 10px;
              background-color: $radivision-blue;
              padding: 8px 40px;
              border-radius: 40px;
              svg {
                width: 20px;
                height: 20px;
                fill: white;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 1200px) {
    .getting-started-container {
      .videos-grid {
        gap: 100px 40px;
        .video-item {
          .title {
            padding: 0px 0px;
            h3 {
              margin: 30px 0px 0px 0px;
              font-size: 20px;
              text-transform: uppercase;
            }
            h4 {
              font-size: 16px;
            }
            .play-icon {
              margin-top: 10px;
              background-color: $radivision-blue;
              padding: 6px 20px;
              display: flex;
              justify-content: center;
              align-items: center;

              border-radius: 40px;
              svg {
                width: 14px;
                height: 14px;
                fill: white;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 1600px) {
    .getting-started-container {
      .videos-grid {
        gap: 100px 50px;
        .video-item {
          cursor: pointer;
          .title {
            padding: 20px 0px;
            h3 {
              margin: 60px 0px 0px 0px;
              font-size: 28px;
              text-transform: uppercase;
            }
            h4 {
              font-size: 24px;
            }
            .play-icon {
              margin-top: 30px;
              background-color: $radivision-blue;
              padding: 6px 30px;
              border-radius: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                margin-left: 5px;
                width: 20px;
                height: 28px;
                fill: white;
              }
            }
          }
        }
      }
    }
  }
}

.getting-started {
  .getting-started-container {
    .gs-header {
      color: $radivision-blue !important;
      margin: 2rem;
      text-align: center;
      font-family: MuliBold;
    }
  }
  @media (min-width: 1200px) {
    .getting-started-container {
      .gs-btn {
        margin-top: 25px;

        a {
          display: flex;
          flex: 1;
          height: 44px;
          border-radius: 40px;
          max-width: 140px;
          align-items: center;
          justify-content: center;
          font-size: 26px;
          font-family: MuliBold;
        }
      }
      .gs-header {
        margin: 50px 30px 80px 30px;
        font-size: 36px;
      }
    }
  }
  @media (min-width: 1600px) {
    .getting-started-container {
      .gs-btn {
        margin-top: 30px;
        a {
          display: flex;
          flex: 1;
          height: 50px;
          border-radius: 40px;
          max-width: 180px;
          align-items: center;
          justify-content: center;
          font-size: 32px;
          font-family: MuliBold;
        }
      }
      .gs-header {
        margin: 50px 30px 80px 30px;
        font-size: 36px;
      }
    }
  }
}

.getting-started {
  .getting-started-container {
    .bullet-points {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      margin: auto;
      flex-flow: row wrap;
      .points {
        display: flex;
        flex-direction: column;
        margin: 10px 0px;
        width: 30%;
        min-width: 300px;
        flex: 0.3;
        text-align: center;
        .title {
          background-color: $radivision-blue;
          border-radius: 40px;
          padding: 1rem;
          // width: 100%;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: center;
          color: white;
          font-size: 16px;
          font-family: MuliSemiBold;
          align-items: stretch;
          span {
            text-align: left;
            font-size: 16px;
            @media (min-width: 1200px) {
              font-size: 18px;
            }
          }
          span.expand-icon {
            width: 20px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            @media (max-width: 1200px) {
              margin-right: 3px;
            }
          }
          svg {
            fill: white;
            stroke: white;
            width: 20px;
            height: 20px;
            stroke: white;
            stroke-width: 3px;
            // transform: translate(-5px, 0) rotate(90deg);
          }
        }
        ul {
          margin-left: 4px;
          color: white;
          text-align: left;
          flex: 1;
          li {
            color: $radivision-blue;
            p {
              color: white;
            }
          }
        }
        &.expanded {
          .title {
            // svg {
            //   display: none;
            // }
          }
        }
      }
    }
  }
  @media (min-width: 1200px) {
    .getting-started-container {
      .bullet-points {
        .points {
          flex-basis: 28%;
          flex-grow: 0;
          .title {
            font-size: 18px;
            min-width: 300px;
            svg {
              fill: white;
              stroke: white;
              stroke-width: 3px;
              width: 20px;
              height: 20px;
            }
            span.expand-icon {
              width: 40px;
              margin-left: 0px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          ul {
            padding: 20px 20px 20px 60px;
            li {
              font-size: 18px;
              color: $radivision-blue;
              p {
                color: white;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 1600px) {
    .getting-started-container {
      .bullet-points {
        .points {
          .title {
            font-size: 18px;
          }
        }
      }
    }
  }
}

#video-modal {
  .modal-lg {
    max-width: 100vw;
    height: 100vh;
    width: 100vw;
    border-radius: 0;
    padding: 0px;
    .modal-content {
      height: 100vh;
      display: flex;
      .modal-header {
        p {
          font-size: 22px;
        }
      }
      .modal-body {
        padding: 0px;
        .content {
          flex: 1;
          height: 100%;
          .video-modal-container {
            flex: 1;
            height: 100%;
            display: flex;
            .howto-video-container {
              flex: 1;
              height: 100%;
            }
          }
        }
      }
    }
  }
  @media (min-width: 1600px) {
    .modal-lg {
      .modal-content {
        display: flex;
        .modal-body {
          .content {
            .video-modal-container {
              .howto-video-container {
              }
            }
          }
        }
      }
    }
  }
}
