export const CompanyTypes = [
  { id: "1", title: "Educational", kind: "EDUCATIONAL" },
  { id: "2", title: "Government Agency", kind: "GOVERNMENT_AGENCY" },
  { id: "3", title: "Nonprofit", kind: "NON_PROFIT" },
  { id: "4", title: "Partnership", kind: "PARTNERSHIP" },
  { id: "5", title: "Privately Held", kind: "PRIVATELY_HELD" },
  { id: "6", title: "Public Company", kind: "PUBLIC_COMPANY" },
  { id: "7", title: "Self-Employed", kind: "SELF_EMPLOYED" },
  // { id: "7", title: "Self Owned", kind: "SELF_OWNED" },
];

export const CompanyInvestmentStages = [
  { id: "1", title: "Pre-Seed" },
  { id: "2", title: "Seed" },
  { id: "3", title: "Series A" },
  { id: "4", title: "Series B or later" },
];

export const CompanyIndustries = [
  { id: "1", title: "Advertising / Pr / Marketing" },
  { id: "1", title: "Age Tech" },
  { id: "1", title: "Analytics / Data / AI" },
  { id: "1", title: "AR / VR / Machine Vision" },
  { id: "1", title: "Arts / Recreation" },
  { id: "1", title: "Beauty / Cosmetics" },
  { id: "1", title: "Biotech / Pharma / Medical" },
  { id: "1", title: "Communication / Messaging / Social Media" },
  { id: "1", title: "Clean Tech / Energy / Environment" },
  { id: "1", title: "Clothing / Fashion" },
  { id: "1", title: "Cloud Infrastructure" },
  { id: "1", title: "Cryptocurrency" },
  { id: "1", title: "Cybersecurity" },
  { id: "1", title: "Developer Tools" },
  { id: "1", title: "Digital Health / Wellness / Fitness" },
  { id: "1", title: "Distributed Work Force / Future of Work" },
  { id: "1", title: "E-Commerce / Retail / Wholesale" },
  { id: "1", title: "Education" },
  { id: "1", title: "Electronics and Computing Equipments" },
  { id: "1", title: "Entertainment and Sports" },
  { id: "1", title: "Finance / Payments" },
  { id: "1", title: "Food / Agriculture / Beverage" },
  { id: "1", title: "Gaming / eSports" },
  { id: "1", title: "Government / Regulation" },
  { id: "1", title: "Hiring / Recruiting" },
  { id: "1", title: "Hospitality" },
  { id: "1", title: "HR / Diversity and Inclusion" },
  { id: "1", title: "Insurance" },
  { id: "1", title: "IoT" },
  { id: "1", title: "Legal" },
  { id: "1", title: "Logistics" },
  { id: "1", title: "Manufacturing / 3D printing" },
  { id: "1", title: "Marketplaces" },
  { id: "1", title: "Materials / Chemicals" },
  { id: "1", title: "Media / Content" },
  { id: "1", title: "Parenting / Families" },
  { id: "1", title: "Professional or Technical Services" },
  { id: "1", title: "Property / Real Estate / Construction" },
  { id: "1", title: "Quantum Computing" },
  { id: "1", title: "Restaurants / Food Services" },
  { id: "1", title: "Robotics" },
  { id: "1", title: "Sales and CRM" },
  { id: "1", title: "Social Impact" },
  { id: "1", title: "Space" },
  { id: "1", title: "Transportation / Cars / Autonomous vehicles" },
  { id: "1", title: "Travel" },
  { id: "1", title: "Other Consumer" },
  { id: "1", title: "Other Enterprise" },
  { id: "1", title: "Other Tech" },
];

export const CompanySizeRanges = ["0 - 10", "11 - 50", "51 - 200", "201 - 500", "501 - 1000", "1001 - 5000", "5000+"];
