import { takeEvery, put, select, call } from "redux-saga/effects";
import { FETCH_EVENT } from "../constants";
import { FetchEventAction } from "../types";
import { TOAST_FAIL } from "../../../../../redux/toast/constants";
import { setToastMessage, fetchInProgress, cacheData } from "../../../../../redux/actions";
import { OOPS_SOMETHING_WENT_WRONG } from "../../../../../constants/error-codes";
import { fetchEvent } from "../api";

export function* fetchEventWorker(action: FetchEventAction) {
  try {
    const { id } = action.payload;
    yield put(fetchInProgress({ key: id, status: true }));
    const event = yield call(fetchEvent, { id });
    yield put(cacheData({ key: event.id, data: event }));
    yield put(fetchInProgress({ key: id, status: false }));
  } catch (error) {
    yield put(setToastMessage({ msg: OOPS_SOMETHING_WENT_WRONG, type: TOAST_FAIL }));
    console.log("Error: fetchEventWorker", { error });
  }
}

export function* watchFetchEvent() {
  yield takeEvery(FETCH_EVENT, fetchEventWorker);
}
