/**
 * @author William Alexander Livesley
 * @date   2018-06-30
 * @description Declaration of the relay environment supported by AppSync.
 * @filename relay-environment.ts
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 */

import "regenerator-runtime/runtime";
import { Environment, RecordSource, RequestNode, Store, Variables } from "relay-runtime";
import {
  RelayNetworkLayer,
  retryMiddleware,
  cacheMiddleware,
  urlMiddleware,
  loggerMiddleware,
  errorMiddleware,
  perfMiddleware,
} from "react-relay-network-modern";
import { ApiClient } from "../authentication/api-client";
import { getNewUniqueId as _getNewUniqueId } from "@radivision/common";

const md5 = require("md5");

// import { showActionModal } from "../utilities/general";
// import { getLoggedInUser } from "../utilities/user";
import { INVALID_CREDENTIALS_ID } from "../constants/general-constants";
import { storageFactory } from "../utilities/local-storage-factory";
import { LocalStorageKeys } from "../utilities/local-storage-keys";

const localStore = storageFactory(() => localStorage);

function getLoggedInUser(): string {
  const USER: string = localStore.getItem(LocalStorageKeys.KEY_LOCAL_STORAGE_USER_ID);
  let personId: string;
  if (USER !== undefined && USER !== null) {
    personId = USER;
  }
  return personId;
}
const network = new RelayNetworkLayer([
  urlMiddleware({
    url: (req) => {
      const URL_ = new URL(process.env.AWS_API_GATEWAY_ENDPOINT);
      URL_.searchParams.append("hk", generateHashKey(req));
      req.fetchOpts.url = URL_.toString();
      return Promise.resolve(URL_.toString());
    },
  }),
  cacheMiddleware({
    size: 100, // max 100 requests
    ttl: 900000, // 15 minutes
    onInit: () => {},
    allowFormData: true,
  }),
  process.env.__DEV__ ? loggerMiddleware() : null,
  process.env.__DEV__ ? errorMiddleware() : null,
  process.env.__DEV__ ? perfMiddleware() : null,
  retryMiddleware({
    fetchTimeout: 20000,
    retryDelays: [1600, 3200, 6400, 12800] /*Math.pow(2, attempt + 4) * 100*/, // or simple array [3200, 6400, 12800, 25600, 51200, 102400, 204800, 409600],
    beforeRetry: ({ forceRetry, abort, delay, attempt, lastError, req }) => {
      let abortRetry = false;

      if (isTsQuery(req) || isCredential(lastError.message)) {
        if (attempt === 3 && getLoggedInUser()) {
          abortRetry = true;
          //showActionModal();
        } else if (attempt >= 3) {
          abortRetry = true;
        }
      }

      if (abortRetry) {
        window.sessionStorage.clear(); //clear all
        import("../authentication/cognito-client").then((module) => {
          module.CognitoClient.signOut();
          setTimeout(() => {
            window.location.href = "/";
          }, 7000);
          return abort();
        });
      }
      window.forceRelayRetry = forceRetry;
    },
    statusCodes: [408, 500, 503, 504],
  }),
  (next) => async (req) => {
    req.fetchOpts.method = "POST";
    req.fetchOpts.mode = "cors";
    const SIGNED_REQUEST = await ApiClient.signNetworkRequest(req);
    req.fetchOpts.headers = SIGNED_REQUEST.headers;
    return await next(req);
  },
]);

function generateHashKey(req): string {
  const USER_ID = getLoggedInUser();
  let hashKey = md5(`${req.fetchOpts.url}${req.getBody()}${USER_ID ? USER_ID : ""}`);
  if (req.isMutation()) {
    req.fetchOpts.headers = { ...req.fetchOpts.headers, "Cache-control": "max-age=0,no-cache" };
    hashKey = _getNewUniqueId();
  }
  return hashKey;
}
/**
 * The relay environment.
 *
 * @constant
 * @type {Environment}
 */
export const ENVIRONMENT: Environment = new Environment({
  network: network,
  store: new Store(new RecordSource()),
});

/**
 * Returns a promise to fetch the response to a request containing a given GraphQL query.
 *
 * @param {RequestNode} operation The GraphQL query.
 *
 * @param {Variables} variables An arbitrary set of variables associated with the query.
 *
 * @return {Promise<{}>} A promise containing the response to the request containing a given GraphQL query.
 */
function executeGraphQuery(operation: RequestNode, variables: Variables): Promise<{}> {
  // Try to get data from cache on queries
  return ApiClient.post(operation.text, variables)
    .then((res) => {
      // Update cache on queries
      return Promise.resolve(res);
    })
    .catch((err: any) => {
      console.error("[fetchQuery] ApiClient error :", err);
      return Promise.resolve({ errors: err.errors });
    });
}

function isTsQuery(req: any): boolean {
  console.log(req);
  return req?.variables?.alias ? true : false;
}

function isUserQuery(req: any): boolean {
  return req.getQueryString().includes("userGraphqlQuery");
}

function isCredential(error: string): boolean {
  console.log("[isCredential]", error.includes(INVALID_CREDENTIALS_ID));
  return error.includes(INVALID_CREDENTIALS_ID);
}
