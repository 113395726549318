import {
  PreviewKind,
  Badge as GraphQlBadge,
  GRAPHQL_TYPE_EVENT,
  GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY,
  TopLevelMediaKind,
  DynamicList,
  FixedList,
  GRAPHQL_TYPE_PERSON,
  GRAPHQL_TYPE_ENTITY,
} from "@radivision/graphql";
import { Types } from "@radivision/common";
import { RdvCarouselProps } from "./index";
import { Show } from "../../../component-configuration/show";
import { GeneralItemDetails } from "../../../component-configuration/general-item-details";
import { GetCarouselProps } from "./types";
import { HomeGenericJson } from "../../../component-configuration/home-generic-json";
import { extractBadge, getCarouselItem } from "../../../utilities/general";
import { ImageHelper } from "../../../utilities/image-helper";
import { Episode } from "../../../component-configuration/episode";
import { CarouselItem } from "../../../component-configuration/carousel-item";
import { rotate } from "../../../utilities/rotate";
import {
  RadivisionInterviewsShowId,
  DrivenShowId,
  VisionariesShowId,
  FeaturedCarouselId,
} from "../../../constants/content-ids";

export const isOriginalShow = (id: string) =>
  [DrivenShowId, VisionariesShowId, RadivisionInterviewsShowId].includes(id);
export const isFeaturedCarousel = (id: string) => id === FeaturedCarouselId;

export const getDetailsPanelType = (show: Show, index: number): "Show" | "General" => {
  const currentItem = show.items[index];
  return currentItem.type === "OriginalContentShow" ||
    currentItem.type === GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY ||
    currentItem.type === GRAPHQL_TYPE_EVENT
    ? "Show"
    : "General";
};

export const getDetailsPanelHash = (show: Show, index: number) => {
  const showId = show?.items[index]?.sourceList ? show?.items[index].sourceList : show?.id;
  return show.title ? `${show.title.toLowerCase().replace(/[\s]+/g, "-")}/${show.items[index].id}/${showId}` : showId;
};

interface GetDetailsPanelEpisodeIndex {
  parentShow: Show;
  index: number;
  show: Show;
  hasShow: boolean;
}

export const getDetailsPanelEpisodeIndex = ({ parentShow, index, show, hasShow }: GetDetailsPanelEpisodeIndex) => {
  const episodeIndexInItemShow =
    hasShow && show.items.findIndex((e: CarouselItem) => e.id === parentShow.items[index].id);
  return hasShow ? episodeIndexInItemShow : index;
};

export const itemHasShow = (show: Show, index: number) =>
  show &&
  show.items &&
  show.items.length &&
  show.items[index] &&
  show.items[index].details &&
  (show.items[index].details as Episode).show
    ? true
    : false;

export const getDetailsPanelShow = (show: Show, index: number) => {
  const currentItem = show.items[index];
  return currentItem.type === "OriginalContentShow"
    ? (currentItem.details as Show)
    : currentItem.type === GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY || currentItem.type === GRAPHQL_TYPE_EVENT
    ? show
    : (currentItem.details as GeneralItemDetails);
};

export const getDetailsPanelProps = (parentShow: Show, index: number) => {
  const hasShow = itemHasShow(parentShow, index);
  const showData = hasShow ? (parentShow.items[index].details as Episode).show : parentShow;

  const episodeIndex: number = getDetailsPanelEpisodeIndex({
    parentShow,
    index,
    show: showData,
    hasShow,
  });
  // console.log("DetailsPanel: ", {
  //   parentShow,
  //   index,
  //   showData,
  //   hasShow,
  //   episodeIndex,
  // });

  const show = getDetailsPanelShow(showData, episodeIndex);
  const type = getDetailsPanelType(showData, episodeIndex);

  const currentItem = parentShow.items[index];
  const episode =
    currentItem.type === "OriginalContentShow"
      ? null
      : currentItem.type === GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY || currentItem.type === GRAPHQL_TYPE_EVENT
      ? episodeIndex
      : null;

  const hash =
    type === "Show"
      ? getDetailsPanelHash(show as Show, episodeIndex)
      : getDetailsPanelHash(parentShow as Show, episodeIndex);

  return { show, type, episode, hash };
};

interface GetDetailsPanelPropsFromListData {
  list: DynamicList | FixedList;
  itemId: string;
}
export const getDetailsPanelPropsFromListData = (props: GetDetailsPanelPropsFromListData) => {
  const { list, itemId } = props;
  const isPitchList = list?.items?.edges[0] && list.items.edges[0].listItem?.item?.__typename === "PitchVideoStory";
  if (isPitchList) {
    const episode = list && list.items.edges.findIndex((e) => e.listItem.item.id === itemId);
    return { show: list, episode, type: "PitchGrid" };
  }
  const json = { detailsPanel: "VideoTab", sourceId: list.id };
  const { show } = getCarouselProps({ show: list, json });
  const index = show && show.items.findIndex((e) => e.id === itemId);
  const detailsPanelProps = getDetailsPanelProps(show, index);
  return detailsPanelProps;
};

export const getCarouselProps = (props: GetCarouselProps): RdvCarouselProps => {
  const { show, json, forceNumberOfItems } = props;
  const { numberOfItems } = getRatioAndNumberOfItems({
    json,
    forceNumberOfItems,
  });
  const _items = show.items
    ? show.items.edges.map((edge: any, index: number) => {
        return getCarouselItem(
          edge,
          show,
          index,
          {
            containerWidthRatio: json && json.itemPreviewType === PreviewKind.PORTRAIT ? 0.75 : 1.4,
            numberOfItems: 4,
          },
          json && json.itemPreviewType ? json.itemPreviewType : PreviewKind.CAROUSEL,
          json && json.detailsPanel ? true : false,
        );
      })
    : [];

  const weekDay = new Date().getDay() + 1;
  const shiftBy = 3;
  const shouldRotate = json?.displayMode !== "GRID" && !json.disableRandomization;
  const items = shouldRotate ? rotate(_items, weekDay * shiftBy) : _items;

  const originalEpisodesOrder = _items.map(({ id }) => id);

  const BADGES = show.badges
    ? show.badges.map((award: GraphQlBadge) => {
        const MEDIA_ASSERT = extractBadge(
          award,
          json && json.badgePreview ? (json.badgePreview as PreviewKind) : PreviewKind.BADGE_LIGHT,
        );

        if (Types.isObject(MEDIA_ASSERT)) {
          return {
            link: award.link,
            imgUrl: ImageHelper.fetchOptimizedImageUrl({
              imageType: "MEDIA_ASSET",
              mediaAsset: {
                files: MEDIA_ASSERT.files,
                type: TopLevelMediaKind.IMAGE,
              },
              desiredDimensions: {
                containerWidthRatio: 3 / 12,
                numberOfItems: 4,
              },
              revision: show.revision,
            }),
          };
        }
      })
    : undefined;

  const THE_SHOW: Show = {
    items,
    originalEpisodesOrder,
    id: show.id,
    title: show.name ? show.name : show.title,
    subTitle: show.subTitle,
    description: show.description,
    revision: show.revision,
    landingPageUrl: show.landingPageUrl,
    badges: BADGES,
    logo: ImageHelper.fetchOptimizedImageUrl({
      imageType: "PREVIEW",
      preview: {
        content: show.previews,
        previewKind: PreviewKind.LOGO,
        topLevelMedia: TopLevelMediaKind.IMAGE,
      },
      desiredDimensions: {
        containerWidthRatio: 1,
        numberOfItems: 2.2,
      },
      revision: undefined,
    }),
    backgroundImage: ImageHelper.fetchOptimizedImageUrl({
      imageType: "PREVIEW",
      preview: {
        content: show.previews,
        previewKind: PreviewKind.BACKGROUND,
        topLevelMedia: TopLevelMediaKind.IMAGE,
      },
      desiredDimensions: {
        containerWidthRatio: 1,
        numberOfItems: 1,
      },
      revision: show.revision,
    }),
  };

  const displayMode: "GridView" | "Carousel" =
    json && json.displayMode && json.displayMode === "GRID" ? "GridView" : "Carousel";
  const hasDetails = json && json.detailsPanel !== null && json.detailsPanel !== undefined && json.detailsPanel !== "";

  const listDetailsView = json && json.listDetailsView ? json.listDetailsView : null;

  const hideTitle = json && json.hideListTitle;
  const displayListDescriptionFlag = json && (json.displayListDescriptionFlag || json.displaySubtitleFlag);
  const navButtonText = json && json.navButtonText ? json.navButtonText : null;
  const navButtonUrl = json && json.navButtonUrl ? json.navButtonUrl : null;
  const itemPreviewType = json && json.itemPreviewType ? json.itemPreviewType : PreviewKind.CAROUSEL;
  const campaignPreview =
    json && json.campaignPreview
      ? ImageHelper.fetchOptimizedImageUrl({
          imageType: "PREVIEW",
          preview: {
            content: show.previews,
            previewKind: PreviewKind.SQUARE,
            topLevelMedia: TopLevelMediaKind.IMAGE,
          },
          desiredDimensions: {
            containerWidthRatio: 0.6,
            numberOfItems: 6,
          },
          revision: undefined,
        })
      : null;
  const moreLikeThis = json?.moreLikeThis ? json?.moreLikeThis : "qQXPd4GmRlGeKbNxpioVpA";
  const pageInfo = show && show.items ? show.items.pageInfo : undefined;
  const descriptionHyperLinkText = json && json.descriptionHyperLink ? json.descriptionHyperLink.text : undefined;
  const descriptionHyperLink = json && json.descriptionHyperLink ? json.descriptionHyperLink.link : undefined;
  const hideItemTitle = json && json.hideItemTitle;
  const showItemTypeIcon = json && json.showItemTypeIcon;

  const carouselProps = {
    displayMode,
    hasDetails,
    listDetailsView,
    hideTitle,
    displayListDescriptionFlag,
    navButtonUrl,
    navButtonText,
    itemPreviewType,
    campaignPreview,
    moreLikeThis,
    pageInfo,
    descriptionHyperLinkText,
    descriptionHyperLink,
    hideItemTitle,
    numberOfItems,
    show: THE_SHOW,
    showItemTypeIcon,
    list: show,
  };
  return carouselProps;
};

export const getRatioAndNumberOfItems = ({
  json,
  forceNumberOfItems,
}: {
  json: HomeGenericJson;
  forceNumberOfItems: number | null;
}) => {
  let ratio = 16 / 9;
  let numberOfItems = json && json.listDetailsView ? 4 : 5;

  if (json) {
    switch (json.itemPreviewType) {
      case PreviewKind.CIRCLE:
      case PreviewKind.SQUARE:
        ratio = 4 / 4;
        numberOfItems = 7;
        break;
      case PreviewKind.CAROUSEL:
        ratio = 16 / 9;
        numberOfItems = json.listDetailsView ? 4 : forceNumberOfItems ? forceNumberOfItems : 5;
        break;
      case PreviewKind.PORTRAIT:
        ratio = 320 / 457;
        numberOfItems = 6;
        break;
      default:
        ratio = 16 / 9;
        numberOfItems = json.listDetailsView ? 4 : 5;
    }
  } else {
    ratio = 16 / 9;
    numberOfItems = 4;
  }
  return { ratio, numberOfItems };
};

export const itemOpenGraphTags = (item) => {
  const image = item.previewImageUrl
    ? item.previewImageUrl.requestedResolutionUrl !== undefined && item.previewImageUrl.requestedResolutionUrl !== null
      ? item.previewImageUrl.requestedResolutionUrl
      : item.previewImageUrl.screenResolutionUrl
    : undefined;

  const title = item.title;

  return {
    image,
    title,
  };
};
