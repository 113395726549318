import { SVG } from "../component-configuration/svgs";
import { SocialMediaPlatformKind } from "@radivision/graphql/lib/ts/graphql/social-media-platform";

export const socialPlatforms = {
  TWITTER: {
    domain: "https://www.twitter.com/",
    name: "Twitter",
    icon: SVG.twitter,
    icon2: SVG.twitter,
    kind: SocialMediaPlatformKind.TWITTER,
    regexp: [/twitter.com/i],
  },
  FACEBOOK: {
    domain: "https://www.facebook.com/",
    name: "Facebook",
    icon: SVG.facebookCircle,
    icon2: SVG.facebook,
    kind: SocialMediaPlatformKind.FACEBOOK,
    regexp: [/facebook.com|fb.com|fb.me/i],
  },
  YOU_TUBE: {
    domain: "https://www.youtube.com/",
    name: "Youtube",
    icon: SVG.youtube,
    icon2: SVG.youtube,
    kind: "YOU_TUBE",
    regexp: [/youtu/i],
  },
  LINKED_IN: {
    domain: "https://www.linkedin.com/",
    name: "LinkedIn",
    icon: SVG.linkedinSquare,
    icon2: SVG.linkedinSquare,
    kind: SocialMediaPlatformKind.LINKED_IN,
    regexp: [/linkedin.com\/(school|in|company)/i],
  },
  INSTAGRAM: {
    domain: "https://www.instagram.com/",
    name: "Instagram",
    icon: SVG.instagram,
    icon2: SVG.instagram,
    kind: SocialMediaPlatformKind.INSTAGRAM,
    regexp: [/instagram.com/i],
  },
  WHATS_APP: {
    domain: "https://www.whatsapp.com/",
    name: "WhatsApp",
    icon: SVG.whatsapp,
    icon2: SVG.whatsapp,
    kind: SocialMediaPlatformKind.WHATS_APP,
    regexp: [/whatsapp/i],
  },
  ANGEL_LIST: {
    domain: "https://www.angel.co/",
    name: "Angel List",
    icon: SVG.angellist,
    icon2: SVG.angellist,
    kind: SocialMediaPlatformKind.ANGEL_LIST,
    regexp: [/angel/i],
  },
  PINTEREST: {
    domain: "https://www.pinterest.com/",
    name: "Pinterest",
    icon: SVG.pinterest,
    icon2: SVG.pinterest,
    kind: SocialMediaPlatformKind.PINTEREST,
    regexp: [/pinterest.com/i],
  },
  REDDIT: {
    domain: "https://www.reddit.com/",
    name: "Reddit",
    icon: SVG.reddit,
    icon2: SVG.reddit,
    kind: SocialMediaPlatformKind.REDDIT,
    regexp: [/reddit.com/i],
  },
  MEDIUM: {
    domain: "https://www.medium.com/",
    name: "Medium",
    icon: SVG.medium,
    icon2: SVG.medium,
    kind: SocialMediaPlatformKind.MEDIUM,
    regexp: [/medium.com/i],
  },
  SNAPCHAT: {
    domain: "https://www.snapchat.com/",
    name: "Snapchat",
    icon: SVG.snapchat,
    icon2: SVG.snapchat,
    kind: SocialMediaPlatformKind.SNAPCHAT,
    regexp: [/snapchat/i],
  },
  TUMBLR: {
    domain: "https://www.tumblr.com/",
    name: "Tumblr",
    icon: SVG.tumblr,
    icon2: SVG.tumblr,
    kind: SocialMediaPlatformKind.TUMBLR,
    regexp: [/tumblr.com/i],
  },
  WEIBO: {
    domain: "https://www.weibo.com/",
    name: "Weibo",
    icon: SVG.weibo,
    icon2: SVG.weibo,
    kind: SocialMediaPlatformKind.WEIBO,
    regexp: [/weibo/i],
  },
  OTHER: {
    domain: "Please enter other link ...",
    name: "Other",
    icon: SVG.globe,
    icon2: SVG.globe,
    kind: SocialMediaPlatformKind.OTHER,
    regexp: [/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/],
  },
};
