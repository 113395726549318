/**
 * @author Ibrahim Ahmed
 * @copyright Copyright 2021 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2021-03-01
 * @description
 * @filename covid-preneurs.tsx
 */

import { TopLevelMediaKind } from "@radivision/graphql/lib/ts/graphql/top-level-media-type";
import * as React from "react";
import ProgressiveImage from "react-progressive-image";
import { OptimizedImageUrls } from "../../component-configuration/optimized-image";
import { ImageHelper } from "../../utilities/image-helper";
import { EditorialPage } from "./types";

export const Covidpreneurs = (props) => {
  const PAGE: EditorialPage = props.json;

  if (!PAGE) {
    return <div>Page {PAGE.title} not found</div>;
  }

  return (
    <div className="main-container">
      <Container page={PAGE}>
        <CenterLogo page={PAGE} />
        <Shadow />
      </Container>
    </div>
  );
};

const Container = ({ children, page }) => {
  const BACKGROUND: OptimizedImageUrls = page?.background
    ? ImageHelper.fetchOptimizedImageUrl({
        imageType: "MEDIA_ASSET",
        mediaAsset: {
          files: page.background,
          type: TopLevelMediaKind.IMAGE,
        },
        desiredDimensions: {
          containerWidthRatio: 1,
          numberOfItems: 1,
        },
        revision: undefined,
      })?.requestedResolutionUrl
    : require("../../../static/covid-preneurs-bg.jpg");

  return (
    <div className="editorials-page">
      <div
        className="backdrop"
        style={{
          backgroundImage: `url(${BACKGROUND})`,
        }}
      />
      <div className="tint" />

      {children}
    </div>
  );
};

const Caption = ({ captionsList }) => (
  <div className="caption">
    {captionsList
      ? captionsList.map((caption, index) => {
          return <p key={`caption-${index}`}>{caption}</p>;
        })
      : null}
  </div>
);

const CenterLogo = ({ page }: { page: EditorialPage }) => {
  const LOGO: OptimizedImageUrls = page?.logoFg
    ? ImageHelper.fetchOptimizedImageUrl({
        imageType: "MEDIA_ASSET",
        mediaAsset: {
          files: page.logoFg,
          type: TopLevelMediaKind.IMAGE,
        },
        desiredDimensions: {
          containerWidthRatio: 1,
          numberOfItems: 2,
        },
        revision: undefined,
      })
    : undefined;

  return (
    <div className="center-logo">
      {LOGO && (
        <ProgressiveImage src={LOGO?.requestedResolutionUrl} placeholder={LOGO?.placeHolderUrl}>
          {(src: any, LOADING: any, srcSetData: any) => (
            <img
              loading="lazy"
              alt={`${page && page.title ? `${page.title}` : "banner-logo"}`}
              src={src}
              onError={(e) => LOGO?.placeHolderUrl}
            />
          )}
        </ProgressiveImage>
      )}
      <Caption captionsList={page.captions} />
    </div>
  );
};
const Shadow = () => <div className="shadow" />;
