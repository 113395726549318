.horizontal-scroller-container {
  position: relative;
  .horizontal-scroller {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    overflow-x: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .tab-scroll-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    min-width: 30px;
    min-height: 35px;
    z-index: 10;
    svg {
      fill: #aaaaaa;
      min-width: 30px;
      min-height: 30px;
    }
  }
  .tab-next {
    right: 0px;
  }
  .tab-prev {
    left: 0px;
  }
}
