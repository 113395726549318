export const extractYoutubeId = (url: string | null) => {
  if (typeof url !== "string") {
    return null;
  }
  // if (url.includes("youtube.com")) {
  //   return url.substring(url.indexOf("v=") + 2).split("&")[0];
  // } else if (url.indexOf("youtu.be") !== -1) {
  //   return url.substring(url.indexOf("youtu.be") + 9);
  // }
  const regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  const id = url.match(regex);
  return id ? id[1] : null;
};

export const convertYoutubeIdToUrl = (id: string) => {
  let url = null;
  const YOUTUBE_DOMIAN = "https://www.youtube.com/watch?v=";
  if (id) {
    url = `${YOUTUBE_DOMIAN}${id}`;
  }
  return url;
};
