import * as React from "react";
import { ImageHelper } from "../../../utilities/image-helper";
import { PreviewKind, TopLevelMediaKind, GRAPHQL_TYPE_ENTITY, Entity } from "@radivision/graphql";
import { getProfileInfo } from "../../../utilities/get-profile-info";
import { Router } from "../../../utilities/router";

interface ComponentProps {
  setCompany: (company: Entity) => void;
  selectedCompany: Entity | null;
  event: any;
}

export type Props = ComponentProps;

export const Companies = (props: Props) => {
  const { event, setCompany, selectedCompany } = props;
  const companies = event?.entities;
  const broughtToYou = event?.peopleEntities?.map((i) => i.entity);
  return (
    <div className="companies-sponsors">
      <div className="companies-list">
        <h4>Companies</h4>
        <div className="companies">
          {companies?.map((company: Entity) => {
            const previewUrl = ImageHelper.fetchOptimizedImageUrl({
              imageType: "PREVIEW",
              preview: {
                content: company.previews,
                previewKind: PreviewKind.LOGO,
                topLevelMedia: TopLevelMediaKind.IMAGE,
                isDefaultPreview: true,
                type: GRAPHQL_TYPE_ENTITY,
              },
              desiredDimensions: {
                containerWidthRatio: 3 / 12,
                numberOfItems: 4,
              },
              revision: company.revision,
            }).requestedResolutionUrl;
            const { profileUrl } = getProfileInfo(company);
            return (
              <div className={"company"} key={`participating-company-${company.id}`}>
                <a
                  href={profileUrl}
                  onClick={(e) => {
                    e.preventDefault();
                    setCompany(company);
                  }}
                  style={{ backgroundImage: `url('${previewUrl}')` }}
                  className={`${selectedCompany === company ? "selected" : ""}`}
                ></a>
              </div>
            );
          })}
        </div>
      </div>
      <div className="sponsors-list">
        <h4>Brought to you by</h4>
        <div className="companies">
          {broughtToYou?.map((company, idx) => {
            if (idx > 2) return null;
            const previewUrl = ImageHelper.fetchOptimizedImageUrl({
              imageType: "PREVIEW",
              preview: {
                content: company.previews,
                previewKind: PreviewKind.LOGO,
                topLevelMedia: TopLevelMediaKind.IMAGE,
                isDefaultPreview: true,
                type: GRAPHQL_TYPE_ENTITY,
              },
              desiredDimensions: {
                containerWidthRatio: 3 / 12,
                numberOfItems: 4,
              },
              revision: company.revision,
            }).requestedResolutionUrl;
            const { profileUrl } = getProfileInfo(company);
            return (
              <div className={"company"} key={`brought-to-you-company-${company.id}`}>
                <a
                  href={profileUrl}
                  onClick={(e) => {
                    e.preventDefault();
                    Router.navigateTo(profileUrl);
                  }}
                  style={{ backgroundImage: `url('${previewUrl}')` }}
                ></a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
