import { call, put, takeEvery, select } from "redux-saga/effects";
import { removeFromPerson } from "../api";
import { RemoveFromPersonInput, Entity } from "@radivision/graphql";
import { getClientMutationId } from "../../../../../utilities/general";
import { setToastMessage, fetchInProgress, updateUser, cacheData } from "../../../../../redux/actions";
import { TOAST_FAIL, TOAST_ALERT } from "../../../../../redux/toast/constants";
import { DeleteCompanyAction } from "../types";
import { DELETE_COMPANY } from "../constants";
import { AppState } from "../../../../../redux";

function* deleteCompanyWorker(action: DeleteCompanyAction) {
  try {
    const { entityId, collection } = action.payload;

    const collections = ["entities", "experience"];
    if (!collections.includes(collection)) {
      yield put(setToastMessage({ msg: "Error: cannot delete company!", type: TOAST_FAIL }));
      console.log("Error: deleteCompanyWorker collection is not defined properly. collection: ", collection);
      return false;
    }

    yield put(fetchInProgress({ key: `delete-company-${entityId}`, status: true }));
    const user = yield select((state: AppState) => state.account.user);
    const personInput: RemoveFromPersonInput = {
      id: user.id,
      clientMutationId: getClientMutationId(),
      [collection]: [entityId],
      revision: user.revision,
    };
    const updatedUser = yield call(removeFromPerson, personInput);
    yield put(updateUser({ user: updatedUser }));
    yield put(cacheData({ key: updatedUser.id, data: updatedUser }));
    yield put(setToastMessage({ msg: "Company has been removed!", type: TOAST_ALERT }));

    yield put(fetchInProgress({ key: `delete-company-${entityId}`, status: false }));
  } catch (e) {
    console.log("Error: deleteCompanyWorker ", { error: e });
  }
}

export function* watchDeleteCompany() {
  yield takeEvery(DELETE_COMPANY, deleteCompanyWorker);
}
