import React from "react";
import { connect } from "react-redux";
import { CreateCompanyModal } from "./create-company";
import { CommunityGuidelines } from "./community-guidlines";

import { Person } from "@radivision/graphql";
import isEmpty from "lodash.isempty";
import { AppState } from "../../redux";

interface State {
  modals: React.ReactNode[];
}

interface MapStateProps {
  user: Person;
}

type Props = MapStateProps;

class Component extends React.Component<Props, State> {
  render(): React.ReactElement<any> {
    const { user } = this.props;
    if (isEmpty(user)) {
      return null;
    }
    return (
      <>
        <CreateCompanyModal />
        <CommunityGuidelines />
      </>
    );
  }
}

const mapState = (state: AppState) => ({
  user: state.account.user,
});
export const UserModals = connect(mapState)(Component);
