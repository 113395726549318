import * as React from "react";
import { connect } from "react-redux";
import { Loader } from "../../page/loader";
import { convertYoutubeIdToUrl, extractYoutubeId } from "../../../utilities/extract-youtube-id";
import { Show } from "../../../component-configuration/show";
import { GRAPHQL_TYPE_PERSON, GRAPHQL_TYPE_ENTITY } from "@radivision/graphql";
import { CarouselItem } from "../../../component-configuration/carousel-item";
import { SVG } from "../../../component-configuration/svgs";
import PlayFullScreenVideoButton from "../../full-screen-video/play-button";
import AddToSavedButton from "../../page-framework/add-to-saved-button";
import { OpenDetailsPanelPayload, OpenDetailsPanelAction } from "../../../redux/details-panel/types";
import isEmpty from "lodash.isempty";
import * as Actions from "../../../redux/actions";
import { formatTime } from "../../../utilities/format-time";

const VideoPanel = React.lazy(() => import("../../page-framework/video-panel"));

interface ComponentProps {
  item: CarouselItem;
  itemIndex: number;
  itemWidth: number;
  show: Show;
  hasDetails: boolean;
  moreLikeThis: string;
}

interface MapDispatchProps {
  openDetailsPanel: (payload: OpenDetailsPanelPayload) => OpenDetailsPanelAction;
}

export type Props = MapDispatchProps & ComponentProps;

export const Component = (props: Props) => {
  const { item, itemWidth, openDetailsPanel, itemIndex, hasDetails, show, moreLikeThis } = props;

  const [videoReady, setVideoReady] = React.useState(false);
  const [isHover, setIsHover] = React.useState(false);
  const isPodcast = item && item.type === "PodcastStory";
  const isOriginalContent = item && item.type === "OriginalContentStory";
  const isCarousel = isPodcast || isOriginalContent;

  const videoUrl = item.youTubeVideo && item.youTubeVideo.includes("youtu") ? item.youTubeVideo : item.link;
  let youtubeId = extractYoutubeId(videoUrl);
  youtubeId = isEmpty(youtubeId) && item?.youTubeVideo ? String(item?.youTubeVideo).trim() : youtubeId;

  const backgroundImage = `url('${item?.previewImageUrl?.requestedResolutionUrl}')`;

  const width = isCarousel ? itemWidth : itemWidth / 2;
  const height = itemWidth ? itemWidth * (9 / 21) : 90;

  const itemIsPersonOrEntity = item.type === GRAPHQL_TYPE_ENTITY || item.type === GRAPHQL_TYPE_PERSON;
  const itemHasDetails = hasDetails && item.details && (!isEmpty(item.show) || !isEmpty(item.youTubeVideo));
  const hasYoutubeVideo = !isEmpty(youtubeId);
  const itemHasLink = !isEmpty(item.link);
  const itemHasExternalLink = !itemHasDetails && itemHasLink;

  const openDetailsPanelOnClick = React.useCallback(
    (item: CarouselItem) => {
      openDetailsPanel({ list: show, item });
    },
    [show, openDetailsPanel],
  );

  const itemOnClick = React.useCallback(() => {
    if (true || itemHasDetails) {
      openDetailsPanelOnClick(item);
      return;
    }
    if (itemIsPersonOrEntity) {
      window.location.href = item.landingPageUrl;
    }
    if (itemHasExternalLink) {
      window.open(item.link, "_blank");
    }
  }, [itemHasDetails, openDetailsPanelOnClick, item, itemHasExternalLink, itemIsPersonOrEntity]);

  const shouldPlayYoutubePreview = hasYoutubeVideo && isHover;

  const PopOver = React.useCallback(() => {
    return (
      <PopOverContainer isHover={isHover}>
        <PreviewContainer style={{ width: "100%", height }} onClick={itemOnClick}>
          <div
            className="item-preview"
            style={{
              backgroundImage,
              opacity: shouldPlayYoutubePreview ? 0 : 1,
            }}
          />
          <Video onClick={itemOnClick} style={{ backgroundImage }}>
            {shouldPlayYoutubePreview && (
              <React.Suspense fallback={<Loader isActive={true}></Loader>}>
                <VideoPanel
                  youTube={youtubeId}
                  onReady={() => setVideoReady(true)}
                  onVideoEnded={() => setVideoReady(false)}
                  mute={true}
                  showControls={false}
                  json={{}}
                  videoPosition={"FIT"}
                  url={convertYoutubeIdToUrl(youtubeId)}

                />
              </React.Suspense>
            )}
          </Video>
        </PreviewContainer>
        <div className="item-card-actions">
          <PlayFullScreenVideoButton item={item} />
          <AddToSavedButton item={item} />

          {false && (itemIsPersonOrEntity || itemHasExternalLink) ? (
            <span onClick={itemOnClick}> {SVG.externalLinkAlt} </span>
          ) : null}
          <span onClick={itemOnClick}> {SVG.chevronDown} </span>
        </div>
        <CardText style={{}}>
          <h3>{item.title}</h3>
        </CardText>
      </PopOverContainer>
    );
  }, [
    backgroundImage,
    height,
    item,
    itemIsPersonOrEntity,
    itemOnClick,
    shouldPlayYoutubePreview,
    youtubeId,
    itemHasExternalLink,
    isHover,
  ]);

  const duration = formatTime(item?.timeLabel || item?.timeRequiredInSeconds);
  return (
    <div
      style={{ width }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={`item-card-container ${isHover ? "hasHover" : ""}`}
    >
      <Container className={item.type}>
        <PopOver />
        <PreviewContainer style={{ width: "100%", height }} onClick={itemOnClick}>
          <div className="item-preview" onClick={itemOnClick} style={{ backgroundImage }}>
          {duration && (
            <div className="overlay-item-meta">
              <span className="duration">{duration}</span>
            </div>
          )}
          </div >
        </PreviewContainer>
        <CardText style={{}}>
          <h3>{item.title}</h3>
        </CardText>
      </Container>
    </div>
  );
};

export const CardText = ({ children, style }) => {
  return (
    <div className={`item-card-text`} style={style}>
      {children}
    </div>
  );
};

export const PopOverContainer = ({ children, isHover }) => {
  return (
    <div className="popover" style={{ pointerEvents: isHover ? "auto" : "none" }}>
      {children}
    </div>
  );
};
export const PreviewContainer = ({ children, style, onClick }) => {
  return (
    <div className="item-preview-container" style={style} onClick={onClick}>
      {children}
    </div>
  );
};

export const CardTitle = ({ children, style }) => {
  return (
    <div className="item-card-title" style={style}>
      {children}
    </div>
  );
};
export const Buttons = ({ children }) => {
  return <div className="item-card-actions">{children}</div>;
};

export const Container = ({ children, className }) => {
  return <div className={`item-card ${className}`}>{children}</div>;
};

export const Video = ({ children, ...props }) => {
  return (
    <div className="item-video" {...props}>
      {children}
    </div>
  );
};

const mapState = () => ({});
const mapDispatch = {
  openDetailsPanel: Actions.openDetailsPanel,
};

export const Card = connect(mapState, mapDispatch)(Component);
