import { takeEvery, call, take, put, select, fork, all } from "redux-saga/effects";
import { channel } from "redux-saga";
import * as Actions from "./actions";
import * as Api from "./api";
import {
  FetchHtmlPageAction,
  FetchListAction,
  FetchOriginalShowsAction,
  FetchStoryItemAction,
  FetchEntityAction,
  FetchListCollectionAction,
  FetchListItemAction,
  FetchMediaAssetsListAction,
  FetchPersonAction,
  FetchListLoadMoreAction,
} from "./types";
import { AppState } from "..";
import isEmpty from "lodash.isempty";
import {
  FETCH_HTML_PAGE,
  FETCH_LIST,
  TTL,
  PREFETCH_HTML_PAGES,
  FETCH_HERO,
  FETCH_ORIGINAL_SHOWS,
  FETCH_STORY_ITEM,
  FETCH_ENTITY,
  FETCH_LIST_COLLECTION,
  FETCH_LIST_ITEM,
  FETCH_MEDIA_ASSETS_LIST,
  FETCH_PERSON,
  FETCH_LIST_LOAD_MORE,
} from "./constants";
import { TOAST_FAIL } from "../toast/constants";
import { setToastMessage } from "../toast/actions";
import produce from "immer";

export const selectInProgress = (key: string) => (state: AppState) => state.cache.inProgress[key];

export const selectUpdatedAt = (key: string) => (state: AppState) => state.cache.updatedAt[key];

export function* fetchHtmlPageWorker(action: FetchHtmlPageAction) {
  const { cacheKey, variables } = action.payload;
  const { alias } = variables;
  // if (alias === "partners" || alias === "getting-started") return null;
  console.log("debug: fetchHtmlPageWorker", { alias });
  const key = cacheKey;
  const inProgress = yield select(selectInProgress(key));
  // const updatedAt = yield select(selectUpdatedAt(key));
  // const currentTime = new Date().getTime();
  // const timeSinceLastFetch = currentTime - updatedAt;
  // const isFresh = updatedAt ? timeSinceLastFetch < TTL : null;
  if (inProgress) {
    return;
  }
  try {
    yield put(Actions.setLoadingStatus({ status: true }));
    yield put(Actions.fetchInProgress({ key, status: true }));
    const data = yield call(Api.fetchHtmlPage, action.payload);
    yield put(Actions.cacheData({ key, data }));
    const { callback } = action.payload;
    yield put(Actions.fetchInProgress({ key, status: false }));
    yield put(Actions.setLoadingStatus({ status: false }));
    if (typeof callback === "function") {
      callback();
    }
  } catch (e) {
    yield put(setToastMessage({ msg: "Page Not Found", type: TOAST_FAIL }));
    console.log("Debug: Error Fecthing Page Query", { key, error: e });
    if (
      e?.res?.errors &&
      e?.res?.errors[0] &&
      e?.res?.errors[0].message === "Cannot read property 'panel' of undefined"
    ) {
      yield put(setToastMessage({ msg: "Page Not Found", type: TOAST_FAIL, autoHide: false }));
      // } else {
      //   yield put(setToastMessage({ msg: "Error: Fail to laod the page", type: TOAST_FAIL }));
    }
  } finally {
    yield put(Actions.fetchInProgress({ key, status: false }));
  }
}

function* fetchListWorker(action: FetchListAction) {
  const { listId } = action.payload.variables;
  const key = listId;
  const inProgress = yield select(selectInProgress(key));
  const updatedAt = yield select(selectUpdatedAt(key));
  const currentTime = new Date().getTime();
  const timeSinceLastFetch = currentTime - updatedAt;
  const isFresh = updatedAt ? timeSinceLastFetch < TTL : null;
  if (isFresh || isEmpty(key) || inProgress) {
    return;
  }
  try {
    yield put(Actions.fetchInProgress({ key, status: true }));
    const data = yield call(Api.fetchList, action.payload);
    yield put(Actions.cacheData({ key, data }));
  } catch (e) {
    console.log("Debug: CarouselPanel Error Fecthing Query", { key, error: e });
  } finally {
    yield put(Actions.fetchInProgress({ key, status: false }));
  }
}

function* fetchListLoadMoreWorker(action: FetchListLoadMoreAction) {
  const listId = action.payload.listId;
  const key = listId;
  const inProgress = yield select(selectInProgress(key));
  const list = yield select((state) => state.cache.store[key]);
  try {
    yield put(Actions.fetchInProgress({ key, status: true }));
    const edges = list?.list?.list?.items?.edges;
    const after = edges ? edges[edges.length - 1].cursor : null;
    console.log("debug: fetchListLoadMoreWorker", { list, after, edges});
    const data = yield call(Api.fetchListLoadMore, listId, after);
    console.log("debug: call fetch LoadMoew", { data })
    const updatedList = produce(data, (draft: any) => {
      draft.list.list.items.edges = [...edges, ...data.list.list.items.edges];
    });
    console.log("debug: updated fetch more", { updatedList });
    yield put(Actions.cacheData({ key, data: updatedList }));
  } catch (e) {
    console.log("Debug: FetchListLoadMore Worder Error Fecthing Query", { key, error: e });
  } finally {
    yield put(Actions.fetchInProgress({ key, status: false }));
  }
}

export function* fetchListCollectionWorker(action: FetchListCollectionAction) {
  const { listCollectionId } = action.payload.variables;
  const key = listCollectionId;
  const inProgress = yield select(selectInProgress(key));
  // const updatedAt = yield select(selectUpdatedAt(key));
  // const currentTime = new Date().getTime();
  // const timeSinceLastFetch = currentTime - updatedAt;
  // const isFresh = updatedAt ? timeSinceLastFetch < TTL : null;
  // if (isFresh || isEmpty(key) || inProgress) {
  //   return;
  // }
  try {
    yield put(Actions.fetchInProgress({ key, status: true }));
    const data = yield call(Api.fetchListCollection, action.payload);
    yield put(Actions.cacheData({ key, data }));
    return data;
  } catch (e) {
    console.log("Debug: ListCollection Error Fecthing Query", { key, error: e });
  } finally {
    yield put(Actions.fetchInProgress({ key, status: false }));
  }
}

function* fetchMediaAssetsListWorker(action: FetchMediaAssetsListAction) {
  try {
    const key = action.payload.id;
    yield put(Actions.fetchInProgress({ key, status: true }));
    const data = yield call(Api.fetchMediaAssetsList, action.payload.id);
    yield put(Actions.cacheData({ key, data }));
    yield put(Actions.fetchInProgress({ key, status: false }));
  } catch (e) {
    console.log("Debug: fetchMediaAssetsListWorker ", { error: e });
  }
}

export function* fetchListItemWorker(action: FetchListItemAction) {
  const { itemId, type } = action.payload;
  const key = itemId;
  const inProgress = yield select(selectInProgress(key));
  // const updatedAt = yield select(selectUpdatedAt(key));
  // const currentTime = new Date().getTime();
  // const timeSinceLastFetch = currentTime - updatedAt;
  // const isFresh = updatedAt ? timeSinceLastFetch < TTL : null;
  // if (isFresh || isEmpty(key) || inProgress) {
  //   return;
  // }
  try {
    yield put(Actions.fetchInProgress({ key, status: true }));
    const data = yield call(Api.fetchListItem, action.payload);
    yield put(Actions.cacheData({ key, data }));
    return data;
  } catch (e) {
    console.log("Debug: ListItem Error Fecthing Query", { key, error: e });
  } finally {
    yield put(Actions.fetchInProgress({ key, status: false }));
  }
}

function* fetchOriginalShowsWorker(action: FetchOriginalShowsAction) {
  const { listCollectionId } = action.payload.variables;
  const key = listCollectionId;
  const inProgress = yield select(selectInProgress(key));
  const updatedAt = yield select(selectUpdatedAt(key));
  const currentTime = new Date().getTime();
  const timeSinceLastFetch = currentTime - updatedAt;
  const isFresh = updatedAt ? timeSinceLastFetch < TTL : null;
  if (isFresh || isEmpty(key) || inProgress) {
    return;
  }
  try {
    yield put(Actions.fetchInProgress({ key, status: true }));
    const data = yield call(Api.fetchOriginalShows, action.payload);
    yield put(Actions.cacheData({ key, data }));
  } catch (e) {
    console.log("Debug: OriginalShows Error Fecthing Query", { key, error: e });
  } finally {
    yield put(Actions.fetchInProgress({ key, status: false }));
  }
}

function* watchFetchHtmlPage() {
  yield takeEvery(FETCH_HTML_PAGE, fetchHtmlPageWorker);
}
function* watchFetchOriginalShows() {
  yield takeEvery(FETCH_ORIGINAL_SHOWS, fetchOriginalShowsWorker);
}
function* watchFetchListCollection() {
  yield takeEvery(FETCH_LIST_COLLECTION, fetchListCollectionWorker);
}
function* watchFetchListItem() {
  yield takeEvery(FETCH_LIST_ITEM, fetchListItemWorker);
}
function* watchFetchMediaAssetsList() {
  yield takeEvery(FETCH_MEDIA_ASSETS_LIST, fetchMediaAssetsListWorker);
}
function* watchFetchList() {
  const queueChannel = yield channel();

  yield fork(fetchListChannel, queueChannel);
  yield fork(fetchListChannel, queueChannel);
  yield fork(fetchListChannel, queueChannel);
  yield fork(fetchListChannel, queueChannel);
  yield fork(fetchListChannel, queueChannel);

  yield takeEvery(FETCH_LIST, function*(action) {
    yield put(queueChannel, action);
  });
}

function* fetchListChannel(chan: any) {
  while (true) {
    const action = yield take(chan);
    yield call(fetchListWorker, action);
  }
}

function* prefetchWorker() {
  const variables = (alias: string) => ({
    userIdentityId: null,
    queryParameters: [],
    alias,
  });
  // yield delay(2000);
  // yield put(Actions.fetchHtmlPage({ variables: variables("home") }));
  // yield delay(2000);
  // yield put(Actions.fetchHtmlPage({ variables: variables("shows") }));
  // yield delay(2000);
  // yield put(Actions.fetchHtmlPage({ variables: variables("ecosystem") }));
  // yield delay(2000);
  // yield put(Actions.fetchHtmlPage({ variables: variables("resources") }));
  // yield delay(1000);
  // yield put(Actions.fetchHtmlPage({ variables: variables("editorials") }));
  // yield delay(1000);
  // yield put(Actions.fetchHtmlPage({ variables: variables("covidpreneurs") }));
  // yield delay(1000);
  // yield put(Actions.fetchHtmlPage({variables: { alias: "about-us"}}));
  // yield delay(2000);
  // yield put(Actions.fetchHtmlPage({variables: { alias: "support"}}));
}

export function* fetchStoryItemWorker(action: FetchStoryItemAction) {
  const key = action.payload.id;
  try {
    const inProgress = yield select(selectInProgress(key));
    const updatedAt = yield select(selectUpdatedAt(key));
    const currentTime = new Date().getTime();
    const timeSinceLastFetch = currentTime - updatedAt;
    const isFresh = updatedAt ? timeSinceLastFetch < TTL : null;
    if (isFresh || isEmpty(key) || inProgress) {
      return;
    }
    yield put(Actions.fetchInProgress({ key, status: true }));
    const data = yield call(Api.fetchStoryItem, action.payload.id);
    yield put(Actions.cacheData({ key, data }));
    yield put(Actions.fetchInProgress({ key, status: false }));
  } catch (e) {
    console.log("Error: fetchStoryItemWorker", { e });
    yield put(Actions.fetchInProgress({ key, status: false }));
  }
}

export function* fetchEntityWorker(action: FetchEntityAction) {
  const key = action.payload.id;
  try {
    yield put(Actions.fetchInProgress({ key, status: true }));
    const data = yield call(Api.fetchEntity, action.payload.id);
    yield put(Actions.cacheData({ key, data }));
    yield put(Actions.fetchInProgress({ key, status: false }));
    return data;
  } catch (error) {
    console.log("Error: fetchEntityWorker", { error });
    yield put(Actions.fetchError({ key, error }));
    yield put(Actions.fetchInProgress({ key, status: false }));
  }
}
export function* fetchPersonWorker(action: FetchPersonAction) {
  const key = action.payload.id;
  try {
    yield put(Actions.fetchInProgress({ key, status: true }));
    const data = yield call(Api.fetchPerson, action.payload.id);
    yield put(Actions.cacheData({ key, data }));
    yield put(Actions.fetchInProgress({ key, status: false }));
    return data;
  } catch (error) {
    console.log("Error: fetchPersonWorker", { error });
    yield put(Actions.fetchInProgress({ key, status: false }));
  }
}

export function* watchFetchPostItem() {
  yield takeEvery(FETCH_STORY_ITEM, fetchStoryItemWorker);
}

export function* watchFetchEntity() {
  yield takeEvery(FETCH_ENTITY, fetchEntityWorker);
}

export function* watchFetchPerson() {
  yield takeEvery(FETCH_PERSON, fetchPersonWorker);
}
export function* watchFetchListLoadMore() {
  yield takeEvery(FETCH_LIST_LOAD_MORE, fetchListLoadMoreWorker);
}
function* watchPrefetchHtmlPages() {
  yield take(PREFETCH_HTML_PAGES);
  yield call(prefetchWorker);
}

export const sagas = [
  watchFetchList,
  watchFetchHtmlPage,
  watchFetchOriginalShows,
  watchPrefetchHtmlPages,
  watchFetchPostItem,
  watchFetchEntity,
  watchFetchListCollection,
  watchFetchListItem,
  watchFetchMediaAssetsList,
  watchFetchPerson,
  watchFetchListLoadMore,
];

export default function* root() {
  yield all(sagas.map((saga) => fork(saga)));
}
