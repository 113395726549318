import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import { SetAuthStatePayload, SetAuthStateAction } from "../../../redux/authentication/types";
import { AUTH_SIGNUP, AUTH_CHOOSE_ACCOUNT_TYPE } from "../../../redux/authentication/constants";

interface MapDispatchProps {
  setAuthState: (paylaod: SetAuthStatePayload) => SetAuthStateAction;
}

export type Props = MapDispatchProps;

export const Component = (props: Props) => {
  const { setAuthState } = props;

  return (
    <Container>
      Don&lsquo;t have an account? <a onClick={() => setAuthState({ step: AUTH_SIGNUP })}>Join Radivision</a>
      <br />
    </Container>
  );
};

const Container = ({ children }) => {
  return <div className="dont-have-an-account">{children}</div>;
};

const mapState = () => ({});

const mapDispatch = {
  setAuthState: Actions.setAuthState,
};

export const DontHaveAnAccount = connect(mapState, mapDispatch)(Component);
