import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { SVG } from "../../../component-configuration/svgs";
import { AppState } from "../../../redux";
import { stopVideoPlayer } from "../../../redux/youtube-player/actions";
import { Loader } from "../../page/loader";
import Draggable from "react-draggable";

const VideoPanel = React.lazy(() => import("../../page-framework/video-panel"));

const YoutubePlayer = () => {
  const videoPlayer = useSelector((state: AppState) => state?.pipPlayer.videoPlayer);
  const dispatch = useDispatch();
  const videoPanelRef = React.useRef(null);
  const videoSrc = `https://www.youtube.com/embed/${videoPlayer.video}`;
  const youtubeId = videoPlayer.video;

  const disablePip = window.innerWidth < 992;

  if (!videoPlayer.visible) {
    return null;
  }

  if (disablePip) {
    return null;
  }
  return (
    <Draggable handle=".handle">
      <div className="pip-player">
        {/* <iframe
        id="player"
        type="text/html"
        style={{ width: "100%", height: "100%" }}
        src={`https://www.youtube.com/embed/${videoPlayer.video}`}
      /> */}
        <React.Suspense fallback={<Loader isActive={true} />}>
          <VideoPanel
            ref={videoPanelRef}
            videoPosition="FIT"
            videoSrc={videoSrc}
            youTube={youtubeId}
            mute={false}
            disableSeek={false}
            // onReady={() => setIsReady(true)}
            showControls={true}
          />
        </React.Suspense>
        <div
          className="close d-flex justify-content-center align-items-center"
          onClick={() => dispatch(stopVideoPlayer())}
        >
          <i>{SVG.crosshairsThin}</i>
        </div>
        <div className="handle d-flex justify-content-center align-items-center">
          <i>{SVG.arrowsAllDirections}</i>
        </div>
      </div>
    </Draggable>
  );
};

export default YoutubePlayer;
