import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import {
  SetEventStatePayload,
  SetEventStateAction,
  EventsModuleState,
  CreateInvestAmountRequestPayload,
  CreateInvestAmountRequestAction,
} from "../redux/events/types";
import $ from "jquery";
import { Entity, Person } from "@radivision/graphql";
import { RdvButton } from "../../../react-components/page-framework/rdv-button";
import { setEventState, createInvestAmountRequest } from "../redux/events/actions";
import { Checkbox } from "../../../react-components/page-framework/checkbox";
import { EVENT_INVESTMENT_AMOUNT } from "../redux/events/constants";
import { setToastMessage, setAuthState } from "../../../redux/actions";
import { SetToastMessagePayload, SetToastMessageAction } from "../../../redux/toast/types";
import { INVESTOR } from "../../../redux/authentication/account-types";
import { SetAuthStatePayload, SetAuthStateAction } from "../../../redux/authentication/types";
import { AUTH_SIGNIN, AUTHENTICATION_MODAL_ID } from "../../../redux/authentication/constants";
import { TOAST_SUCCESS, TOAST_FAIL } from "../../../redux/toast/constants";
import { getEventInfo } from "../utils/get-event-info";
import { storageFactory } from "../../../utilities/local-storage-factory";
import { has } from "immer/dist/internal";

const localStore = storageFactory(() => localStorage);

interface ComponentProps {
  entity: Entity;
  event: any;
}

interface MapDispatchProps {
  setEventState: (payload: SetEventStatePayload) => SetEventStateAction;
  setToastMessage: (payload: SetToastMessagePayload) => SetToastMessageAction;
  setAuthState: (payload: SetAuthStatePayload) => SetAuthStateAction;
  createInvestAmountRequest: (payload: CreateInvestAmountRequestPayload) => CreateInvestAmountRequestAction;
}

interface MapStateProps {
  investAmount: string;
  user: Person | null;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const InvestButtonComponent = (props: Props) => {
  const {
    entity,
    setEventState,
    investAmount,
    user,
    setToastMessage,
    createInvestAmountRequest,
    setAuthState,
    event,
  } = props;
  const isInvestor = user?.accountType?.kind === INVESTOR.kind;
  const hasInvestmentRequest = localStore.getItem(`invest-amount-${entity?.id}`);
  const [isOtherOption, setOtherOption] = React.useState(false);
  const { url } = getEventInfo(event);

  const onClick = React.useCallback(() => {
    if (!investAmount) {
      setToastMessage({
        msg: `Please select the investment amount.`,
        type: TOAST_SUCCESS,
      });
      return;
    }
    const email = user?.emailAddresses ? user?.emailAddresses[0] : null;
    const payload = {
      entity,
      name: user?.fullName,
      email,
      amount: investAmount,
    };
    createInvestAmountRequest(payload);
  }, [createInvestAmountRequest, user, entity, investAmount, setToastMessage]);

  const isHidden = !entity || !user || (isInvestor && hasInvestmentRequest) || (user && !isInvestor);
  const isHiddenOnClick = React.useCallback(() => {
    if (!isHidden) return;
    if (!entity) {
      setToastMessage({
        msg: `Entity undefined`,
        type: TOAST_FAIL,
      });
      return;
    }
    if (!user) {
      setAuthState({
        step: AUTH_SIGNIN,
        signupAccountTypes: [INVESTOR],
        signupRedirectionUrl: url,
      });
      $(`#${AUTHENTICATION_MODAL_ID}`).modal("show");
    }

    if (isInvestor && hasInvestmentRequest) {
      setToastMessage({
        msg: `Thank you for your interest, we already sent your investment request to "${entity?.name}"`,
        type: TOAST_SUCCESS,
      });
      return;
    }

    if (user && !isInvestor) {
      setToastMessage({
        msg: `Investment request can only be requested by accredited investor.`,
        type: TOAST_SUCCESS,
      });
      return;
    }
  }, [user, isHidden, isInvestor, setToastMessage, entity, hasInvestmentRequest, setAuthState, url]);

  return (
    <button className={`invest-btn  ${isHidden ? "hide-it" : ""}`} onClick={isHiddenOnClick}>
      Invest
      <div className={`invest-menu`}>
        <p>How much would you like to invest?</p>
        <ul>
          {EVENT_INVESTMENT_AMOUNT.map((option) => (
            <li key={`checkbox-${option.amount}`}>
              <Checkbox
                checked={investAmount === option.amount}
                label={option.title}
                onChange={() => {
                  setEventState({ investAmount: option.amount });
                  setOtherOption(false);
                }}
              />
            </li>
          ))}
          <li key={`checkbox-other`}>
            <Checkbox
              checked={isOtherOption}
              onChange={() => {
                setOtherOption(true);
                setEventState({ investAmount: null });
              }}
              label={"Other"}
            />
          </li>
        </ul>
        {isOtherOption && (
          <>
            <label>Please specify:</label>
            <textarea onChange={(e) => setEventState({ investAmount: e.target.value })}>{investAmount}</textarea>
          </>
        )}
        <RdvButton text={"Invest"} onClick={onClick} />
      </div>
    </button>
  );
};

const mapDispatch = {
  setEventState: setEventState,
  setToastMessage: setToastMessage,
  setAuthState: setAuthState,
  createInvestAmountRequest: createInvestAmountRequest,
};

const mapState = (state: AppState & EventsModuleState) => ({
  investAmount: state.events.investAmount,
  user: state.account.user,
});

export const InvestButton = connect(mapState, mapDispatch)(InvestButtonComponent);
