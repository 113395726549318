import * as React from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { Loader } from "../page/loader";
import * as Actions from "../../redux/actions";
import { CloseFullScreenVideoAction, VideoModes } from "../../redux/full-screen-video/types";
import { AppState } from "../../redux";
import { VIDEO_MODE_FULLSCREEN } from "../../redux/full-screen-video/constants";
import isEmpty from "lodash.isempty";
import { convertYoutubeIdToUrl } from "../../utilities/extract-youtube-id";

const VideoPanel = React.lazy(() => import("../page-framework/video-panel"));

interface MapStateProps {
  videoId: string;
  videoMode: VideoModes;
  scrollAnchorId: string;
}

interface MapDispatchProps {
  closeFullScreenVideo: () => CloseFullScreenVideoAction;
}

type Props = MapStateProps & MapDispatchProps;

export const FullScreenVideo = (props: Props) => {
  const { videoId, videoMode, scrollAnchorId, closeFullScreenVideo } = props;

  const closeVideo = React.useCallback(() => {
    try {
      window.scrollTo({ top: $(`#${scrollAnchorId}`).offset().top - 100 });
    } finally {
      window.history.pushState({}, "Radivision: Enterpreneurship Network", window.location.href.split("?")[0]);
      closeFullScreenVideo();
    }
  }, [closeFullScreenVideo, scrollAnchorId]);

  const isNotFullScreenMode = isEmpty(videoId); // || videoMode !== VIDEO_MODE_FULLSCREEN;

  if (isNotFullScreenMode) return null;

  return (
    <React.Suspense
      fallback={
        <div>
          <Loader isActive={true} />
        </div>
      }
    >
      <VideoPanel
        youTube={videoId}
        videoPosition="FULL_SCREEN"
        onVideoEnded={() => {
          closeVideo();
        }}
        hasBackBtn={true}
        showControls={true}
        url={convertYoutubeIdToUrl(videoId)}
      />
    </React.Suspense>
  );
};

const mapState = (state: AppState) => ({
  videoId: state.fullScreenVideo.videoId,
  scrollAnchorId: state.fullScreenVideo.scrollAnchorId,
  videoMode: state.fullScreenVideo.videoMode,
});

const mapDispatch = {
  closeFullScreenVideo: Actions.closeFullScreenVideo,
};

export default connect(mapState, mapDispatch)(FullScreenVideo);
