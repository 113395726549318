import * as React from "react";
import { Loader } from "../../../page/loader";
import $ from "jquery";
import { connect } from "react-redux";
import { Entity, Story, PitchVideoStory } from "@radivision/graphql";
import IsVisible from "../../../../utilities/is-visible-hoc";
import isEmpty from "lodash.isempty";
import { PitchMoreMenu } from "./pitch-more-menu";
import { PromotePitchButton } from "./promote-pitch-button";
import { ConnectInvestorButton } from "./connect-investor-button";
import { VideoModes } from "../../../../redux/full-screen-video/types";
import { AppState, store } from "../../../../redux";
import * as Actions from "../../../../redux/actions";
import { TOAST_ALERT } from "../../../../redux/toast/constants";
import { SetToastMessagePayload, SetToastMessageAction } from "../../../../redux/toast/types";
import { isOwner } from "../../../../utilities/is-owner";
import { gaEvent } from "../../../../utilities/general";
import { CROWNFUNDING_COMPANIES } from "../../../../constants/content-ids";
import { SVG } from "../../../../component-configuration/svgs";
import { RdvButton } from "../../../page-framework/rdv-button";
import { addHttpToLink } from "../../../../utilities/add-http-to-link";
import { convertYoutubeIdToUrl, extractYoutubeId } from "../../../../utilities/extract-youtube-id";
const VideoPanel = React.lazy(() => import("../../../page-framework/video-panel"));

interface ComponentProps {
  item: PitchVideoStory;
  entity: Entity;
}

interface MapStateProps {
  videoId: string;
  videoMode: VideoModes;
  recentlyPlayed: string[];
}

interface MapDispatchProps {
  setToastMessage: (payload: SetToastMessagePayload) => SetToastMessageAction;
}

export type Props = ComponentProps & MapStateProps & MapDispatchProps;

interface State {
  pitchVideoDetails: { youtubeId: string; description: string };
  videoVersion: string;
  isOnScreen: boolean;
  videoReady: boolean;
  isMuted: boolean;
  miniPlayerIsClicked: boolean;
  disableSeek: boolean;
}

export const OPPORTUNITIES_NOTIFY_ME_MODAL = `opportunities-notify-me-modal`;
export class Component extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pitchVideoDetails: {
        youtubeId: null,
        description: null,
      },
      videoVersion: null,
      isOnScreen: false,
      videoReady: false,
      miniPlayerIsClicked: false,
      isMuted: true,
      disableSeek: false,
    };
  }

  render() {
    const { entity, item, setToastMessage, user } = this.props;

    const videoReady = true;
    const isOnScreen = this.state.isOnScreen;
    const owner = isOwner(entity, user);
    const hasPitchVideo = !isEmpty(item);

    if (!entity) {
      return null;
    }

    if (!hasPitchVideo) {
      return null;
    }

    const key = this.state.videoVersion === "99sec" ? "trailer" : "originalClip";
    const currentVideo = item && item[key];
    const eitherVideo = item && (item["trailer"] || item["originalClip"]);
    const details = this.state.videoVersion ? currentVideo : eitherVideo;
    const youtubeId = details?.externalId || extractYoutubeId(item.link);

    const currentVersion = this.state.videoVersion || (item?.trailer ? "99sec" : "5min");
    const hasMissingVideo = isEmpty(item?.originalClip?.externalId) || isEmpty(item?.trailer?.externalId);
    const description = item?.description;
    const backgroundImage = `url('https://i.ytimg.com/vi/${youtubeId}/sddefault.jpg')`;
    const investmentsBy = Array.isArray(entity?.investmentsBy) && entity?.investmentsBy[0];
    const link = investmentsBy?.link;
    const canPlayVideo = isOnScreen;
    const isCrowdFunding = false; /*CROWNFUNDING_COMPANIES.find(({ id }) => id === entity?.id);*/ 

    return (
      <>
        <Container>
          <CardVideoContainer>
            <IsVisible
              onShow={() => (this.state.isOnScreen ? $.noop : this.setState({ isOnScreen: true }))}
              onHide={() => (this.state.isOnScreen ? this.setState({ isOnScreen: false }) : $.noop)}
              className="element"
            >
              <VideoContainer>
                <div
                  style={{
                    backgroundImage,
                    opacity: !canPlayVideo ? 1 : videoReady ? 0 : 1,
                  }}
                  className="pitch-poster"
                />
                <Loader isActive={!videoReady} />
                <Video style={{ backgroundImage: videoReady ? "none" : backgroundImage }}>
                  {canPlayVideo && (
                    <React.Suspense fallback={<Loader isActive={true}></Loader>}>
                      <VideoPanel
                        key={`video-${this.state.videoVersion}-${item?.id}-${this.state.disableSeek}`}
                        youTube={youtubeId}
                        onReady={() =>
                          setTimeout(() => {
                            this.setState({ videoReady: true });
                          }, 1000)
                        }
                        mute={this.state.isMuted}
                        disableAutoPlay={false}
                        onVideoEnded={() => {
                          const nextVideo =
                            this.state.videoVersion === "99sec"
                              ? item?.originalClip?.externalId
                              : item?.trailer?.externalId;
                          if (!nextVideo) {
                            return;
                          }
                          this.setState((state) => ({
                            videoVersion: state.videoVersion === "99sec" ? "5min" : "99sec",
                            disableSeek: true,
                          }));
                        }}
                        json={{}}
                        videoPosition={"FIT"}
                        disableSeek={this.state.disableSeek}
                        url={convertYoutubeIdToUrl(youtubeId)}

                      />
                    </React.Suspense>
                  )}
                </Video>
              </VideoContainer>
            </IsVisible>
          </CardVideoContainer>
          <PitchVideoToolBar>
            {/* {!hasMissingVideo ? ( */}
            <SelectPitchVersion
              selected={currentVersion}
              className={`highlighted ${item?.trailer?.externalId ? "has-quick" : ""}  ${
                item?.originalClip?.externalId ? "has-full" : ""
              }`}
              onClick={(version: string) => {
                const video = version === "99sec" ? item?.trailer : item?.originalClip;
                if (!video) {
                  setToastMessage({
                    msg: owner
                      ? `Please add the ${version} pitch video.`
                      : `${version} pitch video is not added at the moment.`,
                    type: TOAST_ALERT,
                  });
                  return;
                }

                this.setState(() => ({
                  videoVersion: version,
                  disableSeek: true,
                }));
              }}
            />
            {/* ) : null} */}
          </PitchVideoToolBar>
          <Description description={description}>
            <Buttons>
              {owner && <PitchMoreMenu entity={entity} item={item} />}
              {owner && <PromotePitchButton entity={entity} item={item} />}
               {!isCrowdFunding ? (
                <ConnectInvestorButton entity={entity} link={link} />
              ) : (
              <div className={`${owner ? "owner" : "visitor"} more-info`}>
                <RdvButton
                  isOutline={false}
                  onClick={() => {
                    gaEvent("PP-learn-more", "click", item?.peopleEntities[0]?.entity?.name, false);
                    window.open(addHttpToLink(link));
                  }}
                >
                  <span>Invest Now</span>
                  {SVG.externalLinkAlt}
                </RdvButton>
              </div>
               )} 
            </Buttons>
          </Description>
        </Container>
      </>
    );
  }
}

const mapState = (state: AppState) => ({
  user: state.account.user,
  videoId: state.fullScreenVideo.videoId,
  videoMode: state.fullScreenVideo.videoMode,
  recentlyPlayed: state.fullScreenVideo.recentlyPlayed,
});

const mapDispatch = {
  setToastMessage: Actions.setToastMessage,
};

export const PitchVideoPanel = connect(mapState, mapDispatch)(Component);

export const SelectPitchVersion = ({
  onClick,
  selected,
  className,
}: {
  onClick: (s: string) => void;
  selected: string;
  className?: string;
}) => {
  return (
    <div className={`select-pitch-version ${className}`}>
      {["99sec", "separator", "5min"].map((version) => {
        if (version === "separator" && className.includes("has-full") && className.includes("has-quick")) {
          return <span />;
        }
        return (
          <a
            key={`select-pitch-${version}`}
            className={`${selected === version ? "selected" : ""} is-${version}`}
            onClick={() => onClick(version)}
          >
            {version === "99sec" ? "99-sec Trailer" : "Full Episode"}
            {version === selected && className.includes("highlighted") ? (
              <span className="playing">Now Playing</span>
            ) : null}
          </a>
        );
      })}
    </div>
  );
};

const PitchVideoToolBar = ({ children }) => {
  return <div className="pitch-tool-bar">{children}</div>;
};

export const Button = ({
  isOutline,
  text,
  url,
  Icon,
  Image,
  dataTarget,
  dataToggle,
  className,
}: {
  isOutline: boolean;
  text: string;
  url: string;
  Icon?: any;
  Image?: any;
  dataToggle?: string;
  dataTarget?: string;
  className?: string;
}) => {
  const modalProps = dataTarget && dataToggle ? { "data-target": dataTarget, "data-toggle": dataToggle } : {};
  return (
    <a
      href={url}
      target="_blank"
      {...modalProps}
      rel="noreferrer"
      className={`pitch-card-button ${className} ${isOutline ? "outline-button" : ""}`}
    >
      {Icon && <Icon />}
      <span>{text}</span>
      {Image && <img src={Image} />}
    </a>
  );
};

export const VideoContainer = ({ children }) => {
  return <div className="pitch-video-container">{children}</div>;
};

export const CardVideoContainer = ({ children }) => {
  return <div className="pitch-card-video">{children}</div>;
};

export const Buttons = ({ children }) => {
  return <div className="pitch-card-actions">{children}</div>;
};
export const Description = ({ description, children }) => {
  return (
    <div className="opportunities-container">
      <div className="opportunity">
        <div className="opportunity-body">
          <h2>Investment</h2>
          <div>
            {!isEmpty(description) &&
              description?.split(/[\n\r]/).map((line: string, index: number) => {
                return <p key={`opportunity-${line}-${index}`}>{line}</p>;
              })}
          </div>
        </div>
        <div className="opportunity-link">{children}</div>
      </div>
    </div>
  );
};

export const Container = ({ children }) => {
  return (
    <div className="pitch-video-preview centered-pitch">
      <div className="pitch-card">{children}</div>
    </div>
  );
};

export const ArrowUpRight = () => {
  return (
    <svg
      className="arrow-up-right"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const Video = ({ children, style }) => {
  return (
    <div className="pitch-video" style={style}>
      {children}
    </div>
  );
};
