import * as React from "react";

export const INVESTOR = {
  kind: "INVESTOR",
  title: "I am an investor",
  description: "Looking to discover amazing startups and connect directly? This is the account for you!",
  colors: ["#1A2980", "#26D0CE"],
};

export const FOUNDER = {
  kind: "FOUNDER",
  title: "I am a founder",
  description:
    "As a founder you can connect your profile page to your company and introduce the Radical Visionary (you) to everyone.",
  colors: ["#00B09B", "#96C93D"],
};

export const COMPANY = {
  kind: "COMPANY",
  title: "I am a Startup Ecosystem Participant",
  description: (
    <>
      Showcase your amazing company to 1,000s of entrepreneurial enthusiasts and potential investors. Get featured on
      Radivision and social media, apply to Pitch Planet&#8482; and other original series.
    </>
  ),
  colors: ["#6107BC", "#C210DA"],
};

export const ENTHUSIAST = {
  kind: "ENTHUSIAST",
  title: "I'm an entrepreneurial enthusiast",
  description: "Be inspired! Explore, learn and participate in the Entrepreneurial Revolution!",
  colors: ["#FF5F6D", "#FFC371"],
};
