import * as React from "react";
import { connect } from "react-redux";
import * as Actions from "../../redux/actions";
import { AppState } from "../../redux";
import {
  OriginalContentStory,
  GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY,
  PreviewKind,
  TopLevelMediaKind,
} from "@radivision/graphql";
import { OpenDetailsPanelPayload, OpenDetailsPanelAction } from "../../redux/details-panel/types";
import { SVG } from "../../component-configuration/svgs";
import { mapMillisecondsToMinutes } from "../../utilities/general";
import isEmpty from "lodash.isempty";
import ProgressiveImage from "react-progressive-image";

import { FetchListAction, FetchListPayload } from "../../redux/cache/types";
import { Preview } from "../../utilities/preview";
import { ImageHelper } from "../../utilities/image-helper";

interface ComponentProps {}

interface MapDispatchProps {
  openDetailsPanel: (payload: OpenDetailsPanelPayload) => OpenDetailsPanelAction;
  fetchList: (payload: FetchListPayload) => FetchListAction;
}
interface MapStateProps {
  list: any;
  item: any;
  isFetching: boolean;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { list, item, openDetailsPanel, fetchList, isFetching, closeDetailsPanel } = props;

  const [selectedSeason, setSelectedSeason] = React.useState("");

  const isSeasonOne = props?.list?.id === "bKZ9jXKJQSqILgVktGrMbw";

  React.useEffect(() => {
    const shouldFetchSourceList =
      item &&
      item.__typename === GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY &&
      !isEmpty(item?.sourceList) &&
      item?.sourceList !== list?.id;
    if (item && shouldFetchSourceList && !list && !isFetching) {
      fetchList({ variables: { listId: item.sourceList } });
    }
  }, [item, list, fetchList, isFetching]);

  const items = list?.items?.edges?.map((edge: any) => edge.listItem?.item);
  if (!list || !items) return null;
  console.log("debug episodes:", { list, items });
  const listTitle = list?.title?.toLowerCase().replace(/[\s]+/g, "-");
  return (
    <div className="details-panel-section section-no-padding all-episodes">
      <div className="episodes-seasons">
        <h3 className="section-title">Episodes</h3>
        {isSeasonOne && (
          <select
            className="season-select"
            id="season-select"
            value={isSeasonOne ? "Season 1" : "Season 2"}
            onChange={(event) => {
              setSelectedSeason(event.target.value);
              closeDetailsPanel();
              const newUrl = `sheconomics#Season-Two-of-our-original-series-celebrating-women-founders-and-supporters-with-host-Peggy-Northrop-Tune-in-and-learn-how-to-activate-your-superpower-for-success-/hSppv06JQDynG_MXLMsauA/-tiSOlvNS0iCqHadapHIsw`;
              window.location.href = newUrl;
            }}
          >
            <option value="Season 1" className="select-option">
              Season 1
            </option>
            <option value="Season 2" className="select-option">
              Season 2
            </option>
          </select>
        )}
      </div>
      <div className="show-episodes">
        {items?.map((_item: OriginalContentStory, index: number) => {
          const currentItem = item?.id === _item?.id;

          const previewImageUrl = ImageHelper.fetchOptimizedImageUrl({
            imageType: "PREVIEW",
            preview: {
              content: _item.previews,
              previewKind: PreviewKind.CAROUSEL,
              topLevelMedia: TopLevelMediaKind.IMAGE,
            },
            desiredDimensions: { containerWidthRatio: 1, numberOfItems: 2.2 },
            revision: undefined,
          });
          return (
            <div
              key={`${listTitle}-episode${index + 1}-${_item.title?.toLowerCase().replace(/[\s]+/g, "-")}`}
              className={`details-panel-episode ${currentItem ? "current-episode" : ""}`}
              onClick={() => {
                if (!currentItem) {
                  openDetailsPanel({ item: _item });
                }
              }}
            >
              <div className="episode-number">{index + 1}</div>
              <div className="episode-poster">
                {previewImageUrl ? (
                  <ProgressiveImage
                    src={previewImageUrl.requestedResolutionUrl || previewImageUrl.screenResolutionUrl}
                    placeholder={previewImageUrl.placeHolderUrl}
                  >
                    {(src: any, LOADING: any, srcSetData: any) => (
                      <img loading="lazy" alt={`${item?.title ? item?.title : ""} poster`} src={src} />
                    )}
                  </ProgressiveImage>
                ) : null}
              </div>
              <div className="episode-meta">
                <h4 className="episode-meta-title">{_item.title}</h4>
                <p className="episode-meta-description">{_item.description}</p>
              </div>
              <div className="episode-play-btn">
                {!currentItem ? (
                  <i className="play-icon">{SVG.playCircleSolid}</i>
                ) : (
                  <span className="now-playing">
                    <span>Now</span>
                    <span>Playing</span>
                  </span>
                )}
                {!currentItem ? (
                  <span className="episode-duration">{mapMillisecondsToMinutes(_item?.durationInSeconds)}</span>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapState = (state: AppState) => {
  const { item } = state.detailsPanel;
  const { currentHash } = state.app;
  const [_title, itemId, listId] = currentHash ? String(currentHash).split("/") : [];
  const isOriginalShow = itemId === listId;
  const list = isOriginalShow
    ? state.cache.store[itemId]
    : item?.sourceList
    ? state.cache.store[item?.sourceList]
    : null;
  const isFetching = item?.sourceList ? state.cache.inProgress[item.sourceList] : false;
  const device = state.app.device;
  return {
    device,
    item,
    isFetching,
    list: list?.list?.list,
  };
};

const mapDispatch = {
  openDetailsPanel: Actions.openDetailsPanel,
  fetchList: Actions.fetchList,
};

export const Episodes = connect(mapState, mapDispatch)(Component);
