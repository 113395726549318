import React from "react";
import { connect } from "react-redux";
import { FormFieldContainer } from "../../../react-components/modals/modal-form-elements";
import { AppState } from "../../../redux";
import {
  SocialMediaProfiles,
  ProfileModuleState,
  SetProfileSocialLinkPayload,
  SetProfileSocialLinkAction,
} from "../redux/profile/types";
import { socialPlatforms } from "../../../constants/social-platforms";
import { Entity, Person } from "@radivision/graphql";
import { SocialLinkInput } from "./social-link-input";
import { setProfileSocialLink } from "../redux/profile/actions";

interface MapStateProps {
  socialMediaProfiles: SocialMediaProfiles | null;
  subject: Person | Entity;
  defaultEnabled?: string[];
}

interface MapDispatchProps {
  setProfileSocialLink: (payload: SetProfileSocialLinkPayload) => SetProfileSocialLinkAction;
}

type Props = MapDispatchProps & MapStateProps;

const PLATFORMS = Object.keys(socialPlatforms);

const Component = (props: Props) => {
  const { socialMediaProfiles, setProfileSocialLink, defaultEnabled } = props;

  const SelectPlatform = React.useCallback(() => {
    const unused = PLATFORMS.filter(
      (platform) => !(socialMediaProfiles && Object.keys(socialMediaProfiles).includes(platform)),
    );
    if (unused.length <= 0 || defaultEnabled?.length) {
      return null;
    }
    return (
      <div id="add-social-platform">
        {unused.map((k) => {
          return (
            <a
              key={`select-platform-${k}`}
              data-toggle="tooltip"
              data-placement="top"
              title={socialPlatforms[k].title}
              className="select-platform"
              onClick={() => {
                setProfileSocialLink({ [k]: "" });
              }}
            >
              <span>{socialPlatforms[k].icon}</span>
            </a>
          );
        })}
      </div>
    );
  }, [setProfileSocialLink, defaultEnabled, socialMediaProfiles]);

  return (
    <Container>
      <FormFieldContainer>
        <label>Social Media Profiles:</label>
      </FormFieldContainer>
      <SelectPlatform />
      {PLATFORMS.map((platform) => {
        const unselected = socialMediaProfiles && !Object.keys(socialMediaProfiles)?.includes(platform);
        const isDefault = defaultEnabled?.includes(platform);
        if (unselected && !isDefault) return null;
        return <SocialLinkInput platform={platform} key={`social-input-${platform}`} />;
      })}
    </Container>
  );
};

const Container = ({ children }) => {
  return <div className="profile-social-links">{children}</div>;
};

const mapState = (state: AppState & ProfileModuleState) => {
  const { id } = state.profile;
  return {
    user: state.account.user,
    socialMediaProfiles: state.profile.socialMediaProfiles,
    subject: state.cache.store[id],
  };
};

const mapDispatch = {
  setProfileSocialLink: setProfileSocialLink,
};

export const SocialLinks = connect(mapState, mapDispatch)(Component);
