import { Entity, Person } from "@radivision/graphql";
import { SocialMediaProfiles } from "./types";

export function getSocialMediaProfilesMap(subject: Person | Entity): SocialMediaProfiles<string> {
  return subject?.socialMediaProfiles
    ? subject?.socialMediaProfiles.reduce((acc, i) => {
        if (!i.platform) {
          return acc;
        }
        acc[i?.platform?.kind] = i.link;
        return acc;
      }, {})
    : {};
}
