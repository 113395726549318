import {Person, Entity} from "@radivision/graphql";

/**
 * Returns the person or entity's default location if provided and any other location if the
 * default isn't added.
 *
 * @export
 * @param {(Person | Entity)} personOrEntity The person or entity.
 *
 * @returns {string} The location.
 */
export function getLocation(personOrEntity: Person | Entity): string {
  let location: string = null;

  if (personOrEntity && personOrEntity.defaultLocation !== null && personOrEntity.defaultLocation !== undefined) {
    location = personOrEntity.defaultLocation.label;
  } else if (
    personOrEntity &&
    personOrEntity.locations !== undefined &&
    personOrEntity.locations !== null &&
    personOrEntity.locations.length > 0 &&
    personOrEntity.locations[0] !== null
  ) {
    location = personOrEntity.locations[0].label;
  }
  return location;
}
