/**
 * @author Ibrahim Ahmed
 * @copyright Copyright 2021 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2021-03-01
 * @description
 * @filename panel.tsx
 */

import { TopLevelMediaKind } from "@radivision/graphql/lib/ts/graphql/top-level-media-type";
import * as React from "react";
import ProgressiveImage from "react-progressive-image";
import { OptimizedImageUrls } from "../../component-configuration/optimized-image";
import { ImageHelper } from "../../utilities/image-helper";
import { Router } from "../../utilities/router";
import { EditorialPage } from "./types";
import CP from "../../../static/videos/COVID.mp4";
import RV from "../../../static/videos/RV.mp4";
import SHE from "../../../static/videos/SHE.mp4";
import BING from "../../../static/videos/BING.mp4";
import PP from "../../../static/videos/PP.mp4";
import TE from "../../../static/videos/TE2.mp4";
import IQ from "../../../static/videos/equityIQ.mp4";

import CB_COVID from "../../../static/CB_COVID.png";
import CB_IQ from "../../../static/equityIQ.png";
import CB_RV from "../../../static/CB_RV.png";
import CB_SHE from "../../../static/sheconomics-home-logo.png";
import CB_BING from "../../../static/CB_BING.png";
import CB_PP from "../../../static/pitch_planet_on_black.png";
import CB_TE from "../../../static/experts_white.png";

interface PanelProps {
  pages: EditorialPage[];
  subTitle: string;
}

export type Props = PanelProps;

export const Panel = (props: Props) => {
  const { pages, subTitle } = props;
  return (
    <Container>
      <Banners>
        {pages
          ? pages.map((page, index) => {
              return <Banner id={`${page.title}`} page={page} key={`Banner-${index}`} />;
            })
          : null}
      </Banners>
      <Shadow />
    </Container>
  );
};

const Container = ({ children }: React.PropsWithChildren<{}>) => {
  return <div className="editorials-panel">{children}</div>;
};

const Shadow = () => <div className="shadow" />;

const Caption = ({ title }) => (
  <div className="caption">
    <p>{title}</p>
  </div>
);

const Banners = ({ children }: React.PropsWithChildren<{}>) => {
  return <div className="banners">{children}</div>;
};

const Banner = ({ page }: React.PropsWithChildren<{ id: string; page: EditorialPage }>) => {
  const ref = React.useRef(null);
  const [hover, setHover] = React.useState(false);

  const goto = (alias: string) => (e) => {
    e.preventDefault();
    if (!alias) {
      return false;
    }
    Router.navigateTo(alias);
  };
  const BACKGROUND: OptimizedImageUrls = page?.background
    ? ImageHelper.fetchOptimizedImageUrl({
        imageType: "MEDIA_ASSET",
        mediaAsset: {
          files: page.background,
          type: TopLevelMediaKind.IMAGE,
        },
        desiredDimensions: {
          containerWidthRatio: 1,
          numberOfItems: 2,
        },
        revision: undefined,
      })
    : undefined;
  const LOGO: OptimizedImageUrls = page?.logo
    ? ImageHelper.fetchOptimizedImageUrl({
        imageType: "MEDIA_ASSET",
        mediaAsset: {
          files: page.logo,
          type: TopLevelMediaKind.IMAGE,
        },
        desiredDimensions: {
          containerWidthRatio: 6 / 12,
          numberOfItems: 2,
        },
        revision: undefined,
      })
    : undefined;

  React.useEffect(() => {
    if (ref.current) {
      if (hover) ref.current.play();
      else {
        ref.current?.pause();
      }
    }
  }, [hover, ref]);

  return (
    <div
      id={page.title}
      className="banner clickable"
      onMouseOver={() => {
        setHover(true);
      }}
      onMouseOut={() => {
        setHover(false);
      }}
    >
      <div className="inner">
        <a onClick={goto(page?.alias)} className="bg" />
        {hover && (
          <video ref={ref} loop muted={true}>
            {page.title === "theexperts" ? (
              <source src={TE} type="video/mp4" />
            ) : page.title === "equity-iq" ? (
              <source src={IQ} type="video/mp4" />
            ) : page.title === "sheconomics" ? (
              <source src={SHE} type="video/mp4" />
            ) : page.title === "black-is-the-new-green" ? (
              <source src={BING} type="video/mp4" />
            ) : page.title === "pitchplanet" ? (
              <source src={PP} type="video/mp4" />
            ) : (
              ""
            )}
          </video>
        )}
        {page.isComingSoon && <div className="coming-soon">Coming Soon</div>}
      </div>
      <a className="logo" href={page.isComingSoon ? "#" : `/${page.alias}`} onClick={goto(page.alias)}>
        <img
          loading="lazy"
          alt={`${page && page.title ? `${page.title}` : "banner"}`}
          src={
            page.title === "theexperts"
              ? CB_TE
              : page.title === "equity-iq"
              ? CB_IQ
              : page.title === "sheconomics"
              ? CB_SHE
              : page.title === "black-is-the-new-green"
              ? CB_BING
              : page.title === "pitchplanet"
              ? CB_PP
              : null
          }
          onError={(e) => LOGO?.placeHolderUrl}
        />
      </a>
    </div>
  );
};
