import * as React from "react";
import { RdvButton } from "../page-framework/rdv-button";
import $ from "jquery";

export const FormSection = ({ children }) => {
  return <div className="form-section">{children}</div>;
};
export const FormSectionTitle = ({ children }) => {
  return <div className="form-section-title">{children}</div>;
};

export const FormFieldInput = ({
  children,
  withIcon,
  withReset,
  rightAligned,
}: {
  children: React.ReactNode;
  withIcon?: boolean;
  withReset?: boolean;
  rightAligned?: boolean;
}) => {
  return (
    <div
      className={`form-field-input ${withReset ? "input-with-reset" : ""} ${withIcon ? "input-with-icon" : ""} ${
        rightAligned ? "right-aligned" : ""
      }`}
    >
      {children}
    </div>
  );
};
export const FormFieldContainer = ({ children }) => {
  return <div className="form-field-container">{children}</div>;
};
export const CharsCount = ({ count, limit }: { count: number; limit: number }) => {
  return (
    <div className={`chars-counter ${count > limit ? "limit-exceeded" : ""}`}>
      <div className="row">
        {count} / {limit} characters
      </div>
    </div>
  );
};
export const ModalFooter = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <div className="modal-footer">
      <div className="row">{children}</div>
    </div>
  );
};
export const Submit = ({
  onClick,
  disabled,
  className,
  isSubmitting,
  title,
}: {
  onClick: () => void;
  disabled: boolean;
  isSubmitting: boolean;
  title: string;
  className?: string;
}) => {
  return (
    <RdvButton
      extraClassName={`submit-post-button ${disabled ? "disabled" : ""} ${className}`}
      text={title}
      isLoading={isSubmitting}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

export const Cancel = ({ onCancel, modalId, text }: { onCancel?: () => void; modalId?: string; text?: string }) => {
  return (
    <RdvButton
      isOutline={true}
      extraClassName="outline-light cancel-btn"
      isCancel={ typeof onCancel === "function" ? false : true}
      text={text ? text : "Cancel"}
      disabled={false}
      onClick={(e) => {
        e.preventDefault();
        console.log("debug cancel", typeof onCancel)
        if (typeof onCancel === "function") {
          onCancel();
        } else {
          $(`#${modalId}`).modal("hide");
        }
      }}
    />
  );
};
