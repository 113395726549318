import { LocalStorageKeys } from "../../utilities/local-storage-keys";

interface GetUserIdentity {
  userIdentityId: string;
  provider: "linkedin" | "twitter";
}

export function getUserIdentity(): GetUserIdentity {
  const userIdentity: {
    userIdentityId: string;
    provider: "linkedin" | "twitter";
  } = {
    userIdentityId: "",
    provider: "twitter",
  };

  userIdentity.userIdentityId = localStorage.getItem(LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_TWITTER_ID_CREDENTIALS);
  if (userIdentity.userIdentityId === undefined || userIdentity.userIdentityId === null) {
    userIdentity.userIdentityId = localStorage.getItem(
      LocalStorageKeys.KEY_LOCAL_STORAGE_COGNITO_LINKEDIN_ID_CREDENTIALS,
    );
    userIdentity.provider = "linkedin";
  }
  // // console.log("[getUserIdentityId] extractedId :", userIdentity.userIdentityId);
  return userIdentity;
}
