import { all, fork, call, put, delay, takeEvery, select, takeLatest } from "redux-saga/effects";
import $ from "jquery";
import * as Constants from "./constants";
import * as Api from "./api";
import * as Actions from "../actions";
import * as TrackersActions from "../trackers/actions";
import * as LocalStorageActions from "../local-storage/actions";
import { LocalStorageKeys } from "../../utilities/local-storage-keys";
import {
  UpdateUserAction,
  FetchSavedItemsAction,
  SaveResourceAction,
  UnsaveResourceAction,
  UpdateUserMyListAction,
  FollowResourceAction,
  UnfollowResourceAction,
  DeactivateAccountRequestAction,
  FetchCurrentUserRevisionAction,
  ChangeEmailRequestAction,
  VerifyChangeEmailAction,
} from "./types";
import { AppState } from "..";
import { TOAST_FAIL, TOAST_SUCCESS, TOAST_ALERT } from "../toast/constants";
import isEmpty from "lodash.isempty";
import { DEACTIVATE_ACCOUNT_MODAL_ID } from "../../react-components/inner-pages/account/deactivate-account-modal";
import { checkAccountExists, verifyAccount, verifyAttributes } from "../authentication/api";
import { fetchInProgress } from "../cache/actions";
import { setToastMessage } from "../toast/actions";
import { CHANGE_EMAIL_VERIFICATION_MODAL_ID } from "../../react-components/inner-pages/account/change-email-verification-modal";
import { isValidEmail } from "../../utilities/is-valid-email";

export const selectUser = (state: AppState) => state.account.user;
export const selectSavedItems = (state: AppState) => state.account.savedItems;
export const ifUserHasList = (state: AppState) => (state.account.user?.myList ? true : false);
export const savedItemsExists = (state: AppState) => (state.account.savedItems ? true : false);

export function* fetchSavedItemsWorker(action: FetchSavedItemsAction) {
  try {
    const listId = action.payload.listId;
    const currentSavedItems = yield select(selectSavedItems);
    const sameList = listId === currentSavedItems?.id;
    if (sameList) {
      // yield put(trace("CANCEL_FETCH_SAVED_ITEMS","saved items already fetched"));
      return;
    }
    const data = yield call(Api.fetchSavedItems, listId);
    const savedItems = data?.list?.list;
    yield put(Actions.updateSavedItems({ savedItems }));
  } catch (error) {
    console.log("Debug: FetchSavedItems ", { error });
  }
}

export function* updateUserWorker(action: UpdateUserAction) {
  try {
    const user = action.payload.user;
    const key = LocalStorageKeys.KEY_LOCAL_STORAGE_USER_ID;
    const value = user.id;
    yield call(LocalStorageActions.setItem, { key, value });
    yield put(TrackersActions.snowplowIdentify(action.payload));
  } catch (error) {
    //yield put(trace("ERROR", { location: "updateUserWorker", error: error.message }));
  }
}

export function* saveResourceWorker(action: SaveResourceAction) {
  const resourceId = action.payload.resourceId;
  const user = yield select(selectUser);
  try {
    yield put(Actions.addSaveItemLoading({ itemId: resourceId }));
    const hasSavedItems = yield select(savedItemsExists);
    if (user && hasSavedItems) {
      const userSavedItems = yield select(selectSavedItems);
      const savedItems = yield call(Api.updateSavedItems, resourceId, userSavedItems);
      yield put(Actions.updateSavedItems({ savedItems }));
    }
    if (user && !hasSavedItems) {
      const savedItems = yield call(Api.addSavedItems, resourceId, user);
      yield put(Actions.updateUserMyList({ user, savedItems }));
      yield put(Actions.updateSavedItems({ savedItems }));
    }
  } catch (error) {
    // yield put(trace("ERROR", { location: "saveResourceWorker", error }));
    console.log("SaveResource: Error ", { error });
  } finally {
    yield put(Actions.removeSaveItemLoading({ itemId: resourceId }));
  }
}

export function* followResourceWorker(action: FollowResourceAction) {
  const { resourceId } = action.payload;
  try {
    const _user = yield select((state: AppState) => state.account.user);
    yield put(Actions.addSaveItemLoading({ itemId: resourceId }));
    yield call(fetchCurrentUserRevisionWorker, Actions.fetchCurrentUserRevision({ id: _user.id }));
    const user = yield select((state: AppState) => state.account.user);
    const response = yield call(Api.followResource, resourceId, user);
    const updatedUser = response.addToPerson?.person;
    yield put(Actions.updateUser({ user: updatedUser }));
  } catch (error) {
    console.log("DEBUG: followResource", { error });
    // yield put(trace("ERROR", { location: "followResourceWorker", error }));
  } finally {
    yield put(Actions.removeSaveItemLoading({ itemId: resourceId }));
  }
}

export function* unfollowResourceWorker(action: UnfollowResourceAction) {
  const { resourceId } = action.payload;
  try {
    yield put(Actions.addSaveItemLoading({ itemId: resourceId }));
    const _user = yield select((state: AppState) => state.account.user);
    yield call(fetchCurrentUserRevisionWorker, Actions.fetchCurrentUserRevision({ id: _user.id }));
    const user = yield select((state: AppState) => state.account.user);
    const response = yield call(Api.unfollowResource, resourceId, user);
    const updatedUser = response.removeFromPerson?.person;
    yield put(Actions.updateUser({ user: updatedUser }));
  } catch (error) {
    // yield put(trace("ERROR", { location: "unfollowResourceWorker", error }));
    console.log("DEBUG: unfollowResource", { error });
  } finally {
    yield put(Actions.removeSaveItemLoading({ itemId: resourceId }));
  }
}

export function* unsaveResourceWorker(action: UnsaveResourceAction) {
  const resourceId = action.payload.resourceId;
  try {
    yield put(Actions.addSaveItemLoading({ itemId: resourceId }));
    const { savedItems } = yield select((state: AppState) => state.account);
    const response = yield call(Api.removeFromUserSavedItemList, resourceId, savedItems);
    const updatedSavedItems = response.removeFromFixedList.fixedList;
    yield put(Actions.updateSavedItems({ savedItems: updatedSavedItems }));
  } catch (error) {
    // yield put(trace("ERROR", { location: "unsaveResourceWorker", error }));
  } finally {
    yield put(Actions.removeSaveItemLoading({ itemId: resourceId }));
  }
}

export function* updateUserMyListWorker(action: UpdateUserMyListAction) {
  try {
    const { user, savedItems } = action.payload;
    yield call(Api.updatePerson, user, savedItems);
    yield put(Actions.updateUserMyListSuccess({ listId: savedItems.id }));
  } catch (error) {
    // yield put(trace("ERROR", { location: "updateUserMyListWorker", error }));
  }
}
export function* fetchCurrentUserWorker() {
  try {
    console.log("debug: fetchCurrentUserWorker")
    const data = yield call(Api.fetchCurrentUser);
    console.log("debug: fetchCurrentUserWorker API ", { data })
    const user = data?.urlRouteByAlias?.node?.user;
    yield put(Actions.updateUser({ user }));
    return user;
  } catch (error) {
    console.log("Debug: fetchCurrentUserWorker", { error });
  }
}

export function* fetchCurrentUserRevisionWorker(action: FetchCurrentUserRevisionAction) {
  try {
    const data = yield call(Api.fetchCurrentUserRevision, action.payload.id);
    const revision = data?.person.person.revision;
    yield put(Actions.updateUserRevision({ revision }));
    return revision;
  } catch (error) {
    console.log("Debug: fetchCurrentUserRevisionWorker", { error });
  }
}

export function* deactivateAccountRequestWorker(action: DeactivateAccountRequestAction) {
  try {
    yield put(Actions.setAccountIsLoading({ status: true }));
    const userRequest = yield call(Api.deactivateAccountRequest, action.payload);
    yield put(Actions.deactivateAccountFulfilled({ userRequest }));
    yield put(Actions.setAccountIsLoading({ status: false }));
    yield put(
      Actions.setToastMessage({ msg: "Deactivate Account request has been sent successfully", type: TOAST_SUCCESS }),
    );
    $(`#${DEACTIVATE_ACCOUNT_MODAL_ID}`).modal("hide");
    // const callback = action.payload.callback;
    // if (typeof callback === "function") {
    //   callback();
    // }
  } catch (error) {
    yield put(Actions.setAccountIsLoading({ status: false }));
    yield put(Actions.setToastMessage({ msg: "Sending Account Deactivation request failed.", type: TOAST_FAIL }));
    console.log("Error: deactivateAccountRequest", { error });
    //yield put(trace("ERROR", { location: "updateUserWorker", error: error.message }));
  }
}

export function* changeEmailRequestWorker(action: ChangeEmailRequestAction) {
  const email = action.payload.email;
  const currentEmail = action.payload.currentEmail;

  if (isEmpty(email) || !/@/.test(email)) {
    yield put(Actions.setToastMessage({ msg: "Please enter a valid email address.", type: TOAST_ALERT }));
    return;
  }

  if (currentEmail === email) {
    yield put(
      Actions.setToastMessage({
        msg: "The email you entered is the same as your old email, Enter a different email",
        type: TOAST_FAIL,
      }),
    );
    return;
  }

  try {
    yield put(Actions.fetchInProgress({ key: "change-email", status: true }));
    //yield call(checkAccountExists, email);
    const userRequest = yield call(Api.changeEmailRequest, action.payload);
    yield put(Actions.changeEmailFulfilled({ userRequest }));
    yield put(Actions.fetchInProgress({ key: "change-email", status: false }));
    yield call(fetchCurrentUserWorker);
    $(`#${CHANGE_EMAIL_VERIFICATION_MODAL_ID}`).modal("show");
  } catch (error) {
    console.log("Error: checkAccountExists", { error });
    switch (error.code) {
      case "UsernameExistsException": {
        yield put(setToastMessage({ msg: error.message, type: TOAST_ALERT }));
        break;
      }
      case "UserNotConfirmedException": {
        const msg = "Your account requires verification";
        yield put(setToastMessage({ msg: msg, type: TOAST_ALERT }));
        $(`#${CHANGE_EMAIL_VERIFICATION_MODAL_ID}`).modal("show");
        break;
      }
      default: {
        const message = error?.res?.errors ? error?.res?.errors[0]?.message : error.message;
        yield put(setToastMessage({ msg: message, type: TOAST_FAIL }));
        break;
      }
    }
    yield put(fetchInProgress({ key: "change-email", status: false }));
  }
  try {
    // const callback = action.payload.callback;
    // if (typeof callback === "function") {
    //   callback();
    // }
  } catch (error) {
    yield put(Actions.setToastMessage({ msg: "Sending change email request failed.", type: TOAST_FAIL }));
    yield put(Actions.setAccountIsLoading({ status: false }));
    console.log("Error: changeEmailRequestWorker", { error });
    //yield put(trace("ERROR", { location: "updateUserWorker", error: error.message }));
  }
}

export function* verifyChangeEmailWorker(action: VerifyChangeEmailAction) {
  const email = action.payload.email;
  if (isEmpty(email) || !isValidEmail(email)) {
    yield put(Actions.setToastMessage({ msg: "Please enter a valid email address.", type: TOAST_ALERT }));
    return;
  }
  try {
    yield put(Actions.fetchInProgress({ key: "verify-change-email", status: true }));
    yield call(verifyAttributes, action.payload);
    yield put(setToastMessage({ msg: "Account verification success!", type: TOAST_SUCCESS }));
    yield put(Actions.fetchInProgress({ key: "verify-change-email", status: false }));
    $(`#${CHANGE_EMAIL_VERIFICATION_MODAL_ID}`).modal("hide");
  } catch (error) {
    console.log("Error: verifyChangeEmailWorker", { error });
    switch (error.code) {
      default: {
        yield put(setToastMessage({ msg: error.message, type: TOAST_FAIL }));
        break;
      }
    }
    yield put(fetchInProgress({ key: "check-account-exists", status: false }));
  }
}
export function* watchUpdateUser() {
  yield takeEvery(Constants.UPDATE_USER, updateUserWorker);
}

export function* watchFetchSavedItems() {
  yield takeEvery(Constants.FETCH_SAVED_ITEMS, fetchSavedItemsWorker);
}

export function* watchSaveResource() {
  yield takeEvery(Constants.SAVE_RESOURCE, saveResourceWorker);
}

export function* watchFollowResource() {
  yield takeEvery(Constants.FOLLOW_RESOURCE, followResourceWorker);
}

export function* watchUnfollowResource() {
  yield takeEvery(Constants.UNFOLLOW_RESOURCE, unfollowResourceWorker);
}

export function* watchUnsaveResource() {
  yield takeEvery(Constants.UNSAVE_RESOURCE, unsaveResourceWorker);
}

export function* watchUpdateUserMyList() {
  yield takeEvery(Constants.UPDATE_USER_MY_LIST, updateUserMyListWorker);
}

export function* watchFetchCurrentUser() {
  yield takeLatest(Constants.FETCH_CURRENT_USER, fetchCurrentUserWorker);
}
export function* watchFetchCurrentUserRevision() {
  yield takeLatest(Constants.FETCH_CURRENT_USER_REVISION, fetchCurrentUserRevisionWorker);
}
export function* watchDeactivateAccountRequest() {
  yield takeLatest(Constants.DEACTIVATE_ACCOUNT_REQUEST, deactivateAccountRequestWorker);
}
export function* watchChangeEmailRequest() {
  yield takeLatest(Constants.CHANGE_EMAIL_REQUEST, changeEmailRequestWorker);
}
export function* watchVerifyChangeEmail() {
  yield takeLatest(Constants.VERIFY_CHANGE_EMAIL, verifyChangeEmailWorker);
}

export const sagas = [
  watchUpdateUser,
  watchFetchSavedItems,
  watchSaveResource,
  watchUnsaveResource,
  watchUpdateUserMyList,
  watchFollowResource,
  watchUnfollowResource,
  watchFetchCurrentUser,
  watchFetchCurrentUserRevision,
  watchDeactivateAccountRequest,
  watchChangeEmailRequest,
  watchVerifyChangeEmail,
];

export default function* root() {
  yield all(sagas.map((saga) => fork(saga)));
}
