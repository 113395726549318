import { takeEvery, put, call, select } from "redux-saga/effects";
import {
  RemoveFromPersonInput,
  RemoveFromEntityInput,
  Person,
  Entity,
  GRAPHQL_TYPE_ENTITY,
  GRAPHQL_TYPE_PERSON,
} from "@radivision/graphql";
import { getSocialMediaProfilesToBeRemoved } from "../utils";
import { getClientMutationId } from "../../../../../utilities/general";
import { removeFromPerson, removeFromEntity } from "../api";
import isEmpty from "lodash.isempty";
import { AppState } from "../../../../../redux";
import { getSocialMediaProfilesMap } from "../selectors";
import { cacheData } from "../../../../../redux/actions";

export function* removeFromProfileWorker() {
  const { id, revision, socialMediaProfiles, activities } = yield select((state) => state.profile);
  if (isEmpty(id)) {
    return;
  }
  const subject = yield select((state: AppState) => state.cache.store[id]);

  try {
    let updatedSubject: Person | Entity = subject;
    const linksToBeRemoved = getSocialMediaProfilesToBeRemoved(socialMediaProfiles, getSocialMediaProfilesMap(subject));
    const input: RemoveFromPersonInput | RemoveFromEntityInput = {
      id: subject.id,
      revision: revision,
      clientMutationId: getClientMutationId(),
    };

    const currentActitivities = Array.isArray(subject.activities) ? subject.activities?.map((a: any) => a.kind) : [];
    const activitiesToBeRemoved =
      Array.isArray(currentActitivities) &&
      currentActitivities?.reduce((toBeRemoved: string[], key: string) => {
        const exists = Array.isArray(activities) && activities.includes(key);
        if (!exists) {
          toBeRemoved.push(key);
        }
        return toBeRemoved;
      }, []);

    input.activities = activitiesToBeRemoved;
    input.socialMediaProfiles = linksToBeRemoved;

    if (isEmpty(linksToBeRemoved) && isEmpty(activitiesToBeRemoved)) {
      return false;
    }

    if (subject.__typename === GRAPHQL_TYPE_PERSON) {
      updatedSubject = yield call(removeFromPerson, input as RemoveFromPersonInput);
    }
    if (subject.__typename === GRAPHQL_TYPE_ENTITY) {
      updatedSubject = yield call(removeFromEntity, input as RemoveFromEntityInput);
    }
    yield put(cacheData({ key: updatedSubject.id, data: updatedSubject }));
    return true;
  } catch (error) {
    console.log("Error: removeFromPerson", { error });
  }
}
