import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { FormFieldInput, FormFieldContainer, CharsCount } from "../../../react-components/modals/modal-form-elements";
import { SetProfileStatePayload, SetProfileStateAction } from "../redux/profile/types";
import { setProfileState } from "../redux/profile/actions";
import { ProfileModuleState } from "../redux/profile";

interface MapStateProps {
  description: string | null;
}

interface MapDispatchProps {
  setProfileState: (payload: SetProfileStatePayload) => SetProfileStateAction;
}

export type Props = MapStateProps & MapDispatchProps;

export const Component = (props: Props) => {
  const { setProfileState, description } = props;

  const [charsCount, setCharsCount] = React.useState<number>(0);
  const textareaId = "profile-description-input";

  React.useEffect(() => {
    setCharsCount(description ? description.length : 0);
  }, [description]);

  React.useEffect(
    function resizeTextarea() {
      const textarea = document.getElementById(textareaId);
      if (!textarea) {
        return;
      }
      try {
        textarea.setAttribute(
          "style",
          `min-height:100px;max-height:200px;height:${textarea.scrollHeight}px;overflow-y:auto;`,
        );
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      } catch (error) {
        console.log({ error });
      }
    },
    [textareaId],
  );

  // const tooltipText = "" ;//`Enter a brief about your ${type === GRAPHQL_TYPE_PERSON ? "yourself" : "company"}`;
  //       <LabelToolTip text={tooltipText} />
  //     <p className="field-help-text">{tooltipText}</p>

  return (
    <FormFieldContainer>
      <label htmlFor="tagline">
        <span>About:</span>
      </label>
      <FormFieldInput>
        <textarea
          rows={4}
          name="description"
          id={textareaId}
          maxLength={300}
          value={description}
          onChange={(e) => setProfileState({ description: e.target.value })}
        />
        <CharsCount count={charsCount} limit={300} />
      </FormFieldInput>
    </FormFieldContainer>
  );
};

export const Container = ({ children }) => {
  return <div className="profile-description-input">{children}</div>;
};

const mapState = (state: AppState & ProfileModuleState) => ({
  description: state.profile.description,
});

const mapDispatch = {
  setProfileState: setProfileState,
};

export const Description = connect(mapState, mapDispatch)(Component);
