import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import $ from "jquery";
import { RdvButton } from "../../../react-components/page-framework/rdv-button";
import { createRSVPRequest } from "../redux/events/actions";
import { CreateRSVPRequestPayload, CreateRSVPRequestAction } from "../redux/events/types";
import { Person } from "@radivision/graphql";
import { setAuthState, setToastMessage } from "../../../redux/actions";
import { SetAuthStateAction, SetAuthStatePayload } from "../../../redux/authentication/types";
import {
  AUTH_SIGNIN,
  AUTHENTICATION_MODAL_ID,
  AUTH_CHOOSE_ACCOUNT_TYPE,
} from "../../../redux/authentication/constants";
import { SetToastMessagePayload, SetToastMessageAction } from "../../../redux/toast/types";
import { TOAST_SUCCESS, TOAST_ALERT } from "../../../redux/toast/constants";
import { INVESTOR } from "../../../redux/authentication/account-types";
import { getEventInfo } from "../utils/get-event-info";

interface ComponentProps {
  event: any;
}

interface MapDispatchProps {
  createRSVPRequest: (payload: CreateRSVPRequestPayload) => CreateRSVPRequestAction;
  setAuthState: (payload: SetAuthStatePayload) => SetAuthStateAction;
  setToastMessage: (payload: SetToastMessagePayload) => SetToastMessageAction;
}

interface MapStateProps {
  inProgress: boolean;
  user: Person | null;
}

export type Props = ComponentProps & MapDispatchProps & MapStateProps;

export const RSVPButtonComponent = (props: Props) => {
  const { event, inProgress, createRSVPRequest, user, setAuthState, setToastMessage } = props;

  const isAttending =
    event && user?.requests?.find((request) => request.__typename === "RSVPRequest" && request.item?.id === event?.id);

  const isInvestor = user?.accountType?.kind === INVESTOR.kind;

  if (!event) return null;

  const { url } = getEventInfo(event);

  return (
    <RdvButton
      isLoading={inProgress}
      extraClassName={`${isAttending ? "grey-out" : ""}`}
      onClick={() => {
        if (isAttending) {
          setToastMessage({
            msg: isInvestor
              ? `You have registered to attend "${event.name}" successfully, an email will be sent to you with the needed details.`
              : `Thank you for your interest in "${event.name}"  We will notify you when the event is available for your viewing. If you are an accredited investor and you want to watch the event live, please sign up to rsvp.`,
            type: TOAST_SUCCESS,
          });
          return;
        }
        if (!user) {
          setAuthState({
            step: AUTH_SIGNIN,
            signupAccountTypes: [INVESTOR],
            signupRedirectionUrl: url,
          }),
            $(`#${AUTHENTICATION_MODAL_ID}`).modal("show");
        } else if (!user.accountType?.kind) {
          setToastMessage({
            msg: `please choose your account type before registering for this event.`,
            type: TOAST_ALERT,
          });
          setAuthState({ step: AUTH_CHOOSE_ACCOUNT_TYPE, signupRedirectionUrl: url });
          $(`#${AUTHENTICATION_MODAL_ID}`).modal("show");
        } else {
          createRSVPRequest({ eventId: event?.id });
        }
      }}
      text={isAttending ? (isInvestor ? "Attending" : "Registered") : "RSVP"}
    />
  );
};

const mapState = (state: AppState, props: ComponentProps) => ({
  user: state.account.user,
  inProgress: props.event?.id ? state.cache.inProgress[`create-rsvp-request-${props.event?.id}`] : false,
});

const mapDispatch = {
  createRSVPRequest: createRSVPRequest,
  setAuthState: setAuthState,
  setToastMessage: setToastMessage,
};

export const RSVPButton = connect(mapState, mapDispatch)(RSVPButtonComponent);
