import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { FormFieldContainer, FormFieldInput } from "../../../react-components/modals/modal-form-elements";
import { SetProfileStateAction, SetProfileStatePayload, ProfileModuleState } from "../redux/profile/types";
import { setProfileState } from "../redux/profile/actions";

interface MapStateProps {
  location: string;
}

interface MapDispatchProps {
  setProfileState: (payload: SetProfileStatePayload) => SetProfileStateAction;
}

export type Props = MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { location, setProfileState } = props;
  return (
    <FormFieldContainer>
      <label htmlFor="location">
        <span>Location:</span>
      </label>
      <FormFieldInput>
        <input
          type="text"
          name="location"
          maxLength={70}
          value={location ? location : ""}
          onChange={(e) => setProfileState({ location: e.target.value })}
        />
      </FormFieldInput>
    </FormFieldContainer>
  );
};

const mapState = (state: AppState & ProfileModuleState) => {
  return {
    location: state.profile.location,
  };
};

const mapDispatch = {
  setProfileState: setProfileState,
};

export const Location = connect(mapState, mapDispatch)(Component);
