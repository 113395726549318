import { takeEvery, put, call, select } from "redux-saga/effects";
import $ from "jquery";
import { TOAST_FAIL } from "../../../../../redux/toast/constants";
import { fetchInProgress, setToastMessage, updateUser } from "../../../../../redux/actions";
import { SUBMIT_PROFILE } from "../constants";
import { updateProfileWorker } from "./update-profile";
import { removeFromProfileWorker } from "./remove-from-profile";
import { deleteFromProfileWorker } from "./delete-from-profile";
import { validateProfileForm } from "./validate-profile";
import { EDIT_PROFILE_MODAL_ID } from "../../../edit-profile-modal";
import { AppState } from "../../../../../redux";
import { ProfileModuleState } from "../types";
import { GRAPHQL_TYPE_PERSON } from "@radivision/graphql";

function* submitProfileWorker() {
  try {
    const isValid = yield call(validateProfileForm);
    if (!isValid) {
      return;
    }
    yield put(fetchInProgress({ key: "submit-profile", status: true }));

    const updates = [
      yield call(updateProfileWorker),
      yield call(removeFromProfileWorker),
      yield call(deleteFromProfileWorker),
    ];

    if (updates.includes(true)) {
      yield put(setToastMessage({ msg: "Profile was updated!" }));
    } else {
      yield put(setToastMessage({ msg: "No changes!" }));
    }

    yield put(fetchInProgress({ key: "submit-profile", status: false }));
    $(`#${EDIT_PROFILE_MODAL_ID}`).modal("hide");

    const subject = yield select((state: AppState & ProfileModuleState) => state.cache.store[state.profile.id]);
    if (subject?.__typename === GRAPHQL_TYPE_PERSON) {
      yield put(updateUser({ user: subject }));
    }
  } catch (errors) {
    yield put(setToastMessage({ msg: "Updating profile failed!", type: TOAST_FAIL }));
    yield put(fetchInProgress({ key: "submit-profile", status: false }));
    $(`#${EDIT_PROFILE_MODAL_ID}`).modal("hide");
    console.log("Error: submit profile", { errors });
  }
}

export function* watchSubmitProfile() {
  yield takeEvery(SUBMIT_PROFILE, submitProfileWorker);
}
