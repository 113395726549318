/**
 * @author Sarah Nouh
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2018-10-16 12:00
 * @description Implementation of the search component.
 * @filename search-component.tsx
 */
import React from "react";
import { CarouselItem } from "../../component-configuration/carousel-item";
import { getCarouselItem as _getCarouselItem } from "../../utilities/general";
import { Router } from "../../utilities/router";
import { getSuggestions } from "../../utilities/search";
import { GRAPHQL_TYPE_SEARCH_HIT } from "@radivision/graphql/lib/ts/graphql/search-hit";
import { PreviewKind } from "@radivision/graphql/lib/ts/graphql/preview-type";
import { SVG } from "../../component-configuration/svgs";
// import { SVG } from "../../component-configuration/svgs";
const windowSize = window.innerWidth;

/**
 * The props of the Search component.
 *
 * @interface
 */
interface SearchProps {
  /**
   * flag to diable pressing enter key on keyboard on add-entity-tab when searching for existing entity | person search field
   *
   * @type {boolean}
   * @memberof AddMediaModalProps
   */
  disableKeyboardEnter?: boolean;

  /**
   * search input placeholder.
   *
   * @type {string}
   * @memberof SearchProps
   */
  placeholder: string;
  /**
   * the function that toggles the search component.
   *
   * @type { (e: React.MouseEvent) => void}
   * @memberof SearchProps
   */
  toggleSearch?: (e?: React.MouseEvent, state?: string) => void;

  /**
   * if the search was instantiated from a certain panel
   *
   * @type {string}
   */
  panelFilter?: string;

  /**
   * search tag if the search was instantiated from a certain panel
   *
   * @type {string}
   */
  searchTag?: string;

  /**
   * indicates when the search is toggled (closed or opened)
   *
   * @type {boolean}
   */
  searchComponentOpened?: boolean;
  /**
   *Handles adding existing entity when a search suggestion is clicked in entity acquisiting modal
   *
   * @type {Function}
   */
  AddExistingEntity?: Function;

  /**
   * on search input change
   *
   * @type {() => void)}
   */
  onChange?: () => void;

  /**
   *Handles select person on click on suggestion panel list items
   *
   * @type {Function}
   */
  selectPerson?: Function;

  /**
   *
   *
   * @type {string}
   * @memberof SearchProps
   */
  id?: string;

  /**
   * to hide 'see all results' link
   *
   * @type {boolean}
   */
  hideSeeAllResultsLink?: boolean;

  /**
   * flag to check loading state
   *
   * @type {boolean}
   */
  isLoading?: boolean;
  onSelectSuggestion?: (s: CarouselItem) => void;
}

/**
 * The state of the Search component.
 *
 * @interface
 */
interface SearchState {
  /**
   * The search results
   *
   * @type {CarouselItem[]}
   */
  searchResult: CarouselItem[];

  /**
   * The search value typed in
   *
   * @type {string}
   */
  searchValue: string;

  /**
   *
   *
   * @type {boolean}
   * @memberof SearchState
   */
  loading: boolean;

  /**
   *
   *
   * @type {string}
   * @memberof SearchState
   */
  feedBackMessage: string;
}

/**
 *  A React component that renders the search component.
 *
 * @export
 * @class SearchComponent
 * @extends {React.Component<SearchProps>}
 */
export class SearchComponent extends React.Component<SearchProps, SearchState> {
  /**
   * Stores the current suggestion to use to add a current existing entity
   *
   * @type {CarouselItem}
   */
  suggestion: CarouselItem;
  /**
   * Ref to the search input
   *
   * @type {React.RefObject<HTMLInputElement>}
   */
  inputRef: React.RefObject<HTMLInputElement>;
  /**
   * Constructor.
   *
   * @param {SearchProps} props The props of the component.
   */
  constructor(props: SearchProps) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      searchResult: [],
      searchValue: "",
      feedBackMessage: "",
      loading: false,
    };
  }

  /**
   * Invoked before the render method is called
   * used to clear the search component
   *
   */
  static getDerivedStateFromProps(props: SearchProps, state: SearchState): any {
    if (!props.searchComponentOpened) {
      // if the search is closed, clear search value and suggestions
      state.searchResult = [];
      state.searchValue = "";
    }
    // }
    return null;
  }

  /**
   * Invoked when the user types a keyword for search.
   *
   * @param {React.ChangeEvent} e The event which triggered the function.
   */
  handleSearchValue(e: React.ChangeEvent) {
    const currentTarget: any = e.currentTarget;
    const currentValue: string = currentTarget.value;
    let searchResult: CarouselItem[] = [];
    let message: string = "";

    // if the user cleared the text -> then clear results else search
    if (currentValue.length > 0) {
      this.setState({
        searchValue: currentValue,
        loading: true,
        feedBackMessage: "loading ...",
      });

      getSuggestions(currentValue, this.props.panelFilter).then((result) => {
        searchResult = result.map((suggestion) => {
          const suggestionObject = {
            title: suggestion.suggestion,
            id: suggestion.id,
            __typename: GRAPHQL_TYPE_SEARCH_HIT,
          };
          return _getCarouselItem(
            suggestionObject,
            null,
            0,
            {
              containerWidthRatio: 1,
              numberOfItems: 1,
            },
            PreviewKind.SQUARE,
          );
        });

        if (searchResult.length === 0 && !this.props.disableKeyboardEnter) {
          message = "Press enter to see more search results";
        }

        this.setState({
          searchResult: [...searchResult],
          loading: false,
          feedBackMessage: message,
        });
        this.inputRef.current.classList.add("suggest");
      });
    } else {
      // the user cleared then clear everything
      this.setState({
        searchValue: "",
        feedBackMessage: "",
        searchResult: [],
        loading: false,
      });
      this.inputRef.current.classList.remove("suggest");
    }
  }

  /**
   * Invoked to handle adding an existing media
   *
   */
  handleAddExistingEntityOnSubmit = () => {
    return this.props.AddExistingEntity(this.suggestion);
  };
  /**
   * Invoked when the user clicks on a suggestion for the search.
   *
   * @param {React.MouseEvent<HTMLElement>} event The event which triggered the function
   * @param {any} suggestion The suggestion the user clicked on
   */
  handleSearchSuggestionClick = (event: React.MouseEvent<HTMLElement>, suggestion: any) => {
    console.log("DEBUG: handleSearchSuggestionClick", { suggestion });
    this.setState({
      searchValue: suggestion.title,
    });
    this.inputRef.current.classList.remove("suggest");
    // close the search component on click
    // check if the search is initiated from main navbar or one of the panels.
    // because the toggling function is different in each
    if (this.props.toggleSearch) {
      if (this.props.panelFilter) this.props.toggleSearch(undefined, "close");
      else this.props.toggleSearch(event);
    }

    if (window.innerWidth < 576) {
      // close dropdown on click in mobile screens
      const target: any = event.target;
      setTimeout(() => {
        if (target.closest("#navbarSupportedContent")) {
          target.closest("#navbarSupportedContent").classList.remove("show");
          document.querySelector(".navbar-toggler").classList.add("collapsed");
          document.querySelector(".navbar-toggler").setAttribute("aria-expanded", "true");
        }
      }, 500);
    }
    if (this.props.onSelectSuggestion) {
      this.props.onSelectSuggestion(suggestion);
      this.setState({
        searchResult: [],
        searchValue: "",
      });
      return;
    }
    if (this.props.AddExistingEntity || this.props.selectPerson) {
      this.suggestion = suggestion;
      this.inputRef.current.value = this.suggestion.title;
      if (this.props.selectPerson) {
        this.props.selectPerson(suggestion);
      }
      if (this.props.AddExistingEntity) {
        //this.props.AddExistingEntity(suggestion);
      }
    } else if (this.props.panelFilter) {
      // if the search is triggered from a certain panel
      Router.to("search", [
        `query=${encodeURIComponent(suggestion.title)}`,
        `searchPhrase=${encodeURIComponent(this.state.searchValue)}`,
        `searchFilter=${encodeURIComponent(this.props.panelFilter)}`,
        `tag=${encodeURIComponent(this.props.searchTag)}`,
      ]);
    } else {
      // the search is triggered from the navbar
      Router.to("search", [
        `query=${encodeURIComponent(suggestion.title)}`,
        `searchPhrase=${encodeURIComponent(this.state.searchValue)}`,
      ]);
    }
    // after routing, clear search value and suggestions
    this.setState({
      searchResult: [],
    });
  };

  /**
   * Returns a ReactNode containing the rendered component.
   *
   * @returns {React.ReactNode} The ReactNode containing the rendered component.
   */
  render() {
    const searchWordRegex = new RegExp(this.state.searchValue.replace(/\W/g, ""), "gi");
    return (
      <div>
        <div className="gray-div" />
        <div className="search-input-container">
          <input
            ref={this.inputRef}
            type="text"
            autoComplete={"off"}
            placeholder={`${this.props.placeholder}`}
            aria-label="Search"
            value={this.state.searchValue}
            onChange={(event) => {
              if (this.props.isLoading) return;
              this.handleSearchValue(event);
              if (this.props.onChange) {
                this.props.onChange();
              }
            }}
            disabled={this.props.isLoading}
            key={this.props.id}
            id={this.props.id}
            onKeyPress={(event) => {
              if (this.props.isLoading) return;
              if (this.props.disableKeyboardEnter && event.charCode === 13) {
                event.preventDefault();
              }
              if (!this.props.disableKeyboardEnter && event.charCode === 13) {
                this.handleSearchSuggestionClick(event as any, {
                  title: this.state.searchValue,
                });
              }
            }}
          />
          {this.props.searchComponentOpened && (this.props.toggleSearch || this.state.searchValue.length) ? (
            <div
              className="dispose-search"
              onClick={(e) => {
                // the user cleared then clear everything
                this.setState({
                  searchValue: "",
                  feedBackMessage: "",
                  searchResult: [],
                  loading: false,
                });
                this.inputRef.current.classList.remove("suggest");
                if (this.props.toggleSearch) {
                  this.props.toggleSearch(e);
                }
                this.inputRef.current.blur();
              }}
            >
              {SVG.crosshairsThin}
            </div>
          ) : null}
        </div>
        <div className="container-fluid">
          <div className="results-container">
            <div className="suggestions">
              <ul className="list-group">
                {!this.props.isLoading &&
                  this.state.searchResult.map((result: any, index: number) => {
                    return index < 6 ? (
                      <li
                        className="list-group-item text-result"
                        key={index}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          this.handleSearchSuggestionClick(event, result);
                        }}
                      >
                        <span>
                          <a>{result.title}</a>
                        </span>
                      </li>
                    ) : null;
                  })}
                {!this.props.isLoading &&
                !this.props.hideSeeAllResultsLink &&
                this.state.searchResult &&
                this.state.searchResult.length ? (
                  <li className="list-group-item text-result all-results">
                    <a
                      onClick={(e) => {
                        this.handleSearchSuggestionClick(e as any, {
                          title: this.state.searchValue,
                        });
                      }}
                    >
                      See all results
                    </a>
                  </li>
                ) : null}
              </ul>
            </div>
            {this.props.isLoading ? null : this.state.searchResult.length ? (
              <hr />
            ) : this.state.feedBackMessage ? (
              <p>{this.state.feedBackMessage}</p>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
