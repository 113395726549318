import * as React from "react";
import { Person, Entity } from "@radivision/graphql";
import { connect } from "react-redux";
import { ProfilePicture } from "./profile-picture";
import { Router } from "../../../utilities/router";
import { getProfileInfo } from "../../../utilities/get-profile-info";
import { SetProfileActiveTabPayload, SetProfileActiveTabAction } from "../../../redux/account/types";
import { setProfileActiveTab } from "../../../redux/actions";
interface ComponentProps {
  subject: Person | Entity;
  subtitle?: string;
}

interface MapDispatchProps {
  setProfileActiveTab: (payload: SetProfileActiveTabPayload) => SetProfileActiveTabAction;
}
export type Props = ComponentProps & MapDispatchProps;

export const ProfilePictureNameComponent = (props: Props) => {
  const { subject, subtitle, setProfileActiveTab } = props;

  if (!subject) {
    return null;
  }

  const { profileUrl, name } = getProfileInfo(subject);

  return (
    <div
      className="profile-picture-name"
      onClick={() => {
        setProfileActiveTab({ activeTab: "Posts" });
        Router.navigateTo(profileUrl);
      }}
    >
      <ProfilePicture subject={subject} />
      <div className="profile-name">
        <a
          href={profileUrl}
          onClick={(e) => {
            e.preventDefault();
            Router.navigateTo(profileUrl);
          }}
        >
          {name}
        </a>
        {subtitle && <em>{subtitle}</em>}
      </div>
    </div>
  );
};

const mapDispatch = {
  setProfileActiveTab: setProfileActiveTab,
};
const mapState = () => ({});
export const ProfilePictureName = connect(mapState, mapDispatch)(ProfilePictureNameComponent);
