/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2020-04-13
 * @description Implementation of Article utilities to be used in the app.
 * @filename analytics.ts
 */

import { CreateUserHistoryInput } from "@radivision/graphql/lib/ts/graphql/mutations/create-user-history-inputs";
import { CREATE_USER_HISTORY_GRAPHQL_MUTATION } from "../relay/mutations/create-user-history";
import { ENVIRONMENT as RELAY_ENVIRONMENT } from "../relay/relay-environment";
import { getClientMutationId } from "./general";
import { storageFactory } from "./local-storage-factory";
import { LocalStorageKeys } from "./local-storage-keys";
import { commitMutation } from "./relay";
const localStore = storageFactory(() => localStorage);

/**
 *
 *
 * @export
 * @class Analytics
 */
export class Analytics {
  /**
   *
   *
   * @static
   * @memberof Analytics
   */
  static userHistory(attributes: { [index: string]: string }): Promise<string> {
    let promise: Promise<string> = Promise.resolve(undefined);
    const userHistoryRequestInput: CreateUserHistoryInput = {
      clientMutationId: getClientMutationId(),
      id: undefined,
      item: undefined,
      topLevelType: undefined,
    };

    if (attributes) {
      userHistoryRequestInput.id = localStore.getItem(LocalStorageKeys.KEY_LOCAL_STORAGE_USER_ID);
      userHistoryRequestInput.item = attributes["item"];
    }

    promise = promise.then(
      (): Promise<string> => {
        const CONFIG = {
          mutation: CREATE_USER_HISTORY_GRAPHQL_MUTATION,
          variables: {
            input: userHistoryRequestInput,
          },
        };
        if (!userHistoryRequestInput.id) {
          throw new Error("invalid user history request, no user id provided");
        }

        if (!userHistoryRequestInput.item) {
          throw new Error("invalid user history request, no item id provided");
        }

        //
        return commitMutation(RELAY_ENVIRONMENT, CONFIG).then((mutationResults: any) => {
          // console.log(mutationResults);
          return mutationResults.createUserHistory.hasErrors === false
            ? Promise.resolve(mutationResults.createUserHistory.userHistory)
            : Promise.reject(mutationResults.createUserHistory.errors);
        });
      },
    );

    promise = promise.catch(
      (err): Promise<string> => {
        console.error(err);

        return Promise.resolve(undefined);
      },
    );

    return promise;
  }
}
