import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../redux";
import { SetProfilePhotoAction, SetProfilePhotoPayload, ProfileModuleState, SetProfileStateAction, SetProfileStatePayload } from "../redux/profile/types";
import { setProfilePhoto, setProfileState } from "../redux/profile/actions";
import { FetchMediaAssetsListAction, FetchMediaAssetsListPayload } from "../../../redux/cache/types";
import { fetchMediaAssetsList } from "../../../redux/actions";
import { OptimizedImageUrls } from "../../../component-configuration/optimized-image";
import { ImageHelper } from "../../../utilities/image-helper";
import { TopLevelMediaKind, PreviewKind } from "@radivision/graphql";
import ProgressiveImage from "react-progressive-image";
import { Loader } from "../../../react-components/page/loader";
import "./banners-list.scss";
import {EDIT_PROFILE_PAGE_INFO} from "../redux/profile/constants";

interface MapStateProps {
  list: any;
  inProgress: boolean;
}

interface MapDispatchProps {
  setProfilePhoto: (payload: SetProfilePhotoPayload) => SetProfilePhotoAction;
  setProfileState: (payload: SetProfileStatePayload) => SetProfileStateAction;
  fetchMediaAssetsList: (payload: FetchMediaAssetsListPayload) => FetchMediaAssetsListAction;
}

export type Props = MapDispatchProps & MapStateProps;

export const Component = (props: Props) => {
  const { inProgress, list, setProfilePhoto, setProfileState, fetchMediaAssetsList } = props;
  console.log("debug:", { list, inProgress });

  const onClick = React.useCallback(
    (backgroundAssetId, previewUrl: OptimizedImageUrls) => () => {
      setProfilePhoto({ type: PreviewKind.BACKGROUND, previewUrl });
      setProfileState({ screen: EDIT_PROFILE_PAGE_INFO, backgroundAssetId });
    },
    [setProfilePhoto, setProfileState],
  );

  React.useEffect(() => {
    if (!list && !inProgress) {
      fetchMediaAssetsList({ id: process.env.BACKGROUND_LIST });
    }
  }, [list, fetchMediaAssetsList, inProgress]);

  const getMediaAssetPreviewUrl = React.useCallback((item) => {
    const previewUrl: OptimizedImageUrls = ImageHelper.fetchOptimizedImageUrl({
      imageType: "MEDIA_ASSET",
      mediaAsset: {
        files: item.files,
        type: TopLevelMediaKind.IMAGE,
      },
      desiredDimensions: {
        containerWidthRatio: 4 / 12,
        numberOfItems: 2,
      },
      revision: item.revision,
    });
    return previewUrl;
  }, []);

  return (
    <div className="banners-list sm-scroller">
      <Loader isActive={inProgress} />
      {list?.items.edges.map((edge: any) => {
        const item = edge?.listItem?.item;
        const previewUrl = getMediaAssetPreviewUrl(item);
        return (
          <ProgressiveImage
            key={`banner-${item.id}`}
            src={previewUrl.requestedResolutionUrl}
            placeholder={previewUrl.placeHolderUrl}
          >
            {(src: string, loading: boolean) => {
              const backgroundImage = `url('${src}')`;
              return (
                <div
                  className="banner"
                  onClick={onClick(item.id, previewUrl)}
                  style={{ opacity: loading ? 0.5 : 1, backgroundImage }}
                ></div>
              );
            }}
          </ProgressiveImage>
        );
      })}
    </div>
  );
};

const mapState = (state: AppState & ProfileModuleState) => {
  return {
    name: state.profile.name,
    list: state.cache.store[process.env.BACKGROUND_LIST],
    inProgress: state.cache.inProgress[process.env.BACKGROUND_LIST],
  };
};

const mapDispatch = {
  setProfilePhoto: setProfilePhoto,
  setProfileState: setProfileState,
  fetchMediaAssetsList: fetchMediaAssetsList,
};

export const BannersList = connect(mapState, mapDispatch)(Component);
