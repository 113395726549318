/**
 * @author Ibrahim Ahmed
 * @copyright Copyright 2021 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2021-03-01
 * @description
 * @filename index.tsx
 */

import * as React from "react";
import { Panel } from "./panel";
import { EditorialPageProps } from "./types";

const pages = [
  // {
  //   title: "radical-visionaries",
  //   alias: "radical-visionaries",
  //   logo: [
  //     {
  //       id: "vM7UW8INQFOQllVcyQuQpg",
  //       name: "mXQ9FTJERiiiVYqw1eTlmw.png",
  //       path: "mx/q9",
  //     },
  //   ],
  //   logoFg: [{}],
  //   background: [
  //     {
  //       id: "krruTQg4SnmKs76OCVImjQ",
  //       name: "QCkkqB5rTnmWL58xBWiKGw.jpg",
  //       path: "qc/kk",
  //     },
  //   ],
  //   isComingSoon: false,
  // },
  {
    title: "pitchplanet",
    alias: "pitchplanet",
    logo: [
      {
        id: "J30OKs6yS06ExpV9xDjsuw",
        name: "EkeOLTfLSlSzMdwQqRre0g.png",
        path: "ek/eo",
      },
    ],
    logoFg: [{}],
    background: [
      {
        id: "mHz38zQVTaedH-X9eEg4EA",
        name: "EahD8H4oRPSlAgbYO6ZmtA.jpg",
        path: "ea/hd",
      },
    ],
    isComingSoon: false,
  },
  {
    title: "equity-iq",
    // alias: "equity-iq",
    // logo: [
    //   {
    //     id: "vM7UW8INQFOQllVcyQuQpg",
    //     name: "mXQ9FTJERiiiVYqw1eTlmw.png",
    //     path: "mx/q9",
    //   },
    // ],
    // logoFg: [{}],
    // background: [
    //   {
    //     id: "krruTQg4SnmKs76OCVImjQ",
    //     name: "QCkkqB5rTnmWL58xBWiKGw.jpg",
    //     path: "qc/kk",
    //   },
    // ],
    isComingSoon: false,
  },
  {
    title: "sheconomics",
    alias: "sheconomics",
    logo: [
      {
        id: "ZDFdXWS4S3eAJkm9uLz04A",
        name: "SusLDPkIQhG63q04vCGemQ.png",
        path: "su/sl",
      },
    ],
    logoFg: [{}],
    background: [
      {
        id: "sitrnRzOT3m0-Pud-Oy45A",
        name: "CCWxky6hQdaTJ1V9io41KQ.jpg",
        path: "cc/wx",
      },
    ],
    isComingSoon: false,
  },

  {
    // alias: "theexperts",
    title: "theexperts",
    // logo: [
    //   {
    //     id: "MDnQ76xYSpSPdJX6rCZigA",
    //     name: "hQZQNDxMTX2gDwFSNgotYw.png",
    //     path: "hq/zq",
    //   },
    // ],
    // logoFg: [
    //   {
    //     id: "8jOqQD8sQfSAS4SIWoY69A",
    //     name: "19ACE1KvSR2Aaycgmi7gKw.png",
    //     path: "19/ac",
    //   },
    // ],
    // background: [
    //   {
    //     name: "GcCPGlbrRLlGX4ccBeYP9g.jpg",
    //     path: "gc/cp",
    //     id: "7mBlhf9lRBmYO6M384qbIA",
    //   },
    // ],
    isComingSoon: false,
  },
  {
    title: "black-is-the-new-green",
    alias: "black-is-the-new-green",
    logo: [
      {
        id: "2BHq6weOQnOmdjDviJbNnQ",
        name: "0xGfrkKcSlegWjDwSTN8Xw.png",
        path: "0x/gf",
      },
    ],
    logoFg: [{}],
    background: [
      {
        id: "mHz38zQVTaedH-X9eEg4EA",
        name: "EahD8H4oRPSlAgbYO6ZmtA.jpg",
        path: "ea/hd",
      },
    ],
    isComingSoon: false,
  },
];

export const NewEditorials = (props) => {
  const PROPS: EditorialPageProps = props.json;

  return <Panel pages={pages} subTitle={""} />;
};

export { Covidpreneurs } from "./covid-preneurs";
