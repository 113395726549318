import * as React from "react";
import { connect } from "react-redux";
import { FixedList, Person } from "@radivision/graphql";
import {
  SaveResourcePayload,
  SaveResourceAction,
  UnsaveResourcePayload,
  UnsaveResourceAction,
  FollowResourceAction,
  FollowResourcePayload,
  UnfollowResourcePayload,
  UnfollowResourceAction,
} from "../../../redux/account/types";
import { CarouselItem } from "../../../component-configuration/carousel-item";
import { SVG } from "../../../component-configuration/svgs";
import { AppState } from "../../../redux";
import * as Actions from "../../../redux/actions";
import * as Selectors from "./selectors";
import isEmpty from "lodash.isempty";
import { AUTHENTICATION_MODAL_ID } from "../../../redux/authentication/constants";

interface AddToSavedButtonProps {
  item: CarouselItem;
  className?: string;
  label?: string;
}

interface MapDispatchProps {
  saveResource?: (payload: SaveResourcePayload) => SaveResourceAction;
  unsaveResource?: (payload: UnsaveResourcePayload) => UnsaveResourceAction;
  followResource?: (payload: FollowResourcePayload) => FollowResourceAction;
  unfollowResource?: (payload: UnfollowResourcePayload) => UnfollowResourceAction;
}

interface MapStateProps {
  user?: Person | null;
  savedItems?: FixedList;
  isItemSaved?: boolean;
  isSaveItemLoading?: boolean;
  isPersonOrEntity?: boolean;
  isItemSaveable?: boolean;
}

type Props = AddToSavedButtonProps & MapDispatchProps & MapStateProps;

const Component = (props: Props) => {
  const {
    user,
    item,
    isSaveItemLoading,
    isPersonOrEntity,
    isItemSaveable,
    isItemSaved,
    saveResource,
    unsaveResource,
    followResource,
    unfollowResource,
    className,
    label,
  } = props;

  if (isEmpty(item) || !isItemSaveable) {
    return null;
  }

  return (
    <span
      data-target={`#${AUTHENTICATION_MODAL_ID}`}
      data-toggle={`${!user ? "modal" : ""}`}
      className={`${false ? "has-tooltip" : ""} ${label ? "with-label" : ""} ${isItemSaved || isSaveItemLoading ? "saved" : ""} save-item ${className}`}
      onClick={() => {
        if (!user) {
          return;
        }

        if (isSaveItemLoading) {
          return;
        }

        if (isPersonOrEntity) {
          if (isItemSaved) {
            unfollowResource({ resourceId: item.id });
          } else {
            {
              user.id === item.id ? null : followResource({ resourceId: item.id });
            }
          }
        } else {
          if (isItemSaved) {
            unsaveResource({ resourceId: item.id });
          } else {
            saveResource({ resourceId: item.id });
          }
        }
      }}
    >
      {isSaveItemLoading ? SVG.spinner : isItemSaved ? SVG.checkmark : label ? null : SVG.plusAlt}
      {label && <span>{label}</span>}
      <div className="tooltip">
        {isPersonOrEntity
          ? isItemSaved
            ? "Unfollow"
            : "Follow"
          : isItemSaved
          ? "Remove from Saved"
          : "Save for later"}
      </div>
    </span>
  );
};

const mapState = (state: AppState, props: Props) => ({
  user: state.account.user,
  savedItems: state.account.savedItems,
  isItemSaved: Selectors.isItemSaved(state, props.item),
  isSaveItemLoading: props.item && Selectors.isSaveItemLoading(state, props.item),
  isPersonOrEntity: props.item && Selectors.isPersonOrEntity(state, props.item),
  isItemSaveable: props.item && Selectors.isItemSaveable(state, props.item),
});

const mapDispatch = {
  saveResource: Actions.saveResource,
  unsaveResource: Actions.unsaveResource,
  followResource: Actions.followResource,
  unfollowResource: Actions.unfollowResource,
};

export const AddToSavedButton = connect(mapState, mapDispatch)(Component);

export default AddToSavedButton;
