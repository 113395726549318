/**
 * @author Ahmed Samer
 * @copyright Copyright 2020 by Radivision Inc., CA, USA. All Rights Reserved.
 * @Date: 2022-02-01
 * @description
 * @filename
 */
import * as React from "react";
import { RdvButton } from "../page-framework/rdv-button";
import { fetchList, openDetailsPanel } from "../../redux/actions";
import { FetchListAction, FetchListPayload } from "../../redux/cache/types";
import { OpenDetailsPanelAction, OpenDetailsPanelPayload } from "../../redux/details-panel/types";
import isEmpty from "lodash.isempty";
import { connect } from "react-redux";
import { convertYoutubeIdToUrl } from "../../utilities/extract-youtube-id";
const VideoPanel = React.lazy(() => import("../page-framework/video-panel"));
const { Suspense } = React;

interface ComponentProps {
  listId: string;
  json: any;
}

interface MapStateProps {
  data: any;
  detailsPanelIsOpened: boolean;
  inProgress: boolean;
}

interface MapDispatchProps {
  openDetailsPanel: (payload: OpenDetailsPanelPayload) => OpenDetailsPanelAction;
  fetchList: (payload: FetchListPayload) => FetchListAction;
}

interface ComponentProps {
  json: any;
}

type Props = ComponentProps & MapDispatchProps & MapStateProps;

const Component = (props: Props) => {
  const { data, fetchList, openDetailsPanel, json, inProgress } = props;
  const youtubeVideo = json?.youtubeId;
  const listId = json?.sourceId || "cax_GMmsRPerPQI8YGhDmQ";
  const list = data?.list?.list;
  const [showBtn, setShowBtn] = React.useState(false);

  React.useEffect(() => {
    if (!data && listId && !inProgress) {
      fetchList({ variables: { listId: listId } });
    }
  }, [listId, fetchList, data, inProgress]);

  const openEpisodesDetailsPanel = React.useCallback(() => {
    openDetailsPanel({ item: list, list });
  }, [list, openDetailsPanel]);

  return (
    <div className="hero-container xxtra live-video">
      <div id="heroCarousel" className="carousel hero" data-ride="carousel">
        <div className={`carousel-item active`} key={1}>
          <Suspense fallback={<div />}>
            <VideoPanel
              mute={true}
              // hasHeroIntro={this.state.heroIntro}
              youTube={youtubeVideo}
              json={{ status: "live", youtube: youtubeVideo }}
              videoPosition={"FIT"}
              onReady={() => {
                setShowBtn(true);
              }}
              url={convertYoutubeIdToUrl(youtubeVideo)}
            />
          </Suspense>
        </div>
      </div>
      <div className="shadow" />
    </div>
  );
};
const mapState = (state: any, props: ComponentProps) => {
  const listId = props.json?.sourceId || "cax_GMmsRPerPQI8YGhDmQ";
  return {
    data: state.cache.store[listId],
    detailsPanelIsOpened: !isEmpty(state.detailsPanel.item) || state.app.currentHash?.split("/").length === 3,
    inProgress: state.cache.inProgress[listId],
  };
};
const mapDispatch = {
  fetchList: fetchList,
  openDetailsPanel: openDetailsPanel,
};

export const LiveVideoPanel = connect(mapState, mapDispatch)(Component);
