import { takeEvery, put, call, select } from "redux-saga/effects";
import { UpdatePersonInput } from "@radivision/graphql";
import { User } from "../../../../../utilities/user";
import {
  prepareUploadMediaPayload,
  prepareMediaAssetPayload,
  ImageUpload,
} from "../../../../../utilities/image-uploader";
import { TOAST_FAIL, TOAST_ALERT } from "../../../../../redux/toast/constants";
import isEmpty from "lodash.isempty";
import { fetchInProgress, cacheData } from "../../../../../redux/cache/actions";
import { SetProfileStatePayload } from "../types";
import { getClientMutationId } from "../../../../../utilities/general";
import { updatePerson } from "../api";
import { setToastMessage } from "../../../../../redux/toast/actions";
import { ONBOARDING_INVESTOR } from "../constants";
import { updateUser } from "../../../../../redux/actions";
import { setProfileState } from "../actions";
import { INVESTOR } from "../../../../../redux/authentication/account-types";
import { getProfileInfo } from "../../../../../utilities/get-profile-info";

function* onBoardingInvestorWorker() {
  try {
    const profileState = yield select((state) => state.profile);
    const currentUser = yield select((state) => state.account.user);
    const isValid = yield call(validateOnBoardingInvestorForm, profileState);

    if (!isValid) {
      return;
    }
    yield put(fetchInProgress({ key: "onboarding-investor", status: true }));
    const file = profileState.profileImage;

    let mediaAssetId: string | null = null;
    if (file) {
      yield call(ImageUpload.uploadImageToS3, prepareUploadMediaPayload(file));
      mediaAssetId = yield call(ImageUpload.createMediaAssetFile, prepareMediaAssetPayload(file));
    }
    const previews = User.handleEditPersonPreview(mediaAssetId, null, currentUser.previews);

    const personInput: UpdatePersonInput & { accountType: { kind: string; description?: string; subKind?: string; } } = {
      clientMutationId: getClientMutationId(),
      id: currentUser.id,
      revision: currentUser.revision,
      fullName: profileState.fullName,
      accountType: { ...profileState.accountType, kind: INVESTOR.kind },
      ...(mediaAssetId ? { previews } : {}),
    };

    const updatedUser = yield call(updatePerson, personInput);
    if (updatedUser) {
      yield put(cacheData({ key: updatedUser.id, data: updatedUser }));
      yield put(updateUser({ user: updatedUser }));
      yield put(setProfileState({ revision: updatedUser.revision }));
    }

    yield put(setToastMessage({ msg: "Profile page was successfully created!" }));

    const signupRedirectionUrl = yield select((state) => state.authentication.signupRedirectionUrl);
    const { profileUrl } = getProfileInfo(updatedUser);
    const redirectionUrl = !isEmpty(signupRedirectionUrl) ? signupRedirectionUrl : profileUrl;
    window.location.href = redirectionUrl;
  } catch (errors) {
    yield put(setToastMessage({ msg: "Updating profile failed!", type: TOAST_FAIL }));
    yield put(fetchInProgress({ key: "onboarding-investor", status: false }));
    console.log("Error: createCompany", { errors });
  }
}

function* validateOnBoardingInvestorForm(payload: SetProfileStatePayload) {
  const { fullName, accountType } = payload;

  const hasAtLeastTwoNames = /\w +\w/.test(fullName);
  if (isEmpty(fullName) || !hasAtLeastTwoNames) {
    yield put(setToastMessage({ msg: "Please enter your first and last name", type: TOAST_ALERT }));
    return false;
  }

  const hasInvestorType = !isEmpty(accountType?.subKind) && !isEmpty(accountType?.description);
  if (!hasInvestorType) {
    yield put(setToastMessage({ msg: "Please choose investor type", type: TOAST_ALERT }));
    return false;
  }

  return true;
}

export function* watchOnBoardingInvestor() {
  yield takeEvery(ONBOARDING_INVESTOR, onBoardingInvestorWorker);
}
